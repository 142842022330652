import { takeLatest, put, call } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/SiteReducer";
import { httpGet } from "../client/http";

const GET_SITES_URL = "api/sites/";

export function* getSites() {
    const response = yield call(httpGet, GET_SITES_URL, true, true);
    if (response.status < 400) {
        let sites = yield response.json();
        yield put(Actions.getSitesSuccess(sites));
    } else {
        yield put(Actions.getSitesError())
    }
}

export function* SiteSaga() {
    yield takeLatest(ActionTypes.GET_SITES, getSites)
}