import * as React from 'react';
import { connect } from 'react-redux';
import UserList from './UserList';
import Settings from './Settings';
import { Page } from '../layout/Page';
import { Row, Col } from 'antd';
import BrandingTaskList from './BrandingTaskList';

interface Props {
  isLoading: boolean;
}

interface State {
  // state
}

class AdminPage extends React.Component<Props, State> {
  render() {
    return (
      <Page isFullWidth={true}>
        <Row className='tenant-admin-wrapper'>
          <Col span={2} />
          <Col span={9}>
            <UserList isLoading={this.props.isLoading} />
          </Col>
          <Col span={2} />
          <Col span={9}>{/* <Settings></Settings> */}</Col>
          <Col span={2}></Col>
        </Row>
        <Row className='tenant-admin-wrapper'>
          <Col span={2} />
          <Col span={20}>
            <BrandingTaskList isLoading={this.props.isLoading}></BrandingTaskList>
          </Col>
          <Col span={2} />
        </Row>
      </Page>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
