import * as React from 'react';
import { Input, Form, Icon, Checkbox, Button } from 'antd';
import '@/styles/password-reset.css';
import logo from '@/assets/jombler.png';
import { httpPost } from '../../client/http';
import swal from 'sweetalert';
import { history } from '../..';

interface Props {}

const FORGET_PASSWORD_URL = 'api/reset-password/';
const FORGET_PASSWORD_CONFIRM_URL = 'api/reset-password/confirm/';

export default class ForgetPassword extends React.Component<Props, {}> {
  state = {
    email: '',
    token: '',
    password: '',
    loading: false,
    showCodeView: false,
  };

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async submitHandler(e) {
    e.preventDefault();

    if (this.state.email) {
      this.setState({ loading: true });

      let response: any = await httpPost(
        FORGET_PASSWORD_URL,
        {
          email: this.state.email,
        },
        false,
        true,
      );

      if (response.status === 'OK') {
        this.setState({ showCodeView: true });
      } else if (response.email) {
        swal('Oops!', response.email[0], 'error');
      }

      this.setState({ loading: false });
    }
  }

  async submitCodeHandler(e) {
    e.preventDefault();

    if (this.state.token && this.state.password) {
      this.setState({ loading: true });

      let response: any = await httpPost(
        FORGET_PASSWORD_CONFIRM_URL,
        {
          token: this.state.token,
          password: this.state.password,
        },
        false,
        true,
      );

      if (response.status === 'notfound') {
        swal('Oops!', 'The password reset code does not match!', 'error');
      } else if (response.password) {
        swal('Oops!', response.password.join('\n'), 'error');
      } else if (response.status === 'OK') {
        swal('Congratulation', 'Password has been successfully reset.', 'success');
        history.push('/login');
      }

      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='password-reset-page'>
        <div className='row center-hz password-reset-row'>
          <div className='password-reset-form'>
            <div className='password-reset-logo'>
              <img src={logo} alt='Jombler Logo' />
            </div>
            <h1>Password Reset</h1>
            {!this.state.showCodeView && (
              <>
                <p className='muted-text'>
                  Please input your email below to get the password reset code. <br />
                  After submit please check your mail box to get password reset code.
                </p>
                <Input
                  prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  name='email'
                  placeholder='Email'
                  onChange={this.handleInputChange.bind(this)}
                  size='large'
                  readOnly={this.state.loading}
                />
              </>
            )}
            {this.state.showCodeView && (
              <>
                <p className='muted-text'>Please input the password reset code you have received through your email.</p>
                <Input
                  prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  name='token'
                  placeholder='Reset Code'
                  onChange={this.handleInputChange.bind(this)}
                  size='large'
                  readOnly={this.state.loading}
                />
                <Input
                  prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  style={{ marginTop: '1vw' }}
                  type='password'
                  name='password'
                  placeholder='Password'
                  onChange={this.handleInputChange.bind(this)}
                  size='large'
                  readOnly={this.state.loading}
                />
              </>
            )}
          </div>
          {!this.state.showCodeView && (
            <Button
              type='primary'
              disabled={!this.state.email}
              loading={this.state.loading}
              onClick={e => this.submitHandler(e)}
              size='large'
            >
              Submit
            </Button>
          )}
          {this.state.showCodeView && (
            <Button
              type='primary'
              disabled={!this.state.token || !this.state.password}
              loading={this.state.loading}
              onClick={e => this.submitCodeHandler(e)}
              size='large'
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    );
  }
}
