import * as React from 'react';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { EditorMode, Actions } from '../../reducers/EditorReducer';
import CroppingFooter from './cropping/CroppingFooter';
import '@/styles/editor-footer.css';
import RotatingFooter from './rotating/RotatingFooter';
import FilteringFooter from './filtering/FilteringFooter';
import BrandingFooter from './branding/BrandingFooter';

interface Props {
  mode: EditorMode;
}

class EditorFooter extends React.PureComponent<Props, {}> {
  state = {
    selectedStep: 100,
  };

  renderFooter() {
    switch (this.props.mode) {
      case EditorMode.CROPPING:
        return <CroppingFooter />;
      case EditorMode.ROTATING:
        return <RotatingFooter />;
      case EditorMode.FILTERING:
        return <FilteringFooter />;
      case EditorMode.BRANDING:
        return <BrandingFooter />;
      default:
        return null;
    }
  }

  render() {
    return (
      <footer>
        <div className='editor-footer'>{this.renderFooter()}</div>
      </footer>
    );
  }
}

const mapDispatchToProps = {
  setMode: Actions.setMode,
};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  mode: editor.mode,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorFooter as any);
