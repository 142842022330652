import { AdminState } from "../state/ApplicationState";
import { createAction } from "./actionHelper";
import { TenantUsers } from "../model/TenantUser";
import { NewTenantUserRequest, UpdateTenantUserRequest } from "../model/TenantUser";
import { BrandingTask, BrandingTasks } from "../model/BrandingTask";

export const INITIAL_STATE_ADMIN = {
    tenantUsers: [],
    isLoading: false,
    isError: false,
    errorMessage: '',
    showCreateModal: false,
    showEditModal: false,
    edittedUserId: '',
    brandingTasks: [],
    showBrandingTaskEditModal: false,
    edittedBrandingTask: null,
};

export enum ActionTypes {
    GET_TENANT_USERS = 'GET_TENANT_USERS',
    GET_TENANT_USER_SUCCESS = 'GET_TENANT_USER_SUCCESS',
    GET_TENANT_USER_FAILURE = 'GET_TENANT_USER_FAILURE',
    SHOW_USER_MODAL = 'SHOW_USER_MODAL',
    SHOW_USER_EDIT_MODAL = 'SHOW_USER_EDIT_MODAL',
    HIDE_USERFORM_MODAL = 'HIDE_USERFORM_MODAL',
    CREATE_TENANT_USER = 'CREATE_TENANT_USER',
    CREATE_TENANT_USER_SUCCESS = 'CREATE_TENANT_USER_SUCCESS',
    CREATE_TENANT_USER_FAILURE = 'CREATE_TENANT_USER_FAILURE',
    UPDATE_TENANT_USER = 'UPDATE_TENANT_USER',
    UPDATE_TENANT_USER_SUCCESS = 'UPDATE_TENANT_USER_SUCCESS',
    UPDATE_TENANT_USER_FAILURE = 'UPDATE_TENANT_USER_FAILURE',
    SHOW_BRANDINGTASK_EDIT_MODAL = 'SHOW_BRANDINGTASK_EDIT_MODAL',
    HIDE_BRANDINGTASK_EDIT_MODAL = 'HIDE_BRANDINGTASK_EDIT_MODAL',
    GET_BRANDINGTASK = 'GET_BRANDTASK',
    GET_BRANDINGTASK_SUCCESS = 'GET_BRANDINGTASK_SUCCESS',
    GET_BRANDINGTASK_FAILURE = 'GET_BRANDINGTASK_FAILURE',
    CREATE_BRANDINGTASK = 'CREATE_BRANDINGTASK',
    CREATE_BRANDINGTASK_SUCCESS = 'CREATE_BRANDINGTASK_SUCCESS',
    CREATE_BRANDINGTASK_FAILURE = 'CREATE_BRANDINGTASK_FAILURE',
    UPDATE_BRANDINGTASK = 'UPDATE_BRANDINGTASK',
    UPDATE_BRANDINGTASK_SUCCESS = 'UPDATE_BRANDINGTASK_SUCCESS',
    UPDATE_BRANDINGTASK_FAILURE = 'UPDATE_BRANDINGTASK_FAILURE',
    DELETE_BRANDINGTASK = 'DELETE_BRANDINGTASK',
    DELETE_BRANDINGTASK_SUCCESS = 'DELETE_BRANDINGTASK_SUCCESS',
    DELETE_BRANDINGTASK_FAILURE = 'DELETE_BRANDINGTASK_FAILURE',
}

export function adminReducer(
    state: AdminState = INITIAL_STATE_ADMIN,
    action: any
) {
    switch (action.type) {
        case ActionTypes.GET_TENANT_USERS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_TENANT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tenantUsers: action.payload,
            };
        case ActionTypes.GET_TENANT_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve Tenant User list'
            }
        case ActionTypes.SHOW_USER_MODAL:
            return {
                ...state,
                showCreateModal: true,
                showEditModal: false,
            }
        case ActionTypes.SHOW_USER_EDIT_MODAL:
            return {
                ...state,
                showEditModal: true,
                showCreateModal: false,
                edittedUserId: action.payload,
            }
        case ActionTypes.HIDE_USERFORM_MODAL:
            return {
                ...state,
                showCreateModal: false,
                showEditModal: false,
            };
        case ActionTypes.GET_BRANDINGTASK:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_BRANDINGTASK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brandingTasks: action.payload,
            };
        case ActionTypes.GET_BRANDINGTASK_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve Branding Task list'
            }
        case ActionTypes.SHOW_BRANDINGTASK_EDIT_MODAL:
            return {
                ...state,
                showBrandingTaskEditModal: true,
                edittedBrandingTask: action.payload,
            }
        case ActionTypes.HIDE_BRANDINGTASK_EDIT_MODAL:
            return {
                ...state,
                showBrandingTaskEditModal: false,
                edittedBrandingTask: null
            };
        default:
            return state;
    }
}

export const Actions = {
    getTenantUsers: () => createAction(ActionTypes.GET_TENANT_USERS),
    getTenantUserSuccess: (tenantUsers: TenantUsers) => createAction(ActionTypes.GET_TENANT_USER_SUCCESS, tenantUsers),
    getTenantUserFailture: () => createAction(ActionTypes.GET_TENANT_USER_FAILURE),
    showCreateModal: () => createAction(ActionTypes.SHOW_USER_MODAL),
    showEditModal: (userId: string) => createAction(ActionTypes.SHOW_USER_EDIT_MODAL, userId),
    hideUserFormModal: () => createAction(ActionTypes.HIDE_USERFORM_MODAL),
    createTenantUser: (tenantUser: NewTenantUserRequest) => createAction(ActionTypes.CREATE_TENANT_USER, tenantUser),
    createTenantUserSuccess: () => createAction(ActionTypes.CREATE_TENANT_USER_SUCCESS),
    createTenantUserFailure: () => createAction(ActionTypes.CREATE_TENANT_USER_FAILURE),
    updateTenantUser: (tenantUser: UpdateTenantUserRequest) => createAction(ActionTypes.UPDATE_TENANT_USER, tenantUser),
    updateTenantUserSuccess: () => createAction(ActionTypes.UPDATE_TENANT_USER_SUCCESS),
    updateTenantUserFailure: () => createAction(ActionTypes.UPDATE_TENANT_USER_FAILURE),

    getBrandingTasks: () => createAction(ActionTypes.GET_BRANDINGTASK),
    getBrandingTasksSuccess: (brandingTasks: BrandingTasks) => createAction(ActionTypes.GET_BRANDINGTASK_SUCCESS, brandingTasks),
    getBrandingTasksFailture: () => createAction(ActionTypes.GET_BRANDINGTASK_FAILURE),
    showBrandingTaskEditModal: (brandingTask: BrandingTask) => createAction(ActionTypes.SHOW_BRANDINGTASK_EDIT_MODAL, brandingTask),
    hideBrandingTaskEditModal: () => createAction(ActionTypes.HIDE_BRANDINGTASK_EDIT_MODAL),
    createBrandingTask: (brandingTask: BrandingTask) => createAction(ActionTypes.CREATE_BRANDINGTASK, brandingTask),
    createBrandingTaskSuccess: () => createAction(ActionTypes.CREATE_BRANDINGTASK_SUCCESS),
    createBrandingTaskFailure: () => createAction(ActionTypes.CREATE_BRANDINGTASK_FAILURE),
    updateBrandingTask: (brandingTask: BrandingTask) => createAction(ActionTypes.UPDATE_BRANDINGTASK, brandingTask),
    updateBrandingTaskSuccess: () => createAction(ActionTypes.UPDATE_BRANDINGTASK_SUCCESS),
    updateBrandingTaskFailure: () => createAction(ActionTypes.UPDATE_BRANDINGTASK_FAILURE),
    deleteBrandingTask: (brandingTask: BrandingTask) => createAction(ActionTypes.DELETE_BRANDINGTASK, brandingTask),
    deleteBrandingTaskSuccess: () => createAction(ActionTypes.DELETE_BRANDINGTASK_SUCCESS),
    deleteBrandingTaskFailure: () => createAction(ActionTypes.DELETE_BRANDINGTASK_FAILURE),
};
