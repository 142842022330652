import * as React from 'react';
import { connect } from 'react-redux';
import { Page } from '../layout/Page';
import { Row, Col } from 'antd';
import MetricCard from './MetricCard';
import MetricSidebar from './MetricSidebar';
import '@/styles/metric-card.scss';
import { Session } from '../../model/Session';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/MetricReducer';
import { numberWithCommas } from '../../util';
import timerIcon from '@/assets/timer.png';
import numberIcon from '@/assets/number.png';
import cameraIcon from '@/assets/camera.png';
import carIcon from '@/assets/car.png';
import { User } from '../../model/User';
import { BASE_URL } from '../../client/http';

interface Props {
  metricsLoading: Boolean;
  isError: Boolean;
  errorMessage: String;
  photoCount: Number;
  vehicleCount: Number;
  avgTime: Number;
  avgPhotos: Number;
  recentSession: Session;
  getMetrics: () => void;
  user: User;
}

interface State {
  // state
}

class MetricPage extends React.Component<Props, State> {
  componentWillMount() {
    this.props.getMetrics();
    window.addEventListener('message', this.handleIframeMessageHandler.bind(this), false);
  }

  handleIframeMessageHandler(e) {
    const data = e.data;
    if (data.type === 'ready') {
      this.sendMessage({ type: 'ready' });
    } else if (data.type == 'resize') {
      let iframe = document.getElementById('spinview-frame') as HTMLIFrameElement;

      if (iframe) {
        iframe.style['height'] = data.data.height + 'px';
        iframe.style['min-height'] = data.data.height + 'px';
        iframe.height = data.data.height + 'px';
      }
    }
  }

  sendMessage(message) {
    let iframe = document.getElementById('spinview-frame') as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(message)), '*');
    }
  }

  render() {
    return (
      <div className='content-container'>
        <MetricSidebar />
        <div className='metric-content-container'>
          <div className='metric-content-section'>
            <h2 className='metric-section-title'>Metrics</h2>
            <div className='metric-card-container'>
              <MetricCard
                metricTitle='Vehicles Photographed'
                metricIcon={carIcon}
                metricValue={this.props.vehicleCount ? numberWithCommas(this.props.vehicleCount) : 0}
                iconBackground='#FC9208'
                key='metric-1'
              />
              <MetricCard
                metricTitle='Average time per vehicle'
                metricIcon={timerIcon}
                metricValue={this.props.avgTime ? this.props.avgTime : '00:00:00'}
                iconBackground='#4CA750'
                key='metric-2'
              />
              <MetricCard
                metricTitle='Total Photographs'
                metricIcon={cameraIcon}
                metricValue={this.props.photoCount ? numberWithCommas(this.props.photoCount) : 0}
                iconBackground='#E8413E'
                key='metric-3'
              />
              <MetricCard
                metricTitle='Average number of photos'
                metricIcon={numberIcon}
                metricValue={this.props.avgPhotos ? numberWithCommas(this.props.avgPhotos) : 0}
                iconBackground='#0BB3C8'
                key='metric-4'
              />
            </div>
          </div>
          {this.props.recentSession && (
            <div className='recent-session-container'>
              <h2 className='recent-session-title'>Recent Session</h2>
              {this.props.recentSession.vehicle && (
                <div className='recent-session-vehicle'>
                  <span className='recent-session-vehicle-subtitle'>VEHICLE:</span>
                  &nbsp;
                  <span className='recent-session-vehicle-subtitle'>VIN:</span>
                  &nbsp;
                  <span className='recent-session-vehicle-value'>{this.props.recentSession.vehicle.vin}</span>
                  &nbsp;
                  <span className='recent-session-vehicle-subtitle'>MAKE:</span>
                  &nbsp;
                  <span className='recent-session-vehicle-value'>{this.props.recentSession.vehicle.make}</span>
                  &nbsp;
                  <span className='recent-session-vehicle-subtitle'>MODEL:</span>
                  &nbsp;
                  <span className='recent-session-vehicle-value'>{this.props.recentSession.vehicle.model}</span>
                  &nbsp;
                </div>
              )}
              <div className='photo-container'>
                {this.props.user.active_site &&
                  this.props.user.active_site.use_photos &&
                  this.props.recentSession.photos.length > 0 &&
                  this.props.recentSession.photos.map(photo => (
                    <div className='photo-item' style={{ backgroundImage: `url(${photo.photo})` }}></div>
                  ))}
              </div>
              <div className='container-360'>
                {this.props.user.active_site && this.props.user.active_site.use_spinviewer_main && (
                  <div className='spinview-preview'>
                    <iframe
                      src={`${BASE_URL}/spinviewer/${this.props.user.active_site.key}/${this.props.recentSession.vehicle.vin}/hide`}
                      width='100%'
                      height='100%'
                      id='spinview-frame'
                    ></iframe>
                  </div>
                )}
                {this.props.user.active_site && this.props.user.active_site.use_panoviewer && (
                  <div className='panoview-preview'>
                    <iframe
                      src={`${BASE_URL}/panoviewer/${this.props.user.active_site.key}/${this.props.recentSession.vehicle.vin}/hide`}
                      width='100%'
                      height='100%'
                      id='view-frame'
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ metrics, user }: ApplicationState) => ({
  metricsLoading: metrics.isLoading,
  isError: metrics.isError,
  errorMessage: metrics.errorMessage,
  photoCount: metrics.photoCount,
  vehicleCount: metrics.vehicleCount,
  avgTime: metrics.avgTime,
  avgPhotos: metrics.avgPhotos,
  recentSession: metrics.recentSession,
  user: user,
});

const mapDispatchToProps = {
  getMetrics: Actions.getMetrics,
};
export default connect(mapStateToProps, mapDispatchToProps)(MetricPage);
