import { AnnotationState } from "../state/ApplicationState";
import { createAction } from "./actionHelper";
import { Annotations, AnnotationListRequest, SpinAnnotations, SpinAnnotation, PanoAnnotations, PanoAnnotation } from "../model/Annotation";
import { Spin } from "antd";


export const INITIAL_STATE_ANNOTATIONS = {
    isLoading: false,
    isError: false,
    errorMessage: '',
    annotations: [],
    spin_annotations: [],
    pano_annotations: []
};

export enum ActionTypes {
    GET_ANNOTATIONS = 'GET_ANNOTATIONS',
    GET_ANNOTATIONS_SUCCESS = 'GET_ANNOTATIONS_SUCCESS',
    GET_ANNOTATIONS_FAILURE = 'GET_ANNOTATIONS_FAILURE',
    GET_SPIN_ANNOTATIONS = 'GET_SPIN_ANNOTATIONS',
    GET_SPIN_ANNOTATIONS_SUCCESS = 'GET_SPIN_ANNOTATIONS_SUCCESS',
    GET_SPIN_ANNOTATIONS_FAILURE = 'GET_SPIN_ANNOTATIONS_FAILURE',
    CREATE_SPIN_ANNOTATION = "CREATE_SPIN_ANNOTATION",
    CREATE_SPIN_ANNOTATION_SUCCESS = "CREATE_SPIN_ANNOTATION_SUCCESS",
    CREATE_SPIN_ANNOTATION_FAILURE = "CREATE_SPIN_ANNOTATION_FAILURE",
    UPDATE_SPIN_ANNOTATION = "UPDATE_SPIN_ANNOTATION",
    UPDATE_SPIN_ANNOTATION_SUCCESS = "UPDATE_SPIN_ANNOTATION_SUCCESS",
    UPDATE_SPIN_ANNOTATION_FAILURE = "UPDATE_SPIN_ANNOTATION_FAILURE",
    DELETE_SPIN_ANNOTATION = 'DELETE_SPIN_ANNOTATION',
    DELETE_SPIN_ANNOTATION_SUCCESS = 'DELETE_SPIN_ANNOTATION_SUCCESS',
    DELETE_SPIN_ANNOTATION_FAILURE = 'DELETE_SPIN_ANNOTATION_FAILURE',
    GET_PANO_ANNOTATIONS = 'GET_PANO_ANNOTATIONS',
    GET_PANO_ANNOTATIONS_SUCCESS = 'GET_PANO_ANNOTATIONS_SUCCESS',
    GET_PANO_ANNOTATIONS_FAILURE = 'GET_PANO_ANNOTATIONS_FAILURE',
    CREATE_PANO_ANNOTATION = "CREATE_PANO_ANNOTATION",
    CREATE_PANO_ANNOTATION_SUCCESS = "CREATE_PANO_ANNOTATION_SUCCESS",
    CREATE_PANO_ANNOTATION_FAILURE = "CREATE_PANO_ANNOTATION_FAILURE",
    UPDATE_PANO_ANNOTATION = "UPDATE_PANO_ANNOTATION",
    UPDATE_PANO_ANNOTATION_SUCCESS = "UPDATE_PANO_ANNOTATION_SUCCESS",
    UPDATE_PANO_ANNOTATION_FAILURE = "UPDATE_PANO_ANNOTATION_FAILURE",
    DELETE_PANO_ANNOTATION = 'DELETE_PANO_ANNOTATION',
    DELETE_PANO_ANNOTATION_SUCCESS = 'DELETE_PANO_ANNOTATION_SUCCESS',
    DELETE_PANO_ANNOTATION_FAILURE = 'DELETE_PANO_ANNOTATION_FAILURE',
}

export function annotationReducer(
    state: AnnotationState = INITIAL_STATE_ANNOTATIONS,
    action: any,
) {
    switch (action.type) {
        case ActionTypes.GET_ANNOTATIONS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                annotations: action.payload,
            };
        case ActionTypes.GET_ANNOTATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve annotation list'
            };
        case ActionTypes.GET_SPIN_ANNOTATIONS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_SPIN_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                spin_annotations: action.payload,
            };
        case ActionTypes.GET_SPIN_ANNOTATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve spinview annotation list'
            };
        case ActionTypes.GET_PANO_ANNOTATIONS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_PANO_ANNOTATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                pano_annotations: action.payload,
            };
        case ActionTypes.GET_PANO_ANNOTATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve panoview annotation list'
            };
        default:
            return state;
    }
}

export const Actions = {
    getAnnotations: (request: AnnotationListRequest) => createAction(ActionTypes.GET_ANNOTATIONS, request),
    getAnnotationsSuccess: (annotations: Annotations) => createAction(ActionTypes.GET_ANNOTATIONS_SUCCESS, annotations),
    getAnnotationsFailure: () => createAction(ActionTypes.GET_ANNOTATIONS_FAILURE),

    getSpinAnnotations: (uuid: string) => createAction(ActionTypes.GET_SPIN_ANNOTATIONS, uuid),
    getSpinAnnotationsSuccess: (annotations: SpinAnnotations) => createAction(ActionTypes.GET_SPIN_ANNOTATIONS_SUCCESS, annotations),
    getSpinAnnotationsFailure: () => createAction(ActionTypes.GET_SPIN_ANNOTATIONS_FAILURE),
    createSpinAnnoation: (annotation: SpinAnnotation) => createAction(ActionTypes.CREATE_SPIN_ANNOTATION, annotation),
    createSpinAnnoationSuccess: (uuid: string) => createAction(ActionTypes.CREATE_SPIN_ANNOTATION_SUCCESS, uuid),
    createSpinAnnoationFailure: () => createAction(ActionTypes.CREATE_SPIN_ANNOTATION_FAILURE),
    updateSpinAnnotation: (annotation: SpinAnnotation) => createAction(ActionTypes.UPDATE_SPIN_ANNOTATION, annotation),
    updateSpinAnnotationSuccess: (uuid: string) => createAction(ActionTypes.UPDATE_SPIN_ANNOTATION_SUCCESS, uuid),
    updateSpinAnnotationFailure: () => createAction(ActionTypes.UPDATE_SPIN_ANNOTATION_FAILURE),
    deleteSpinAnnotation: (annotation: SpinAnnotation) => createAction(ActionTypes.DELETE_SPIN_ANNOTATION, annotation),
    deleteSpinAnnotationSuccess: (uuid: string) => createAction(ActionTypes.DELETE_SPIN_ANNOTATION_SUCCESS, uuid),
    deleteSpinAnnotationFailure: () => createAction(ActionTypes.DELETE_SPIN_ANNOTATION_FAILURE),

    getPanoAnnotations: (uuid: string) => createAction(ActionTypes.GET_PANO_ANNOTATIONS, uuid),
    getPanoAnnotationsSuccess: (pano_annotations: PanoAnnotations) => createAction(ActionTypes.GET_PANO_ANNOTATIONS_SUCCESS, pano_annotations),
    getPanoAnnotationsFailure: () => createAction(ActionTypes.GET_PANO_ANNOTATIONS_FAILURE),
    createPanoAnnoation: (pano_annotations: PanoAnnotations) => createAction(ActionTypes.CREATE_PANO_ANNOTATION, pano_annotations),
    createPanoAnnoationSuccess: (uuid: string) => createAction(ActionTypes.CREATE_PANO_ANNOTATION_SUCCESS, uuid),
    createPanoAnnoationFailure: () => createAction(ActionTypes.CREATE_PANO_ANNOTATION_FAILURE),
    updatePanoAnnotation: (pano_annotation: PanoAnnotation) => createAction(ActionTypes.UPDATE_PANO_ANNOTATION, pano_annotation),
    updatePanoAnnotationSuccess: (uuid: string) => createAction(ActionTypes.UPDATE_PANO_ANNOTATION_SUCCESS, uuid),
    updatePanoAnnotationFailure: () => createAction(ActionTypes.UPDATE_PANO_ANNOTATION_FAILURE),
    deletePanoAnnotation: (pano_annotation: PanoAnnotation) => createAction(ActionTypes.DELETE_PANO_ANNOTATION, pano_annotation),
    deletePanoAnnotationSuccess: (uuid: string) => createAction(ActionTypes.DELETE_PANO_ANNOTATION_SUCCESS, uuid),
    deletePanoAnnotationFailure: () => createAction(ActionTypes.DELETE_PANO_ANNOTATION_FAILURE),
};