import * as React from 'react';
import { Input, Form, Icon, Checkbox, Button } from 'antd';
import '@/styles/login.css';
import logo from '@/assets/jombler.png';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { LoginRequest } from '../../model/Auth';
import { Actions } from '../../reducers/AuthReducer';
import { history } from '../..';

interface Props {
  form: any;
  login: (request: LoginRequest) => void;
  isAuthenticated: boolean;
  errorMessage: string;
}

class LoginPage extends React.Component<Props, {}> {
  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: LoginRequest) => {
      if (!err) {
        this.props.login(values);
      }
    });
  };

  handleForgetPassword(e) {
    e.preventDefault();
    history.push('/forget-password');
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className='login-page'>
        <div className='row center-hz login-row'>
          <div className='login-form'>
            <div className='login-logo'>
              <img src={logo} alt='Jombler Logo' />
            </div>
            <h1>Sign in</h1>
            <p className='muted-text'>Welcome back. Please login to your account</p>
            <Form onSubmit={this.handleSubmit} className='login-form'>
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                })(
                  <Input
                    prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder='Username'
                    size='large'
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                  <Input
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type='password'
                    placeholder='Password'
                    size='large'
                  />,
                )}
              </Form.Item>
              <Form.Item className='login-form-bottom'>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox>
                    Remember me{' '}
                    <a onClick={this.handleForgetPassword.bind(this)} style={{ float: 'right' }}>
                      Forget Password
                    </a>
                  </Checkbox>,
                )}
                <Button type='primary' htmlType='submit' className='login-form-button' size='large'>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export const Login = Form.create({ name: 'normal_login' })(LoginPage);

const mapStateToProps = ({ auth }: ApplicationState): Partial<Props> => ({
  isAuthenticated: auth.isAuthenticated,
  errorMessage: auth.errorMessage,
});

const mapDispatchToProps: Partial<Props> = {
  login: Actions.login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
