import * as React from 'react';
import { Form, Input, Modal, Switch, Select } from 'antd';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/AdminReducer';
import { TenantUsers } from '../../model/TenantUser';
import { UpdateTenantUserRequest } from '../../model/TenantUser';
import { Sites } from '../../model/Site';

interface Props {
  // props
  form: any;
  tenantUsers: TenantUsers;
  showEditModal: boolean;
  edittedUserId: string;
  closeModal: () => void;
  updateTenantUser: (tenantUser: UpdateTenantUserRequest) => void;
  sites: Sites;
}

interface State {
  // state
}

const { Option } = Select;

class UserEditForm extends React.Component<Props, State> {
  state = {
    confirmDirty: false,
    changePasswordForm: true,
  };
  initFormValue() {
    const edittied_user = this.props.tenantUsers.find(u => u.id === this.props.edittedUserId);
    this.props.form.getFieldDecorator('email', { initialValue: edittied_user.email });
    this.props.form.getFieldDecorator('first_name', { initialValue: edittied_user.first_name });
    this.props.form.getFieldDecorator('last_name', { initialValue: edittied_user.last_name });
    this.props.form.getFieldDecorator('sites', { initialValue: edittied_user.sites.map(site => site.id) });
  }
  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      const allValues = {
        ...values,
      };
      const id = { id: this.props.edittedUserId };
      Object.assign(allValues, id);
      if (!err) {
        console.log('No error from form submit', allValues);
        const siteIds: [string] = allValues['sites'] ? allValues['sites'] : [];
        const sites = this.props.sites.filter(site => siteIds.includes(site.id));
        allValues['sites'] = sites;
        this.props.updateTenantUser(allValues);
        this.props.form.resetFields();
        this.props.closeModal();
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleModalCancel = () => {
    this.props.closeModal();
    this.handleReset();
  };
  handlePasswordFieldRender = checked => {
    this.setState({ changePasswordForm: checked });
  };
  renderPasswordForm() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form.Item label='Password' hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please input a password!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label='Confirm Password' hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Please confirm the password!',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur} />)}
        </Form.Item>
      </div>
    );
  }
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.edittedUserId !== null && this.props.showEditModal) {
      this.initFormValue();
    }
    return (
      <Modal
        title='Update user information!'
        visible={this.props.showEditModal}
        onOk={this.handleModalOk}
        onCancel={this.handleModalCancel}
      >
        <Form>
          <Form.Item label='Username'>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input E-mail!',
                },
              ],
            })(<Input placeholder='please input an email address...' />)}
          </Form.Item>
          <div className='password-toggle'>
            <span className='desc'>Do you want to update password as well?</span>
            <Switch
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={this.handlePasswordFieldRender}
              defaultChecked
              style={{ float: 'right' }}
            />
          </div>
          {this.state.changePasswordForm ? this.renderPasswordForm() : null}
          <Form.Item label='First Name'>
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: 'Field cannot be empty',
                },
              ],
            })(<Input placeholder='First name...' />)}
          </Form.Item>
          <Form.Item label='Last Name'>
            {getFieldDecorator('last_name', {
              rules: [
                {
                  required: true,
                  message: 'Field cannot be empty',
                },
              ],
            })(<Input placeholder='Last name...' />)}
          </Form.Item>
          <Form.Item label='Sites'>
            {getFieldDecorator('sites', {
              rules: [],
            })(
              <Select mode='multiple' placeholder='Sites:'>
                {this.props.sites.map(site => (
                  <Option value={site.id}>{site.name}</Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export const form = Form.create({ name: 'UserEditForm' })(UserEditForm);

const mapStateToProps = ({ admin, sites }: ApplicationState) => ({
  tenantUsers: admin.tenantUsers,
  showEditModal: admin.showEditModal,
  edittedUserId: admin.edittedUserId,
  sites: sites.sites,
});

const mapDispatchToProps = {
  closeModal: Actions.hideUserFormModal,
  updateTenantUser: Actions.updateTenantUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
