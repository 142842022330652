import * as React from 'react';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrop, faSync, faAdjust, faDotCircle, faAd } from '@fortawesome/free-solid-svg-icons';
import { EditorMode, Actions, SidebarMode } from '../../reducers/EditorReducer';
import FilteringSidebar from './filtering/FilteringSidebar';
import BrandingSidebar from './branding/BrandingSidebar';
import { Session } from '../../model/Session';
import { history } from '../../index';
import AnnnotationSidebar from './annotation/AnnnotationSidebar';

interface Props {
  mode: EditorMode;
  setMode: (mode: EditorMode) => void;
  setSidebarMode: (sidebarMode: SidebarMode) => void;
  currentSession: Session;
  currentPhotoIndex: number;
}

type Feature = {
  mode: EditorMode;
  name: string;
  icon: JSX.Element;
  description: string;
};

const features: Feature[] = [
  {
    mode: EditorMode.CROPPING,
    name: 'Crop',
    description: 'Adjust the dimensions of the image by freehand or preset.',
    icon: <FontAwesomeIcon icon={faCrop} />,
  },
  {
    mode: EditorMode.ROTATING,
    name: 'Rotate',
    description: 'Adjust the angle of the image.',
    icon: <FontAwesomeIcon icon={faSync} />,
  },
  {
    mode: EditorMode.FILTERING,
    name: 'Filter',
    description: 'Apply filters to the image',
    icon: <FontAwesomeIcon icon={faAdjust} />,
  },
  {
    mode: EditorMode.ANNOTATING,
    name: 'Annotate',
    description: 'Add annotations to the image to draw attention to specific features',
    icon: <FontAwesomeIcon icon={faDotCircle} />,
  },
  {
    mode: EditorMode.BRANDING,
    name: 'Branding',
    description: 'Add custom branding overlays',
    icon: <FontAwesomeIcon icon={faAd} />,
  },
];

class EditorSidebar extends React.PureComponent<Props, {}> {
  state = {
    sidebarOuterActive: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      ...this.state,
      sidebarOuterActive:
        nextProps.mode === EditorMode.FILTERING ||
        nextProps.mode === EditorMode.BRANDING ||
        nextProps.mode === EditorMode.ANNOTATING,
    });
  }

  renderSidebarOuter() {
    switch (this.props.mode) {
      case EditorMode.FILTERING:
        return <FilteringSidebar />;
      case EditorMode.BRANDING:
        return <BrandingSidebar />;
      case EditorMode.ANNOTATING:
        return <AnnnotationSidebar />;
      default:
        return null;
    }
  }

  goToSession() {
    history.push(
      `/session/${this.props.currentSession.uuid}?view=photos&photoId=${
        this.props.currentSession.photos[this.props.currentPhotoIndex].id
      }`,
    );
  }

  render() {
    return (
      <section className='sidebar'>
        <div className='sidebar-inner'>
          <ul>
            {features.map(({ mode: featureMode, name, icon, description }) => {
              return (
                <li
                  className={featureMode === this.props.mode ? 'feature active' : 'feature'}
                  onClick={() => {
                    this.props.setMode(featureMode);
                    if (featureMode === EditorMode.BRANDING) {
                      this.props.setSidebarMode(SidebarMode.ITEM_LIST);
                    }
                  }}
                  key={name}
                >
                  <span className='icon'>{icon}</span>
                  <div className={`tooltip sidebar-tooltip ${this.state.sidebarOuterActive ? 'hide' : ''}`}>
                    <div className={'sidebar-tooltip-body'}>
                      <h5>{name}</h5>
                      <p>{description}</p>
                    </div>
                  </div>
                </li>
              );
            })}
            <li className='feature' onClick={() => this.goToSession()}>
              <span className='icon'>
                <svg
                  aria-hidden='true'
                  focusable='false'
                  data-prefix='fas'
                  data-icon='backward'
                  className='svg-inline--fa fa-backward fa-w-16'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                >
                  <path
                    fill='currentColor'
                    d='M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z'
                  ></path>
                </svg>
              </span>
              <div className={`tooltip sidebar-tooltip ${this.state.sidebarOuterActive ? 'hide' : ''}`}>
                <div className={'sidebar-tooltip-body'}>
                  <h5>Back To Session</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className={`sidebar-outer ${this.state.sidebarOuterActive ? 'active' : ''}`}>
          {this.renderSidebarOuter()}
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = {
  setMode: Actions.setMode,
  setSidebarMode: Actions.setSidebarMode,
};

const mapStateToProps = ({ editor, sessions }: ApplicationState) => ({
  mode: editor.mode,
  currentSession: sessions.currentSession,
  currentPhotoIndex: sessions.currentPhotoIndex,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorSidebar as any);
