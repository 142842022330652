import * as React from 'react';
import { Photo } from '../../model/Session';
import { fabric } from 'fabric';
import { Brandings } from '../../model/Branding';
import { CachedBrandingImage } from '../utils/CachedBrandingImage';

export interface Props {
  photo: Photo;
  brandings: Brandings;
}

const CANVAS_SIZE = 350;

export class PhotoItem extends React.Component<Props, {}> {
  state = {
    canvas: null,
    ratio: 0,
    isBrandingsRendered: false,
  };

  async renderBrandings(brandings: Brandings) {
    if (!brandings || brandings.length == 0 || !this.state.canvas) {
      return;
    }

    const canvas = this.state.canvas;
    const ratio = this.state.ratio;

    this.removeBrandings();

    for (const branding of brandings) {
      const brandingImage = await CachedBrandingImage.updateImage(branding);
      if (branding.default_set) {
        const photoRatio = canvas.imgBigSize / branding.default_photo_size;
        brandingImage.set('scaleX', photoRatio * ratio * branding.default_width);
        brandingImage.set('scaleY', photoRatio * ratio * branding.default_height);
        brandingImage.set('left', (canvas.width * branding.default_x) / 100);
        brandingImage.set('top', (canvas.height * branding.default_y) / 100);
        brandingImage.set('angle', branding.default_angle);
        brandingImage.set('branding', branding);
      }

      canvas.add(brandingImage);
    }
  }

  removeBrandings() {
    const canvas = this.state.canvas;
    canvas.getObjects().map(obj => {
      if (obj.branding) {
        canvas.remove(obj);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.brandings.length !== nextProps.brandings.length) {
      this.renderBrandings(nextProps.brandings);
    }
  }

  componentDidMount() {
    const canvas = new fabric.Canvas(`photo-item-${this.props.photo.id}`, {
      selection: false,
    });

    fabric.filterBackend = fabric.initFilterBackend();
    try {
      let webglBackend = new fabric.WebglFilterBackend();
      fabric.filterBackend = webglBackend;
    } catch (e) {
      let canvas2dBackend = new fabric.Canvas2dFilterBackend();
      fabric.filterBackend = canvas2dBackend;
    }

    let ratio = 0;

    const self = this;
    fabric.Image.fromURL(
      this.props.photo.photo,
      async function(img) {
        canvas.add(img);
        const bigSize = img.width > img.height ? img.width : img.height;
        ratio = CANVAS_SIZE / bigSize;
        canvas.setDimensions({
          width: img.width * ratio,
          height: img.height * ratio,
        });
        img.set('scaleX', ratio);
        img.set('scaleY', ratio);
        canvas.renderAll();
        canvas.imgBigSize = bigSize;
        img.center();

        self.setState({ ratio, canvas }, () => {
          self.renderBrandings(self.props.brandings);
        });
      },
      {
        selectable: false,
        hoverCursor: 'default',
        // crossOrigin: 'anonymous',
      },
    );
  }

  render() {
    return (
      <div className='photo-item'>
        <canvas id={`photo-item-${this.props.photo.id}`}></canvas>
      </div>
    );
  }
}
