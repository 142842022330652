import * as React from 'react';
import { connect } from 'react-redux';
import { Sites } from '../../model/Site';
import { User } from '../../model/User';
import { Actions as SiteActions } from '../../reducers/SiteReducer';
import { Actions as UserActions } from '../../reducers/UserReducer';
import { ApplicationState } from '../../state/ApplicationState';
import cn from 'classnames';

interface Props {
  getSites: () => void;
  updateUserActiveSite: (user: User) => void;
  user: User;
  sites: Sites;
}

class MetricSidebar extends React.PureComponent<Props, {}> {
  componentDidMount() {
    this.props.getSites();
  }

  render() {
    return (
      <div className='metric-sidebar'>
        <div className='sidebar-title-container'>
          <h2 className='title'>Dealerships</h2>
        </div>
        <div className='side-card-container-list'>
          {this.props.sites &&
            this.props.sites.map(site => {
              return (
                <div
                  className={cn('site-card-container', {
                    selected: this.props.user.active_site && this.props.user.active_site.id === site.id,
                  })}
                  onClick={() => {
                    this.props.updateUserActiveSite({ ...this.props.user, active_site: site });
                  }}
                >
                  <h4>{site.name}</h4>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getSites: SiteActions.getSites,
  updateUserActiveSite: UserActions.updateUserActiveSite,
};

const mapStateToProps = ({ sites, user }: ApplicationState) => ({
  sites: sites.sites,
  user: user,
});

export default connect(mapStateToProps, mapDispatchToProps)(MetricSidebar);
