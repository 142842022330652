import {AuthState} from "../state/ApplicationState";
import {ActionsUnion, createAction} from "./actionHelper";
import {LoginRequest} from "../model/Auth";


export const INITIAL_STATE_AUTH = {
    username: "",
    isAuthenticated: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
};

export enum ActionTypes {
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    CHECK_AUTH = 'CHECK_AUTH',
    AUTHENTICATED = 'AUTHENTICATED',
    NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
    LOGOUT = 'LOGOUT',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE = 'LOGOUT_FAILURE',
}


export function AuthReducer(
    state: AuthState = INITIAL_STATE_AUTH,
    action: any
): AuthState {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return {
                ...state,
                username: "",
                isAuthenticated: false,
                isLoading: true,
                isError: false,
            };
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                username: action.payload.username,
                isAuthenticated: true,
                isLoading: false,
                isError: false,
            };
        case ActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                username: "",
                isAuthenticated: false,
                isLoading: false,
                isError: true,
                errorMessage: "Invalid username / password combination",
            };
        case ActionTypes.AUTHENTICATED: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isAuthenticated: true,
                username: action.payload.username,
            };
        }
        case ActionTypes.NOT_AUTHENTICATED: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isAuthenticated: false,
            };
        }
        default:
            return state;

    }
}

export const Actions = {
    login: (payload: LoginRequest) => createAction(ActionTypes.LOGIN, payload),
    logout: () => createAction(ActionTypes.LOGOUT),
    checkAuth: () => createAction(ActionTypes.CHECK_AUTH),
    loginSuccess: (payload: any) => createAction(ActionTypes.LOGIN_SUCCESS, payload),
    loginFail: () => createAction(ActionTypes.LOGIN_FAILURE),
    authenticated: (payload: any) => createAction(ActionTypes.AUTHENTICATED, payload),
    notAuthenticated: () => createAction(ActionTypes.NOT_AUTHENTICATED),
};

export type AuthActions = ActionsUnion<typeof Actions>;
