import { call, takeLatest, put, select } from '@redux-saga/core/effects';
import { Actions, ActionTypes } from '../reducers/ClassificationReducer';
import { Actions as SessionActions } from '../reducers/SessionReducer';
import {
  httpDelete,
  httpGet,
  httpPatch,
  multipartFormSubmit,
} from '../client/http';
import { ActionWithPayload } from '../reducers/actionHelper';
import {
  ClassifyPhotoRequest,
  NewClassificationRequest,
} from '../model/Classification';
import { NavigatePhotoDirection } from '../model/Session';
import { message } from 'antd';

const classificationEndpoint = 'api/classification';
const classifyPhotoEndpoint = (id: string) => `api/photo/${id}`;

export function* getClassifications() {
  try {
    const response = yield call(httpGet, classificationEndpoint);
    yield put(Actions.getClassificationsSuccess(response));
  } catch (e) {
    yield put(Actions.getClassificationsFailure());
  }
}

export function* createClassification(
  action: ActionWithPayload<
    ActionTypes.CREATE_CLASSIFICATION,
    NewClassificationRequest
  >
) {
  try {
    let { name, required, thumbnail, order } = action.payload;
    const data = new FormData();
    if (typeof order === 'undefined') {
      order = 999;
    }
    data.append('name', name);
    data.append('order', order.toString());
    data.append('required', required ? 'true' : 'false');
    if (thumbnail && thumbnail.originFileObj) {
      data.append(
        'thumbnail',
        thumbnail.originFileObj,
        thumbnail.originFileObj.name
      );
    }
    yield call(multipartFormSubmit, classificationEndpoint, data);
    yield put(Actions.createClassificationSuccess());
  } catch (e) {
    console.log('Error creating classification', e);
    yield put(Actions.createClassificationFailure());
  }
}

export function* deleteClassification(
  action: ActionWithPayload<ActionTypes.DELETE_CLASSIFICATION, string>
) {
  try {
    yield call(httpDelete, `${classificationEndpoint}/${action.payload}/`);
    yield put(Actions.deleteClassificationSuccess());
    message.success('Successfully deleted classification');
  } catch (e) {
    message.error('Error deleting classification. Please try again.');
    yield put(Actions.deleteClassificationFailure());
  }
  yield put(Actions.getClassifications());
}

export function* classifyPhoto(
  action: ActionWithPayload<ActionTypes.CLASSIFY_PHOTO, ClassifyPhotoRequest>
) {
  try {
    const { photo, classification } = action.payload;

    yield call(httpPatch, classifyPhotoEndpoint(photo.id), {
      classification: {
        id: classification.id,
      },
    });
    yield put(Actions.classifyPhotoSuccess());
    const getCurrentSession = (state) => state.sessions;
    const { currentSession, currentPhotoIndex } = yield select(
      getCurrentSession
    );

    // Modify photo to have session that was PUT above.
    const session = {
      ...currentSession,
      photos: currentSession.photos.map((currentPhoto) => {
        if (currentPhoto.id === photo.id) {
          currentPhoto.classification = classification;
        }
        return currentPhoto;
      }),
    };

    yield put(
      SessionActions.setCurrentPhoto({
        currentSession: session,
        currentIndex: currentPhotoIndex,
        direction: NavigatePhotoDirection.FORWARD,
      })
    );
  } catch (e) {
    console.log('CLASSIFY PHOTO FAILURE', e);
    yield put(Actions.classifyPhotoFailure());
  }
}

export function* classificationSaga() {
  yield takeLatest(ActionTypes.GET_CLASSIFICATIONS, getClassifications);
  yield takeLatest(ActionTypes.CLASSIFY_PHOTO, classifyPhoto);
  yield takeLatest(ActionTypes.CREATE_CLASSIFICATION, createClassification);
  yield takeLatest(
    ActionTypes.CREATE_CLASSIFICATION_SUCCESS,
    getClassifications
  );
  yield takeLatest(ActionTypes.DELETE_CLASSIFICATION, deleteClassification);
}
