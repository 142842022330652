import { call, takeLatest, put } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/MetricReducer";
import { httpGet } from "../client/http";

const METRICS_URL = 'api/metrics/';

export function* getMetrics() {
    try {
        const response = yield call(httpGet, METRICS_URL);
        yield put(Actions.getMetricsSuccess(response));
    }
    catch (e) {
        yield put(Actions.getMetricsFail());
    }
}

export function* MetricSaga() {
    yield takeLatest(ActionTypes.GET_METRICS, getMetrics);
}