import React from 'react';
import { connect } from 'react-redux';
import { Actions as ClassificationActions } from '../../reducers/ClassificationReducer';
import { Actions } from '../../reducers/SessionReducer';
import { ApplicationState } from '../../state/ApplicationState';
import { Link } from 'react-router-dom';
import { message, Popover } from 'antd';
import '@/styles/photo-session.css';
import {
  AfterPhotos,
  BeforePhotos,
  ChangePhotoPayload,
  GetSession,
  NavigatePhotoDirection,
  Photo,
  Session,
  DeletePhotoPayload,
} from '../../model/Session';
import { Classification, Classifications, ClassifyPhotoRequest } from '../../model/Classification';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Page } from '../layout/Page';
import { Card } from '../layout/Card';
import UploadPhotoForm from './UploadPhotoForm';
import { User } from '../../model/User';
import { history } from '../../index';

interface Props {
  user: User;
  sessionId: string;
  currentSession: Session;
  currentPhoto: Photo;
  classifications: Classifications;
  sessionLoading: boolean;
  classificationLoading: boolean;
  getSession: (sessionSearch: GetSession) => void;
  getClassifications: () => void;
  classifyPhoto: (request: ClassifyPhotoRequest) => void;
  setCurrentPhoto: (photoPayload: ChangePhotoPayload) => void;
  openPhotoUploadForm: () => void;
  deletePhoto: (deletePayload: DeletePhotoPayload) => void;
  currentPhotoIndex: number;
  beforePhotos: BeforePhotos;
  afterPhotos: AfterPhotos;
}

export class PhotoSessionPage extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.getClassifications();
  }

  handleClassificationClick = (classification: Classification) => {
    if (!this.sessionHasPhotoSelected()) {
      message.error('Session has no photos to classify');
      return;
    }

    const currentPhoto = this.props.currentSession.photos[this.props.currentPhotoIndex];

    this.props.classifyPhoto({
      classification,
      photo: currentPhoto,
    });
  };

  openPhotoUploadModal = () => {
    this.props.openPhotoUploadForm();
  };

  openEditing = () => {
    history.push(
      '/editor/' +
        this.props.currentSession.uuid +
        '/' +
        this.props.currentSession.photos[this.props.currentPhotoIndex].id,
    );
  };

  openPanoviewerEditing = () => {
    history.push('/panoviewer-editor/' + this.props.currentSession.uuid + '/');
  };

  openSpinviewerEditing = () => {
    history.push('/spinviewer-editor/' + this.props.currentSession.uuid);
  };

  deletePhoto = () => {
    const currentPhotoId = parseInt(this.props.currentSession.photos[this.props.currentPhotoIndex].id);

    this.props.deletePhoto({
      id: currentPhotoId,
      sessionId: this.props.currentSession.uuid,
    });
  };

  canGetPreviousImage = () => {
    return this.props.currentPhotoIndex > 0;
  };

  canGetNextImage = () => {
    return this.sessionHasPhotoSelected() && this.props.currentPhotoIndex < this.props.currentSession.photos.length - 1;
  };

  sessionHasPhotoSelected = () => {
    return (
      this.props.currentSession && this.props.currentSession.photos.length > 0 && this.props.currentPhotoIndex >= 0
    );
  };

  getRequiredClassificationsLeft() {
    var classifiedPhotos: Photo[];
    var currentClassifications: string[];
    var requiredClassificationNames: string[];
    var difference: string[];
    if (this.props.currentSession && this.props.classifications) {
      classifiedPhotos = this.props.currentSession.photos.filter(photo => photo.classification);
      currentClassifications = classifiedPhotos.map(photo => photo.classification.name);
      requiredClassificationNames = this.props.classifications
        .filter(classification => classification.required === true)
        .map(classification => classification.name);
      difference = requiredClassificationNames.filter(name => !currentClassifications.includes(name));
      if (difference.length > 0) {
        return difference;
      }
    }
    return [`No required classifications`];
  }

  renderSubmenu() {
    return (
      <div className='session-submenu'>
        <ul>
          <Popover
            placement='bottom'
            title={`Classifications Required`}
            content={this.renderRequirementContent(this.getRequiredClassificationsLeft())}
            trigger='click'
          >
            <li className='session-submenu-item'>Session Requirements</li>
          </Popover>
          <li className='session-submenu-item' onClick={() => this.openPhotoUploadModal()}>
            Upload a Photo
          </li>
          {this.props.user.active_site && this.props.user.active_site.use_photos && (
            <li className='session-submenu-item' onClick={() => this.openEditing()}>
              Open Editor
            </li>
          )}
          {this.sessionHasPhotoSelected() ? (
            <Link to={'/finalize/' + this.props.currentSession.uuid + '/'}>
              <li className='session-submenu-item'>Finalize Session</li>
            </Link>
          ) : (
            <li className='session-submenu-item'>Finalize Session</li>
          )}
          <li className='session-submenu-item' onClick={() => this.deletePhoto()}>
            Delete Photo
          </li>
        </ul>
      </div>
    );
  }

  renderBeforePhotos() {
    if (isEmpty(this.props.beforePhotos)) {
      return null;
    }
    return [...this.props.beforePhotos].map((photo: Photo | null, index) => {
      if (photo === null) {
        return <div className='before-image no-image' key={'photo-before' + index}></div>;
      }
      return (
        <div className='before-image' key={'photo-before' + index}>
          <img src={photo.photo} height='100%' width='100%' alt='' />
        </div>
      );
    });
  }

  renderAfterPhotos() {
    if (isEmpty(this.props.afterPhotos)) {
      return null;
    }
    return [...this.props.afterPhotos].map((photo: Photo | null, index) => {
      if (photo === null) {
        return <div className='after-image no-image' key={'photo-before' + index}></div>;
      }
      return (
        <div className='after-image' key={'photo-after' + index}>
          <img src={photo.photo} height='100%' width='100%' alt='' />
        </div>
      );
    });
  }

  renderClassificationOnPhoto() {
    const currentPhoto = this.props.currentSession.photos[this.props.currentPhotoIndex];
    if (isEmpty(currentPhoto.classification)) {
      return <h5 className='classification-type'>Unclassified</h5>;
    }

    return <h5 className='classification-type'>{currentPhoto.classification.name}</h5>;
  }

  renderRequirementContent(requirements: string[]) {
    var requirementListItemTags: any =
      requirements.length >= 1 && requirements[0] !== `No required classifications` ? (
        requirements.map((requirement, index) => <li key={index}>{requirement}</li>)
      ) : (
        <li>No Classification required</li>
      );
    return (
      <div className='requirements-content'>
        <ul>{requirementListItemTags}</ul>
      </div>
    );
  }

  renderPhotos() {
    return (
      <div className='all-photos'>
        <div
          className='before noselect'
          onClick={() =>
            this.props.setCurrentPhoto({
              currentSession: this.props.currentSession,
              currentIndex: this.props.currentPhotoIndex,
              direction: NavigatePhotoDirection.BACKWARDS,
            })
          }
        >
          <div className='before-overlay'></div>
          {this.canGetPreviousImage() && (
            <div className='back-arrow'>
              <FontAwesomeIcon icon={faChevronLeft} size='4x' />
            </div>
          )}
          {this.renderBeforePhotos()}
        </div>
        <div className='current-photo noselect'>
          {this.sessionHasPhotoSelected() ? (
            <div className='current-photo-overlay'>
              <img
                src={this.props.currentSession.photos[this.props.currentPhotoIndex].photo}
                height='100%'
                width='100%'
                onClick={() => this.openEditing()}
                alt=''
              />
              <div className='current-photo-footer'>
                <h5 className='photos-remaining'>
                  {this.props.currentPhotoIndex + 1} / {this.props.currentSession.photos.length}
                </h5>
                {this.renderClassificationOnPhoto()}
              </div>
            </div>
          ) : (
            <div className='current-photo-overlay'>
              <h5 className='no-photos'>No photos for session</h5>
            </div>
          )}
        </div>
        <div
          className='after noselect'
          onClick={() =>
            this.props.setCurrentPhoto({
              currentSession: this.props.currentSession,
              currentIndex: this.props.currentPhotoIndex,
              direction: NavigatePhotoDirection.FORWARD,
            })
          }
        >
          <div className='after-overlay'></div>
          {this.canGetNextImage() && (
            <div className='forward-arrow'>
              <FontAwesomeIcon icon={faChevronRight} size='4x' />
            </div>
          )}
          {this.renderAfterPhotos()}
        </div>
      </div>
    );
  }

  renderClassifications() {
    return this.props.classifications.map((classification: Classification, index: number) => {
      return (
        <Card
          key={`${index}-${classification.id}`}
          className='classification noselect'
          onClick={() => this.handleClassificationClick(classification)}
        >
          <div className='classification-thumbnail'>
            <img src={classification.thumbnail} alt={classification.name} />
          </div>
          <h4>{classification.name.length > 20 ? classification.name.slice(0, 20) + '...' : classification.name}</h4>
        </Card>
      );
    });
  }

  render() {
    return (
      <Page isFullWidth={true}>
        {this.renderSubmenu()}
        <UploadPhotoForm />
        <div className='session-photo-section'>{this.renderPhotos()}</div>
        <div className='container'>
          <div className='classifications-section'>{this.renderClassifications()}</div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ sessions, classifications, user }: ApplicationState) => ({
  user: user,
  currentSession: sessions.currentSession,
  currentPhoto: sessions.currentPhoto,
  classifications: classifications.classifications,
  sessionLoading: sessions.isLoading,
  classificationLoading: classifications.isLoading,
  currentPhotoIndex: sessions.currentPhotoIndex,
  beforePhotos: sessions.beforePhotos,
  afterPhotos: sessions.afterPhotos,
});

const mapDispatchToProps = {
  getSession: Actions.getSession,
  setCurrentPhoto: Actions.setCurrentPhoto,
  getClassifications: ClassificationActions.getClassifications,
  classifyPhoto: ClassificationActions.classifyPhoto,
  openPhotoUploadForm: Actions.openPhotoUploadForm,
  deletePhoto: Actions.deletePhoto,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoSessionPage as any);
