import { SessionState } from '../state/ApplicationState';
import {
  ChangePhotoPayload,
  GetSession,
  NewSessionRequest,
  Session,
  Sessions,
  SetPhotoPayload,
  PhotoUploadRequest,
  DeletePhotoPayload,
  PhotoCanvasUploadRequest,
  SetSpinviewerPhotoSortPayload,
  SetPanoviewerCenterPayload,
} from '../model/Session';
import { ActionsUnion, createAction } from './actionHelper';
import { notification } from 'antd';

export const INITIAL_STATE_SESSIONS = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  sessions: [],
  filteredSessions: [],
  sessionCount: 0,
  completeSessionCount: 0,
  currentSession: undefined,
  currentPhoto: undefined,
  newSessionError: false,
  newSessionLoading: false,
  currentPhotoIndex: -1,
  beforePhotos: [],
  afterPhotos: [],
  newSessionModalOpen: false,
  exportErrorMessage: '',
  downloadErrorMessage: '',
  completeErrorMessage: '',
  sessionsLoading: [],
  photoUploadFormOpen: false,
  sessionsPaginationPage: 1,
  sessionsPaginationPageSize: 0,
  totalCount: 0
};

export enum ActionTypes {
  GET_SESSIONS = 'GET_SESSIONS',
  GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAIL = 'GET_SESSIONS_FAIL',
  GET_SESSION = 'GET_SESSION',
  GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS',
  GET_SESSION_FAIL = 'GET_SESSION_FAIL',
  SET_CURRENT_PHOTO = 'SET_CURRENT_PHOTO',
  SET_CURRENT_PHOTO_SUCCESS = 'SET_CURRENT_PHOTO_SUCCESS',
  SET_SESSION_EXPORT = 'SET_SESSION_EXPORT',
  SET_SESSION_DOWNLOAD = 'SET_SESSION_DOWNLOAD',
  COMPLETE_SESSION = 'COMPLETE_SESSION',
  CREATE_SESSION = 'CREATE_SESSION',
  CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS',
  CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE',
  SEARCH_SESSIONS = 'SEARCH_SESSIONS',
  SEARCH_SESSIONS_RESULTS = 'SEARCH_SESSIONS_RESULTS',
  OPEN_CREATE_SESSION_MODAL = 'OPEN_CREATE_SESSION_MODAL',
  CLOSE_CREATE_SESSION_MODAL = 'CLOSE_CREATE_SESSION_MODAL',
  DELETE_SESSION = 'DELETE_SESSION',
  DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS',
  DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE',
  EXPORT_SESSION_FAILURE = 'EXPORT_SESSION_FAILURE',
  DOWNLOAD_SESSION_FAILURE = 'DOWNLOAD_SESSION_FAILURE',
  COMPLETE_SESSION_FAILURE = 'COMPLETE_SESSION_FAILURE',
  OPEN_PHOTO_FORM_MODAL = 'OPEN_PHOTO_FORM_MODAL', //added
  CLOSE_PHOTO_FORM_MODAL = 'CLOSE_PHOTO_FORM_MODAL', //added
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  PHOTO_CANVAS_UPLOAD = 'PHOTO_CANVAS_UPLOAD',
  PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS',
  PHOTO_UPLOAD_FAILURE = 'PHOTO_UPLOAD_FAILURE',
  PHOTO_UPLOAD_CANVAS_SUCCESS = 'PHOTO_UPLOAD_CANVAS_SUCCESS',
  PHOTO_UPLOAD_CANVAS_FAILURE = 'PHOTO_UPLOAD_CANVAS_FAILURE',
  DELETE_PHOTO = 'DELETE_PHOTO',
  SET_SPINVIEWER_PHOTO_SORT = 'SPINVIEWER_PHOTO_SORT',
  SET_PANOVIEWER_CENTER = 'SET_PANOVIEWER_CENTER',
}

export function sessionReducer(state: SessionState = INITIAL_STATE_SESSIONS, action: any): SessionState {
  switch (action.type) {
    case ActionTypes.GET_SESSIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };
    case ActionTypes.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        sessions: action.payload.results,
        filteredSessions: action.payload.results,
        totalCount: action.payload.count,
        sessionsPaginationPage: action.payload.page,
        sessionsPaginationPageSize: action.payload.pageSize,
        sessionCount: action.payload.total ? action.payload.total : 0,
        completeSessionCount: getCompleteSessionCount(action.payload),
      };
    case ActionTypes.GET_SESSIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Cannot retrieve sessions',
      };
    case ActionTypes.GET_SESSION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
        currentPhotoIndex: -1,
        beforePhotos: [],
        afterPhotos: [],
      };
    case ActionTypes.GET_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
        currentSession: action.payload,
        currentPhotoIndex: -1,
      };
    case ActionTypes.GET_SESSION_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Cannot retrieve current session',
      };
    case ActionTypes.SET_CURRENT_PHOTO_SUCCESS:
      return {
        ...state,
        currentPhotoIndex: action.payload.currentIndex,
        beforePhotos: action.payload.beforePhotos,
        afterPhotos: action.payload.afterPhotos,
      };
    case ActionTypes.CREATE_SESSION_SUCCESS:
      notification.close(action.payload.session.vehicle.vin);
      const sessionsWithNewEntry = [action.payload.session, ...state.sessions];
      const sessionCount = sessionsWithNewEntry.length;
      const completeSessionCount = getCompleteSessionCount(sessionsWithNewEntry);
      return {
        ...state,
        newSessionLoading: false,
        newSessionError: false,
        sessions: sessionsWithNewEntry,
        sessionCount,
        completeSessionCount,
      };
    case ActionTypes.CREATE_SESSION:
      let newSessionsList = state.sessionsLoading.slice();
      newSessionsList.push(action.payload.vin);
      return {
        ...state,
        sessionsLoading: newSessionsList,
      };
    case ActionTypes.CREATE_SESSION_FAILURE:
      return {
        ...state,
        newSessionError: true,
        newSessionLoading: false,
      };
    case ActionTypes.DELETE_SESSION:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.DELETE_SESSION_SUCCESS:
      let sessionsWithoutEntry = state.sessions.filter(x => x.uuid !== action.payload);
      return {
        ...state,
        sessions: sessionsWithoutEntry,
        filteredSessions: sessionsWithoutEntry,
        sessionCount: sessionsWithoutEntry.length,
        completeSessionCount: getCompleteSessionCount(sessionsWithoutEntry),
        errorMessage: '',
        isError: false,
        isLoading: false,
      };
    case ActionTypes.SEARCH_SESSIONS_RESULTS:
      return {
        ...state,
        filteredSessions: action.payload.results,
      };
    case ActionTypes.OPEN_CREATE_SESSION_MODAL:
      return {
        ...state,
        newSessionModalOpen: true,
      };
    case ActionTypes.CLOSE_CREATE_SESSION_MODAL:
      return {
        ...state,
        newSessionModalOpen: false,
      };
    case ActionTypes.EXPORT_SESSION_FAILURE:
      console.log('EXPORT_SESSION_FAILURE');
      return {
        ...state,
        exportErrorMessage: action.payload,
      };
    case ActionTypes.DOWNLOAD_SESSION_FAILURE:
      console.log('DOWNLOAD_SESSION_FAILURE');
      return {
        ...state,
        downloadErrorMessage: action.payload,
      };
    case ActionTypes.COMPLETE_SESSION_FAILURE:
      console.log('COMPLETE_SESSION_FAILURE');
      return {
        ...state,
        completeErrorMessage: action.payload,
      };
    case ActionTypes.OPEN_PHOTO_FORM_MODAL: //added
      return {
        ...state,
        photoUploadFormOpen: true,
      };
    case ActionTypes.CLOSE_PHOTO_FORM_MODAL: //added
      return {
        ...state,
        photoUploadFormOpen: false,
      };
    default:
      return state;
  }
}

function getCompleteSessionCount(sessions: Sessions) {
  return sessions.length > 0 ? sessions.filter(session => session.complete).length : 0;
}

export const Actions = {
  getSessions: (isIncludeCompleted?: boolean, page?: number) => createAction(ActionTypes.GET_SESSIONS, { isIncludeCompleted, page }),
  getSessionsSuccess: (sessions: Sessions) => createAction(ActionTypes.GET_SESSIONS_SUCCESS, sessions),
  getSessionsFail: () => createAction(ActionTypes.GET_SESSIONS_FAIL),
  getSession: (sessionSearch: GetSession) => createAction(ActionTypes.GET_SESSION, sessionSearch),
  getSessionSuccess: (session: Session) => createAction(ActionTypes.GET_SESSION_SUCCESS, session),
  getSessionFail: () => createAction(ActionTypes.GET_SESSION_FAIL),
  setCurrentPhoto: (changePhotoPayload: ChangePhotoPayload) =>
    createAction(ActionTypes.SET_CURRENT_PHOTO, changePhotoPayload),
  setCurrentPhotoSuccess: (setPhotoPayload: SetPhotoPayload) =>
    createAction(ActionTypes.SET_CURRENT_PHOTO_SUCCESS, setPhotoPayload),
  createSession: (session: NewSessionRequest) => createAction(ActionTypes.CREATE_SESSION, session),
  createSessionSuccess: (newSession: Session) =>
    createAction(ActionTypes.CREATE_SESSION_SUCCESS, { session: newSession, isIncludeCompleted: true }),
  createSessionFailure: () => createAction(ActionTypes.CREATE_SESSION_FAILURE),
  searchSessions: (query: string) => createAction(ActionTypes.SEARCH_SESSIONS, query),
  searchSessionsResults: (filteredSessions: Sessions) =>
    createAction(ActionTypes.SEARCH_SESSIONS_RESULTS, filteredSessions),
  openModal: () => createAction(ActionTypes.OPEN_CREATE_SESSION_MODAL),
  closeModal: () => createAction(ActionTypes.CLOSE_CREATE_SESSION_MODAL),
  setSessionExport: (session: Session) => createAction(ActionTypes.SET_SESSION_EXPORT, session),
  setSessionDownload: (session: Session) => createAction(ActionTypes.SET_SESSION_DOWNLOAD, session),
  completeSession: (session: Session) => createAction(ActionTypes.COMPLETE_SESSION, session),
  deleteSession: (sessionId: string) => createAction(ActionTypes.DELETE_SESSION, sessionId),
  deleteSessionSuccess: (sessionId: string) => createAction(ActionTypes.DELETE_SESSION_SUCCESS, sessionId),
  deleteSessionFailure: () => createAction(ActionTypes.DELETE_SESSION_FAILURE),
  exportSessionFailure: (errorMessage: string) => createAction(ActionTypes.EXPORT_SESSION_FAILURE, errorMessage),
  downloadSessionFailure: (errorMessage: string) => createAction(ActionTypes.DOWNLOAD_SESSION_FAILURE, errorMessage),
  completeSessionFailure: (errorMessage: string) => createAction(ActionTypes.COMPLETE_SESSION_FAILURE, errorMessage),
  openPhotoUploadForm: () => createAction(ActionTypes.OPEN_PHOTO_FORM_MODAL),
  closePhotoUploadForm: () => createAction(ActionTypes.CLOSE_PHOTO_FORM_MODAL),
  photoUpload: (photoPayload: PhotoUploadRequest) => createAction(ActionTypes.PHOTO_UPLOAD, photoPayload),
  uploadCanvasPhoto: (photoPayload: PhotoCanvasUploadRequest) =>
    createAction(ActionTypes.PHOTO_CANVAS_UPLOAD, photoPayload),
  photoUploadSuccess: () => createAction(ActionTypes.PHOTO_UPLOAD_SUCCESS),
  photoUploadFailure: () => createAction(ActionTypes.PHOTO_UPLOAD_FAILURE),
  photoUploadCanvasSuccess: () => createAction(ActionTypes.PHOTO_UPLOAD_CANVAS_SUCCESS),
  photoUploadCanvasFailure: () => createAction(ActionTypes.PHOTO_UPLOAD_CANVAS_FAILURE),
  deletePhoto: (deletePhotoPayload: DeletePhotoPayload) => createAction(ActionTypes.DELETE_PHOTO, deletePhotoPayload),
  setSpinviewerPhotoSort: (setSpinviewerPhotoSortPayload: SetSpinviewerPhotoSortPayload) =>
    createAction(ActionTypes.SET_SPINVIEWER_PHOTO_SORT, setSpinviewerPhotoSortPayload),
  setPanoviewerCenter: (setPanoviewerCenterPayload: SetPanoviewerCenterPayload) =>
    createAction(ActionTypes.SET_PANOVIEWER_CENTER, setPanoviewerCenterPayload),
};

export type SessionActions = ActionsUnion<typeof Actions>;
