import * as React from 'react';
import { notification, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Photo, Session } from '../../model/Session';
import { AnnotationPos, AnnotationPoses } from '../../model/Annotation';

export const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

export const uploadingBox = (title, body, key) => {
  const args = {
    message: title,
    description: (
      <div>
        <Spin size='large' style={{ paddingRight: '2em' }} />
        {body}
      </div>
    ),
    duration: 0,
    key: key,
  };

  notification.open(args);
};

export function isDefined(v: any) {
  return v !== undefined && v !== null;
}

const defaultOnClick = () => {};

export const Card = ({ children, className, onClick = defaultOnClick }) => {
  return (
    <div className={`card ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export function getNonAnnotationPhotos(session: Session, annotationPos?: AnnotationPos) {
  if (annotationPos) {
    return session.photos.filter(photo => !photo.annotation || photo.id === annotationPos.annotation.image.id);
  } else {
    return session.photos.filter(photo => !photo.annotation);
  }
}

export function progressiveLoadImages(photos: Photo[]) {
  for (let i = 0; i < photos.length; i++) {
    let img = new Image();
    img.src = photos[i].photo;
  }
}

export function renderImageList(
  session: Session,
  activePhotoIndex: number,
  clickCallback: (activePhotoIndex) => void,
  annotationPos?: AnnotationPos,
) {
  if (!session) {
    return;
  }

  const photos = getNonAnnotationPhotos(session, annotationPos);
  progressiveLoadImages(photos);

  if (photos.length === 0) {
    return (
      <div className='new-annotation-photo-container'>
        <h3>There is no photo in the list.</h3>
      </div>
    );
  } else {
    return (
      <div className='new-annotation-photo-container noselect'>
        <div
          className='backward-arrow'
          style={{
            opacity: activePhotoIndex === 0 ? 0.5 : 1,
          }}
          onClick={() => {
            clickCallback(activePhotoIndex > 0 ? activePhotoIndex - 1 : activePhotoIndex);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} size='4x' />
        </div>
        <div className='new-annotation-photo'>
          <img src={photos[activePhotoIndex].photo} alt='Annotation Display'></img>
        </div>
        <div
          className='forward-arrow'
          style={{
            opacity: activePhotoIndex === photos.length - 1 ? 0.5 : 1,
          }}
          onClick={() => {
            clickCallback(activePhotoIndex < photos.length - 1 ? activePhotoIndex + 1 : activePhotoIndex);
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} size='4x' />
        </div>
      </div>
    );
  }
}

export function generateHash(length) {
  var result = [];
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }

  return result.join('');
}
