import { ClassificationState } from "../state/ApplicationState";
import { createAction } from "./actionHelper";
import { Classifications, ClassifyPhotoRequest, NewClassificationRequest } from "../model/Classification";


export const INITIAL_STATE_CLASSIFICATIONS = {
    isLoading: false,
    isError: false,
    errorMessage: '',
    classifications: [],
    showModal: false,
};

export enum ActionTypes {
    GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS',
    GET_CLASSIFICATIONS_SUCCESS = 'GET_CLASSIFICATIONS_SUCCESS',
    GET_CLASSIFICATIONS_FAILURE = 'GET_CLASSIFICATIONS_FAILURE',
    CLASSIFY_PHOTO = 'CLASSIFY_PHOTO',
    CLASSIFY_PHOTO_SUCCESS = 'CLASSIFY_PHOTO_SUCCESS',
    CLASSIFY_PHOTO_FAILURE = 'CLASSIFY_PHOTO_FAILURE',
    SHOW_CLASSIFICATION_MODAL = 'SHOW_CLASSIFICATION_MODAL',
    HIDE_CLASSIFICATION_MODAL = 'HIDE_CLASSIFICATION_MODAL',
    CREATE_CLASSIFICATION = 'CREATE_CLASSIFICATION',
    CREATE_CLASSIFICATION_SUCCESS = 'CREATE_CLASSIFICATION_SUCCESS',
    CREATE_CLASSIFICATION_FAILURE = 'CREATE_CLASSIFICATION_FAILURE',
    DELETE_CLASSIFICATION = 'DELETE_CLASSIFICATION',
    DELETE_CLASSIFICATION_SUCCESS = 'DELETE_CLASSIFICATION_SUCCESS',
    DELETE_CLASSIFICATION_FAILURE = 'DELETE_CLASSIFICATION_FAILURE',
}

export function classificationReducer(
    state: ClassificationState = INITIAL_STATE_CLASSIFICATIONS,
    action: any,
) {
    switch (action.type) {
        case ActionTypes.GET_CLASSIFICATIONS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_CLASSIFICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                classifications: action.payload,
            };
        case ActionTypes.GET_CLASSIFICATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve classification list'
            };
        case ActionTypes.SHOW_CLASSIFICATION_MODAL:
            return {
                ...state,
                showModal: true,
            };
        case ActionTypes.HIDE_CLASSIFICATION_MODAL:
            return {
                ...state,
                showModal: false,
            };
        case ActionTypes.DELETE_CLASSIFICATION:
            return {
                ...state,
                isLoading: true,
            };
        case ActionTypes.DELETE_CLASSIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case ActionTypes.DELETE_CLASSIFICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export const Actions = {
    getClassifications: () => createAction(ActionTypes.GET_CLASSIFICATIONS),
    getClassificationsSuccess: (classifications: Classifications) => createAction(ActionTypes.GET_CLASSIFICATIONS_SUCCESS, classifications),
    getClassificationsFailure: () => createAction(ActionTypes.GET_CLASSIFICATIONS_FAILURE),
    classifyPhoto: (classifyPhotoRequest: ClassifyPhotoRequest) => createAction(ActionTypes.CLASSIFY_PHOTO, classifyPhotoRequest),
    classifyPhotoSuccess: () => createAction(ActionTypes.CLASSIFY_PHOTO_SUCCESS),
    classifyPhotoFailure: () => createAction(ActionTypes.CLASSIFY_PHOTO_FAILURE),
    showClassificationModal: () => createAction(ActionTypes.SHOW_CLASSIFICATION_MODAL),
    hideClassificationModal: () => createAction(ActionTypes.HIDE_CLASSIFICATION_MODAL),
    createClassification: (classification: NewClassificationRequest) => createAction(ActionTypes.CREATE_CLASSIFICATION, classification),
    createClassificationSuccess: () => createAction(ActionTypes.CREATE_CLASSIFICATION_SUCCESS),
    createClassificationFailure: () => createAction(ActionTypes.CREATE_CLASSIFICATION_FAILURE),
    deleteClassification: (classificationId: string) => createAction(ActionTypes.DELETE_CLASSIFICATION, classificationId),
    deleteClassificationSuccess: () => createAction(ActionTypes.DELETE_CLASSIFICATION_SUCCESS),
    deleteClassificationFailure: () => createAction(ActionTypes.DELETE_CLASSIFICATION_FAILURE),
};