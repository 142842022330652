import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/SessionReducer';
import { Actions as EditorActions } from '../../reducers/EditorReducer';
import { GetSession, Session } from '../../model/Session';
import { RouteComponentProps } from 'react-router';
import EditorSidebar from './EditorSidebar';

import 'cropperjs/dist/cropper.css';
import '@/styles/editor.css';
import EditorMain from './EditorMain';

interface MatchParams {
  sessionId: string;
  photoId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  sessionId: string;
  currentSession: Session;
  getSession: (sessionSearch: GetSession) => void;
  initEditor: () => void;
}

class EditorPage extends React.Component<Props, {}> {
  componentWillMount() {
    const { sessionId, photoId } = this.props.match.params;
    this.props.getSession({
      sessionId,
      photoId,
    });
    this.props.initEditor();
  }

  render() {
    return (
      <div className='editor-content'>
        <EditorSidebar />
        <EditorMain />
      </div>
    );
  }
}

const mapStateToProps = ({ sessions }: ApplicationState) => ({
  currentSession: sessions.currentSession,
});

const mapDispatchToProps = {
  getSession: Actions.getSession,
  initEditor: EditorActions.initEditor,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorPage as any);
