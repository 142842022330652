import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApplicationState } from '../state/ApplicationState';
import { connect } from 'react-redux';
import { Actions } from '../reducers/AuthReducer';
import { getRoutes } from '../router/Routes';
import { User } from '../model/User';
import { Content } from './layout/Content';
import Header from './layout/Header';
import JobNotification from './jobnotification/jobnotification';

interface ApplicationProps extends RouteComponentProps<any> {
  checkAuth: () => void;
  logout: () => void;
  isAuthenticated: boolean;
  user: User;
}

export class Application extends React.Component<ApplicationProps, {}> {
  componentWillMount() {
    this.props.checkAuth();
  }
  render() {
    return (
      <>
        <JobNotification></JobNotification>
        <Header isAuthenticated={this.props.isAuthenticated} logout={this.props.logout} />
        <Content>{getRoutes()}</Content>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkAuth: () => dispatch(Actions.checkAuth()),
  logout: () => dispatch(Actions.logout()),
});
const mapStateToProps = ({ auth, user }: ApplicationState) => ({
  isAuthenticated: auth.isAuthenticated,
  user: user,
});

export const ConnectedApplication = withRouter(connect(mapStateToProps, mapDispatchToProps)(Application));
