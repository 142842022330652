import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxSaga from 'redux-saga';
import * as serviceWorker from './serviceWorker';
import { authSaga } from './sagas/AuthSaga';
import { ConnectedApplication } from './components/Application';
import createRootReducer from './reducers/reducers';
import { INITIAL_STATE } from './state/ApplicationState';

import 'antd/dist/antd.css';
import '@/styles/app.scss';
import { sessionSaga } from './sagas/SessionSaga';
import { classificationSaga } from './sagas/ClassificationSaga';
import { SiteSaga } from './sagas/SiteSaga';
import { UserSaga } from './sagas/UserSaga';
import { AdminSaga } from './sagas/AdminSaga';
import { brandingSaga } from './sagas/BrandingSaga';
import { annotationPosSaga } from './sagas/AnnotationPosSaga';
import { annotationSaga } from './sagas/AnnotationSaga';
import { MetricSaga } from './sagas/MetricSaga';

const sagaMiddleWare = reduxSaga();
export const history = createBrowserHistory();
const store = createStore(
  createRootReducer(history),
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleWare)),
);

sagaMiddleWare.run(authSaga);
sagaMiddleWare.run(sessionSaga);
sagaMiddleWare.run(classificationSaga);
sagaMiddleWare.run(brandingSaga);
sagaMiddleWare.run(annotationSaga);
sagaMiddleWare.run(annotationPosSaga);
sagaMiddleWare.run(SiteSaga);
sagaMiddleWare.run(UserSaga);
sagaMiddleWare.run(AdminSaga);
sagaMiddleWare.run(MetricSaga);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConnectedApplication />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
