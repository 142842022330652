import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { AuthReducer } from './AuthReducer';
import { sessionReducer } from './SessionReducer';
import { classificationReducer } from './ClassificationReducer';
import { siteReducer } from './SiteReducer';
import { editorReducer } from './EditorReducer';
import { UserReducer } from './UserReducer';
import { adminReducer } from './AdminReducer';
import { brandingReducer } from './BrandingReducer';
import { annotationPosReducer } from './AnnotationPosReducer';
import { annotationReducer } from './AnnotationReducer';
import { metricReducer } from './MetricReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    sessions: sessionReducer,
    classifications: classificationReducer,
    sites: siteReducer,
    brandings: brandingReducer,
    annotations: annotationReducer,
    annotationPoses: annotationPosReducer,
    editor: editorReducer,
    user: UserReducer,
    admin: adminReducer,
    metrics: metricReducer
  });
