import { EditorState } from '../state/ApplicationState';
import { createAction } from './actionHelper';
import { IPosition } from '../util';
import { Branding } from '../model/Branding';

export enum EditorMode {
  CROPPING,
  ROTATING,
  ANNOTATING,
  FILTERING,
  BRANDING,
}

export enum SidebarMode {
  ITEM_LIST,
  ITEM_UPLOAD,
}

export enum AnnotationWindow {
  NEW_ANNOTATION,
  PREVIEW_ANNOTAION,
  BLANK,
}

export const INITIAL_STATE_EDITOR = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  isPhotoLoaded: false,
  mode: EditorMode.CROPPING,
  zoom: 1,
  aspectRatio: '4:3',
  sidebarMode: SidebarMode.ITEM_LIST,
  activeAnnotationWindow: AnnotationWindow.BLANK,
  activeAnnotationPos: { x: 0, y: 0 },
  activeBranding: null,
  activeCanvasBranding: null,
  canvas: null,
};

export const aspectRatioToValue = {
  '4:3': 4 / 3,
};

export interface GammaObject {
  label: string;
  value: number;
}

export const GAMMA_VALUES: GammaObject[] = [
  {
    label: 'Darkest',
    value: 0.7,
  },
  {
    label: 'Darker',
    value: 0.85,
  },
  {
    label: 'Current',
    value: 1,
  },
  {
    label: 'Lighter',
    value: 1.15,
  },
  {
    label: 'Lightest',
    value: 1.3,
  },
];

export enum ActionTypes {
  SET_MODE = 'SET_MODE',
  SET_ZOOM = 'SET_ZOOM',
  SET_ASPECT_RATIO = 'SET_ASPECT_RATIO',
  SET_SIDEBAR_MODE = 'SET_SIDEBAR_MODE',
  SET_PHOTO_LOADED = 'SET_PHOTO_LOADED',
  SET_ACTIVE_ANNOTATION_WINDOW = 'SET_ACTIVE_ANNOTATION_WINDOW',
  SET_ACTIVE_ANNOTATION_POS = 'SET_ACTIVE_ANNOTATION_POS',
  SET_CANVAS = 'SET_CANVAS',
  SET_ACTIVE_BRANDING = 'SET_ACTIVE_BRANDING',
  SET_ACTIVE_CANVAS_BRANDING = 'SET_ACTIVE_CANVAS_BRANDING',
  INIT_EDITOR = 'INIT_EDITOR',
}

export function editorReducer(state: EditorState = INITIAL_STATE_EDITOR, action: any) {
  switch (action.type) {
    case ActionTypes.SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case ActionTypes.SET_ZOOM:
      return {
        ...state,
        mode: action.payload,
      };
    case ActionTypes.SET_ASPECT_RATIO:
      return {
        ...state,
        mode: action.payload,
      };
    case ActionTypes.SET_SIDEBAR_MODE:
      return {
        ...state,
        sidebarMode: action.payload,
      };
    case ActionTypes.SET_PHOTO_LOADED:
      return {
        ...state,
        isPhotoLoaded: action.payload,
      };
    case ActionTypes.SET_ACTIVE_ANNOTATION_WINDOW:
      return {
        ...state,
        activeAnnotationWindow: action.payload,
      };
    case ActionTypes.SET_ACTIVE_ANNOTATION_POS:
      return {
        ...state,
        activeAnnotationPos: action.payload,
      };
    case ActionTypes.SET_ACTIVE_BRANDING:
      return {
        ...state,
        activeBranding: action.payload,
      };
    case ActionTypes.SET_ACTIVE_CANVAS_BRANDING:
      return {
        ...state,
        activeCanvasBranding: action.payload,
      };
    case ActionTypes.SET_CANVAS:
      return {
        ...state,
        canvas: action.payload,
      };
    case ActionTypes.INIT_EDITOR:
      return {
        ...state,
        ...INITIAL_STATE_EDITOR,
      };
    default:
      return state;
  }
}

export const Actions = {
  setMode: (mode: EditorMode) => createAction(ActionTypes.SET_MODE, mode),
  setZoom: (zoom: number) => createAction(ActionTypes.SET_ZOOM, zoom),
  setAspectRatio: (aspectRatio: string) => createAction(ActionTypes.SET_ASPECT_RATIO, aspectRatio),
  setSidebarMode: (sidebarMode: SidebarMode) => createAction(ActionTypes.SET_SIDEBAR_MODE, sidebarMode),
  setPhotoLoaded: (isPhotoLoaded: boolean) => createAction(ActionTypes.SET_PHOTO_LOADED, isPhotoLoaded),
  setActiveBranding: (activeBranding: Branding) => createAction(ActionTypes.SET_ACTIVE_BRANDING, activeBranding),
  setActiveCanvasBranding: (activeCanvasBranding: Branding) =>
    createAction(ActionTypes.SET_ACTIVE_CANVAS_BRANDING, activeCanvasBranding),
  setCanvas: (canvas: any) => createAction(ActionTypes.SET_CANVAS, canvas),
  setActiveAnnotationWindow: (activeAnnotationWindow: AnnotationWindow) =>
    createAction(ActionTypes.SET_ACTIVE_ANNOTATION_WINDOW, activeAnnotationWindow),
  setActiveAnnotationPos: (activeAnnotationPos: IPosition) =>
    createAction(ActionTypes.SET_ACTIVE_ANNOTATION_POS, activeAnnotationPos),
  initEditor: () => createAction(ActionTypes.INIT_EDITOR),
};
