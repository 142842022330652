import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { GammaObject } from '../../../reducers/EditorReducer';
import { fabric } from 'fabric';
import { Session, Photo } from '../../../model/Session';
import { generateHash } from '../../utils';

interface Props {
  gammaObject: GammaObject;
  currentSession: Session;
  currentPhotoIndex: number;
  canvas: any;
}

class FilteringPreviewItem extends React.PureComponent<Props, {}> {
  componentDidMount() {
    const canvas = new fabric.Canvas('canvas-' + this.props.gammaObject.label, {
      selection: false,
    });

    fabric.filterBackend = fabric.initFilterBackend();
    try {
      let webglBackend = new fabric.WebglFilterBackend();
      fabric.filterBackend = webglBackend;
    } catch (e) {
      let canvas2dBackend = new fabric.Canvas2dFilterBackend();
      fabric.filterBackend = canvas2dBackend;
    }

    const photo: Photo = this.props.currentSession.photos[this.props.currentPhotoIndex];

    const self = this;
    fabric.Image.fromURL(
      photo.photo + `?${generateHash(10)}`,
      function(img) {
        const ratio = img.width / img.height;

        const desiredWidth = 220;
        const desiredHeight = desiredWidth / ratio;

        canvas.setDimensions({ width: desiredWidth, height: desiredHeight });

        img.set({
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height,
        });

        let filter = new fabric.Image.filters.Gamma({
          gamma: [self.props.gammaObject.value, self.props.gammaObject.value, self.props.gammaObject.value],
        });

        img.filters[0] = filter;
        img.applyFilters();

        canvas.add(img).renderAll();
      },
      {
        selectable: false,
        hoverCursor: 'default',
        crossOrigin: 'anonymous',
      },
    );
  }

  filterClick() {
    if (this.props.canvas && this.props.canvas.filterImage) {
      this.props.canvas.filterImage(this.props.gammaObject);
    }
  }

  render() {
    return (
      <>
        <div className='filter-preview' onClick={() => this.filterClick()}>
          <div className='filter-container'>
            <h4>{this.props.gammaObject.label}</h4>
            <canvas id={'canvas-' + this.props.gammaObject.label} />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ editor, sessions }: ApplicationState) => ({
  currentSession: sessions.currentSession,
  currentPhotoIndex: sessions.currentPhotoIndex,
  canvas: editor.canvas,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilteringPreviewItem);
