import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Table, Row, Col } from 'antd';
import { Actions } from '../../reducers/AdminReducer';
import { Loading } from '../layout/Loading';
import '../../styles/admin.scss';
import Button from 'antd/lib/button/button';
import { faEdit, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BrandingTaskEditForm from './BrandingTaskEditForm';
import { BrandingTask, BrandingTasks } from '../../model/BrandingTask';

const { Column } = Table;

interface Props {
  isLoading: boolean;
  brandingTasks: BrandingTasks;
  getBrandingTasks: () => void;
  showBrandingTaskEditModal: (brandingTask: BrandingTask) => void;
  deleteBrandingTask: (brandingTask: BrandingTask) => void;
}

class BrandingTaskManagement extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.getBrandingTasks();
  }

  renderEditButton(brandingTask: BrandingTask) {
    return (
      <>
        <Button
          type='primary'
          onClick={() => this.props.showBrandingTaskEditModal(brandingTask)}
          style={{
            marginRight: '10px',
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button type='danger' onClick={() => this.props.deleteBrandingTask(brandingTask)}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>
    );
  }

  renderBrandingList() {
    return (
      <div>
        <Table
          dataSource={this.props.brandingTasks}
          rowKey='id'
          pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'] }}
        >
          <Column title='Trigger' dataIndex='trigger' key='trigger' />
          <Column
            title='Classification'
            dataIndex='classification'
            key='classification'
            render={classification => (classification ? classification.name : 'N/A')}
          />
          <Column title='Order' dataIndex='order' key='order' render={order => (order > 0 ? order : 'N/A')} />
          <Column
            title='Branding'
            dataIndex='branding'
            key='branding'
            render={branding => (branding ? branding.name : 'N/A')}
          />
          <Column title='Edit' dataIndex='id' render={(id, record: BrandingTask) => this.renderEditButton(record)} />
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Loading isLoading={this.props.isLoading}>
        <BrandingTaskEditForm />
        <Row className='brandingtask-wrapper'>
          <Row>
            <Col span={24}>
              <div className='brandingtask-title'>
                <span>Branding Tasks</span>
              </div>
              {true ? this.renderBrandingList() : null}
            </Col>
          </Row>
          <Row>
            <Col span={8} />
            <Col span={8} />
            <Col span={8} className='brandingtask-action'>
              <Button type='primary' onClick={() => this.props.showBrandingTaskEditModal(null)}>
                Create New Branding
              </Button>
            </Col>
          </Row>
        </Row>
      </Loading>
    );
  }
}
const mapStateToProps = ({ admin }: ApplicationState) => ({
  brandingTasks: admin.brandingTasks,
});

const mapDispatchToProps = {
  getBrandingTasks: Actions.getBrandingTasks,
  deleteBrandingTask: Actions.deleteBrandingTask,
  showBrandingTaskEditModal: Actions.showBrandingTaskEditModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingTaskManagement);
