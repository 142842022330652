/* global cv */

import * as React from 'react';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { Photo, Session, PhotoCanvasUploadRequest } from '../../model/Session';
import { fabric } from 'fabric';
import {
  Actions,
  EditorMode,
  AnnotationWindow,
  aspectRatioToValue,
  GammaObject,
  SidebarMode,
} from '../../reducers/EditorReducer';
import { Actions as SessionActions } from '../../reducers/SessionReducer';
import { Actions as BrandingActions } from '../../reducers/BrandingReducer';
import { IPosition, convertPixelPositionToPercent, IContainer } from '../../util';
import { Branding, UpdateBrandingRequest } from '../../model/Branding';
import { uploadingBox } from '../dashboard/SessionForm';
import Cropper from 'cropperjs';
import swal from 'sweetalert';
import { generateHash } from '../utils';

interface Props {
  currentSession: Session;
  currentPhotoIndex: number;
  mode: EditorMode;
  sidebarMode: SidebarMode;
  aspectRatio: string;
  activeAnnotationWindow: AnnotationWindow;
  setPhotoLoaded: (mode: boolean) => void;
  setCanvas: (canvas: any) => void;
  setSidebarMode: (SidebarMode: SidebarMode) => void;
  setActiveAnnotationWindow: (activeAnnotationWindow: AnnotationWindow) => void;
  setActiveAnnotationPos: (activeAnnotationPos: IPosition) => void;
  setActiveCanvasBranding: (activeCanvasBranding: Branding) => void;
  uploadCanvasPhoto: (photo: PhotoCanvasUploadRequest) => void;
  updateBrandings: (brandings: UpdateBrandingRequest) => void;
}

interface IState {
  photo: Photo;
  photoScale: number;
  canvas: any;
  mode: EditorMode;
}

class Canvas extends React.Component<Props, {}> {
  state: IState = {
    photo: null,
    photoScale: 1,
    canvas: null,
    mode: null,
  };

  componentDidMount() {
    const canvas = new fabric.Canvas('canvas', {
      selection: false,
    });

    fabric.filterBackend = fabric.initFilterBackend();
    try {
      let webglBackend = new fabric.WebglFilterBackend();
      fabric.filterBackend = webglBackend;
    } catch (e) {
      let canvas2dBackend = new fabric.Canvas2dFilterBackend();
      fabric.filterBackend = canvas2dBackend;
    }

    canvas.placeBranding = (branding: Branding) => {
      if (branding.default_set) {
        fabric.Image.fromURL(
          branding.thumbnail + `?${generateHash(10)}`,
          function(img) {
            img
              .setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                mtr: false,
              })
              .setCoords();
            canvas.add(img).renderAll();
          },
          {
            transparentCorners: false,
            cornerColor: 'rgb(173, 152, 152)',
            cornerStrokeColor: 'rgb(173, 152, 152)',
            borderColor: 'rgb(173, 152, 152)',
            cornerSize: 12,
            cornerStyle: 'circle',
            borderDashArray: [3, 3],
            crossOrigin: 'anonymous',
            lockUniScaling: true,
            lockScalingFlip: true,
            lockRotation: true,
            branding,
            scaleX: branding.default_width * this.state.photoScale,
            scaleY: branding.default_height * this.state.photoScale,
            left: (canvas.width * branding.default_x) / 100,
            top: (canvas.height * branding.default_y) / 100,
            angle: branding.default_angle,
          },
        );
      } else {
        fabric.Image.fromURL(
          branding.thumbnail + `?${generateHash(10)}`,
          function(img) {
            img.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              mtr: false,
            });
            canvas.add(img).renderAll();
            img.center();
          },
          {
            transparentCorners: false,
            cornerColor: 'rgb(173, 152, 152)',
            cornerStrokeColor: 'rgb(173, 152, 152)',
            borderColor: 'rgb(173, 152, 152)',
            cornerSize: 12,
            cornerStyle: 'circle',
            borderDashArray: [3, 3],
            crossOrigin: 'anonymous',
            lockUniScaling: true,
            lockScalingFlip: true,
            lockRotation: true,
            scaleX: this.state.photoScale,
            scaleY: this.state.photoScale,
            branding,
          },
        );
      }
    };

    canvas.removeBranding = (branding: Branding) => {
      const brandingObj = canvas.getObjects().find(obj => obj.branding && obj.branding.id === branding.id);
      canvas.remove(brandingObj);
      this.props.setActiveCanvasBranding(null);
    };

    canvas.updateBrandings = async () => {
      const brandings = [];

      const objects = canvas.getObjects();

      for await (let obj of objects) {
        if (obj.branding) {
          const defaultPhotoSize =
            canvas.mainImg.width > canvas.mainImg.height ? canvas.mainImg.width : canvas.mainImg.height;

          const brandingObj = {
            id: obj.branding.id,
            name: obj.branding.name,
            default_width: Number(obj.scaleX / this.state.photoScale).toFixed(5),
            default_height: Number(obj.scaleY / this.state.photoScale).toFixed(5),
            default_x: Number((obj.left * 100) / canvas.width).toFixed(5),
            default_y: Number((obj.top * 100) / canvas.height).toFixed(5),
            default_angle: Number(obj.angle).toFixed(5),
            default_photo_size: defaultPhotoSize,
          };

          if (
            obj.branding.default_width != brandingObj.default_width ||
            obj.branding.default_height != brandingObj.default_height ||
            obj.branding.default_x != brandingObj.default_x ||
            obj.branding.default_y != brandingObj.default_y ||
            obj.branding.default_angle != brandingObj.default_angle ||
            obj.branding.default_photo_size != brandingObj.default_photo_size
          ) {
            if (!obj.branding.default_set) {
              brandings.push(brandingObj);
            } else {
              const result = await swal({
                title: 'Change Default Location?',
                text: `Do you want to change the default location and size for the branding'${brandingObj.name}'?`,
                icon: 'info',
                buttons: [true, true],
              });

              if (result) {
                brandings.push(brandingObj);
              }
            }
          }
        }
      }

      if (brandings.length > 0) {
        this.props.updateBrandings({ brandings });
      }
    };

    canvas.uploadCanvas = () => {
      this.props.setPhotoLoaded(false);

      const brandingObjs = canvas.getObjects().filter(obj => obj.branding);

      // Set Canvas Dimension
      canvas.setDimensions({
        width: canvas.mainImg.width,
        height: canvas.mainImg.height,
      });

      // Canvas Main Photo Recover Scale
      canvas.mainImg
        .set({
          scaleX: 1,
          scaleY: 1,
        })
        .setCoords();

      // Recover Branding Scale
      brandingObjs.map(brandingObj =>
        brandingObj
          .set({
            left: brandingObj.left / this.state.photoScale,
            top: brandingObj.top / this.state.photoScale,
            scaleX: brandingObj.scaleX / this.state.photoScale,
            scaleY: brandingObj.scaleY / this.state.photoScale,
          })
          .setCoords(),
      );

      canvas.renderAll();

      var dataURI = canvas.toDataURL({
        format: 'jpeg',
        quality: 1,
      });

      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      var mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];

      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([ia], { type: mimeString });

      uploadingBox('Started uploading', 'Uploading, please wait....', 'uploading');

      this.props.uploadCanvasPhoto({
        photo: this.props.currentSession.photos[this.props.currentPhotoIndex],
        file: blob,
      });
    };

    canvas.cropImage = () => {
      if (canvas.cropper) {
        this.props.setPhotoLoaded(false);

        // Set Canvas Dimension
        canvas.setDimensions({
          width: canvas.mainImg.width,
          height: canvas.mainImg.height,
        });

        // Canvas Main Photo Recover Scale
        canvas.mainImg
          .set({
            scaleX: 1,
            scaleY: 1,
          })
          .setCoords();

        canvas.renderAll();

        let data = canvas.cropper.getData();

        if (canvas.cropper) {
          canvas.cropper.destroy();
          canvas.cropper = null;
        }

        canvas.cropper = new Cropper(canvas.getElement(), {
          dragMode: 'none',
          background: false,
          aspectRatio: aspectRatioToValue[self.props.aspectRatio],
          checkCrossOrigin: true,
          checkOrientation: false,
          zoomable: false,
          ready() {},
        });

        data = {
          scaleX: 1,
          scaleY: 1,
          rotation: 0,
          width: data.width / this.state.photoScale,
          height: data.height / this.state.photoScale,
          x: data.x / this.state.photoScale,
          y: data.y / this.state.photoScale,
        };

        setTimeout(() => {
          canvas.cropper.setData(data);
          canvas.cropper.getCroppedCanvas().toBlob(blob => {
            uploadingBox('Started uploading', 'Uploading, please wait....', 'uploading');
            this.props.uploadCanvasPhoto({
              photo: this.props.currentSession.photos[this.props.currentPhotoIndex],
              file: blob,
            });
          });
        }, 500);
      }
    };

    canvas.rotateImage = degree => {
      if (canvas.cropper) {
        canvas.cropper.rotateTo(degree);
      }
    };

    canvas.applyRotate = () => {
      if (canvas.cropper) {
        this.props.setPhotoLoaded(false);

        // Set Canvas Dimension
        canvas.setDimensions({
          width: canvas.mainImg.width,
          height: canvas.mainImg.height,
        });

        // Canvas Main Photo Recover Scale
        canvas.mainImg
          .set({
            scaleX: 1,
            scaleY: 1,
          })
          .setCoords();

        canvas.renderAll();

        let data = canvas.cropper.getData();

        if (canvas.cropper) {
          canvas.cropper.destroy();
          canvas.cropper = null;
        }

        canvas.cropper = new Cropper(canvas.getElement(), {
          dragMode: 'none',
          autoCrop: false,
          background: false,
          checkCrossOrigin: true,
          checkOrientation: false,
          zoomable: false,
          ready() {},
        });

        setTimeout(() => {
          canvas.cropper.rotate(data.rotate);
          canvas.cropper.getCroppedCanvas().toBlob(blob => {
            uploadingBox('Started uploading', 'Uploading, please wait....', 'uploading');
            this.props.uploadCanvasPhoto({
              photo: this.props.currentSession.photos[this.props.currentPhotoIndex],
              file: blob,
            });
          });
        }, 500);
      }
    };

    canvas.filterImage = (value: GammaObject) => {
      let filter = new fabric.Image.filters.Gamma({
        gamma: [value.value, value.value, value.value],
      });

      canvas.mainImg.filters[0] = filter;
      canvas.mainImg.applyFilters();
      canvas.renderAll();
    };

    canvas.resetFiltering = () => {
      canvas.mainImg.filters = [];
      canvas.mainImg.applyFilters();
      canvas.renderAll();
    };

    canvas.saveFiltering = () => {
      canvas.uploadCanvas();
    };

    const self = this;

    canvas.on('mouse:down', event => {
      if (self.props.mode === EditorMode.ANNOTATING && self.props.activeAnnotationWindow === AnnotationWindow.BLANK) {
        const container: IContainer = {
          width: canvas.width,
          height: canvas.height,
        };
        const position: IPosition = { x: event.pointer.x, y: event.pointer.y };

        self.props.setActiveAnnotationPos(convertPixelPositionToPercent(container, position));

        self.props.setActiveAnnotationWindow(AnnotationWindow.NEW_ANNOTATION);
      }
    });

    canvas.on('selection:created', function() {
      const obj = canvas.getActiveObject();
      if (obj && obj.branding) {
        self.props.setActiveCanvasBranding(obj.branding);
      } else {
        self.props.setActiveCanvasBranding(null);
      }
    });

    canvas.on('selection:updated', function() {
      const obj = canvas.getActiveObject();
      if (obj && obj.branding) {
        self.props.setActiveCanvasBranding(obj.branding);
      } else {
        self.props.setActiveCanvasBranding(null);
      }
    });

    canvas.on('selection:cleared', function() {
      const obj = canvas.getActiveObject();
      if (obj && obj.branding) {
        self.props.setActiveCanvasBranding(obj.branding);
      } else {
        self.props.setActiveCanvasBranding(null);
      }
    });

    this.setState({ ...this.state, canvas });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentSession.photos[nextProps.currentPhotoIndex]) {
      let nextUpdatePhoto = nextProps.currentSession.photos[nextProps.currentPhotoIndex];
      if (
        this.state.mode !== nextProps.mode ||
        !this.state.photo ||
        (this.state.photo && this.state.photo.photo !== nextUpdatePhoto.photo)
      ) {
        this.setState({ ...this.state, mode: nextProps.mode, photo: nextUpdatePhoto }, () => {
          this.initializeCanvas();
        });
      }
    }
  }

  initializeCanvas() {
    const editor = document.querySelector('section.editor') as HTMLDivElement;

    if (!editor) {
      return;
    }

    let { canvas } = this.state;
    this.props.setPhotoLoaded(false);
    canvas.clear();

    if (canvas.cropper) {
      canvas.cropper.destroy();
      canvas.cropper = null;
    }

    const self = this;

    fabric.Image.fromURL(
      this.state.photo.photo + `?${generateHash(10)}`,
      function(img) {
        console.log('original width, height', img.width, img.height);
        canvas.add(img);

        const imageRatio = img.height / img.width;

        const editorWidth = editor.offsetWidth;
        const editorHeight = editor.offsetHeight;

        let width = editorWidth > img.width ? img.width : editorWidth;
        let height = width * imageRatio;

        if (height > editorHeight) {
          height = editorHeight;
          width = height / imageRatio;
        }

        const scale = width / img.width;

        canvas.setDimensions({ width, height });
        canvas.mainImg = img;
        canvas.renderAll();
        img.set({
          scaleX: scale,
          scaleY: scale,
        });
        img.center();

        self.setState({ photoScale: scale });
        self.props.setCanvas(canvas);
        setTimeout(() => self.props.setPhotoLoaded(true), 500); //Considering sidebar animation time

        if (self.state.mode === EditorMode.CROPPING) {
          setTimeout(() => {
            canvas.cropper = new Cropper(canvas.getElement(), {
              dragMode: 'none',
              background: false,
              aspectRatio: aspectRatioToValue[self.props.aspectRatio],
              checkCrossOrigin: true,
              checkOrientation: false,
              zoomable: false,
              ready() {},
            });
          }, 500);
        } else if (self.state.mode === EditorMode.ROTATING) {
          setTimeout(() => {
            canvas.cropper = new Cropper(canvas.getElement(), {
              dragMode: 'none',
              autoCrop: false,
              background: false,
              checkCrossOrigin: true,
              checkOrientation: false,
              zoomable: false,
              ready() {},
            });
          }, 500);
        }
      },
      {
        selectable: false,
        hoverCursor: 'default',
        crossOrigin: 'anonymous',
      },
    );
  }

  render() {
    return (
      <>
        <canvas id='canvas' />
      </>
    );
  }
}

const mapDispatchToProps = {
  setPhotoLoaded: Actions.setPhotoLoaded,
  setActiveAnnotationWindow: Actions.setActiveAnnotationWindow,
  setActiveAnnotationPos: Actions.setActiveAnnotationPos,
  setActiveCanvasBranding: Actions.setActiveCanvasBranding,
  setCanvas: Actions.setCanvas,
  setSidebarMode: Actions.setSidebarMode,
  uploadCanvasPhoto: SessionActions.uploadCanvasPhoto,
  updateBrandings: BrandingActions.updateBrandings,
};

const mapStateToProps = ({ editor, sessions }: ApplicationState) => ({
  sidebarMode: editor.sidebarMode,
  currentSession: sessions.currentSession,
  currentPhotoIndex: sessions.currentPhotoIndex,
  activeAnnotationWindow: editor.activeAnnotationWindow,
  aspectRatio: editor.aspectRatio,
  mode: editor.mode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Canvas as any);
