import { takeLatest, put, call } from "@redux-saga/core/effects";
import { ActionTypes, Actions } from "../reducers/AuthReducer";
import { Actions as UserActions } from "../reducers/UserReducer";
import { ActionWithPayload } from "../reducers/actionHelper";
import { LoginRequest } from "../model/Auth";
import { DASHBOARD, LOGIN } from "../router/Routes";
import { push } from "connected-react-router";
import { httpPost } from "../client/http";
import { message } from "antd";

const authUrl = 'api/auth/login/';
const logoutUrl = 'api/auth/logout/';
const TOKEN_KEY = '_t';

function* login(action: ActionWithPayload<ActionTypes.LOGIN, LoginRequest>) {
    try {
        const { key } = yield call(httpPost, authUrl, {
            username: action.payload.username,
            password: action.payload.password,
        }, false);
        localStorage.setItem(TOKEN_KEY, btoa(`${action.payload.username}_${key}`));
        yield put(Actions.loginSuccess({ username: action.payload.username }));
        yield put(UserActions.getUser());
        yield put(push(DASHBOARD));
    } catch (e) {
        message.error("Invalid username / password combination");
        yield put(Actions.loginFail());
    }
}

function* logout() {
    try {
        yield call(httpPost, logoutUrl, {});
    } catch (e) {
        console.log('LOGOUT FAILED', e);
    } finally {
        delete localStorage[TOKEN_KEY];
        yield put(Actions.notAuthenticated());
        yield put(push(LOGIN));
    }
}

function* checkAuth() {
    const tokenStr = localStorage.getItem(TOKEN_KEY);
    if (!!tokenStr) {
        const username = atob(tokenStr).split('_')[0];
        yield put(Actions.authenticated({ username }));
    } else {
        yield put(Actions.notAuthenticated());
    }
}

export function* authSaga() {
    yield takeLatest(ActionTypes.LOGIN, login);
    yield takeLatest(ActionTypes.CHECK_AUTH, checkAuth);
    yield takeLatest(ActionTypes.LOGOUT, logout);
}