import { call, takeLatest, put } from '@redux-saga/core/effects';
import { Actions, ActionTypes } from '../reducers/AnnotationPosReducer';
import {
  Actions as EditorActions,
  AnnotationWindow,
} from '../reducers/EditorReducer';
import {
  httpDelete,
  httpGet,
  multipartFormPatchSubmit,
  multipartFormSubmit,
} from '../client/http';
import { ActionWithPayload } from '../reducers/actionHelper';
import {
  NewAnnotationPosRequest,
  AnnotationPosListRequest,
  UpdateAnnotationPosRequest,
  AnnotationPos,
} from '../model/Annotation';
import { message, notification } from 'antd';

const annotionPosEndpoint = 'api/annotationPos';

export function* getAnnotationPoses(
  action: ActionWithPayload<
    ActionTypes.GET_ANNOTATIONPOSES,
    AnnotationPosListRequest
  >
) {
  try {
    const endpoint = `${annotionPosEndpoint}?photo=${action.payload.photo.id}`;
    const response = yield call(httpGet, endpoint);
    yield put(Actions.getAnnotationPosesSuccess(response));
  } catch (e) {
    yield put(Actions.getAnnotationPossFailure());
  }
}

export function* createAnnotationPos(
  action: ActionWithPayload<
    ActionTypes.CREATE_ANNOTATIONPOS,
    NewAnnotationPosRequest
  >
) {
  try {
    let { position_x, position_y, annotation, photo } = action.payload;
    let { id, title, description, icon, is_template, session } = annotation;

    const data = new FormData();

    if (annotation.imageFile) {
      data.append(
        'imageFile',
        annotation.imageFile.originFileObj,
        annotation.imageFile.name
      );
    }

    const annotationData = {
      id,
      title,
      description,
      icon,
      is_template,
      session,
    };

    if (annotation.image) {
      annotationData['image'] = { id: annotation.image.id };
    }

    data.append('position_x', position_x.toString());
    data.append('position_y', position_y.toString());
    data.append('photo', JSON.stringify({ id: photo.id }));
    data.append('annotation', JSON.stringify(annotationData));

    yield call(multipartFormSubmit, annotionPosEndpoint, data);

    yield put(Actions.createAnnotationPosSuccess({ photo }));
    yield put(EditorActions.setActiveAnnotationWindow(AnnotationWindow.BLANK));
    notification.close(title);
    message.success('Successfully created annotation');
  } catch (e) {
    console.log('Error creating branding', e);
    yield put(Actions.createAnnotationPosFailure());
  }
}

export function* updateAnnotationPos(
  action: ActionWithPayload<
    ActionTypes.UPDATE_ANNOTATIONPOS,
    UpdateAnnotationPosRequest
  >
) {
  try {
    let { id, annotation, photo } = action.payload;
    let { title, description, icon, is_template, session } = annotation;

    const data = new FormData();

    if (annotation.imageFile) {
      data.append(
        'imageFile',
        annotation.imageFile.originFileObj,
        annotation.imageFile.name
      );
    }

    const annotationData = {
      id: annotation.id,
      title,
      description,
      icon,
      is_template,
      session,
    };

    if (annotation.image) {
      annotationData['image'] = { id: annotation.image.id };
    }

    data.append('annotation', JSON.stringify(annotationData));

    yield call(
      multipartFormPatchSubmit,
      `${annotionPosEndpoint}/${id}?photo=${photo.id}`,
      data
    );

    yield put(Actions.updateAnnotationPosSuccess({ photo }));
    yield put(EditorActions.setActiveAnnotationWindow(AnnotationWindow.BLANK));
    notification.close(title);
    message.success('Successfully updated annotation');
  } catch (e) {
    console.log('Error creating branding', e);
    yield put(Actions.updateAnnotationPosFailure());
  }
}

export function* deleteAnnotationPos(
  action: ActionWithPayload<ActionTypes.DELETE_ANNOTATIONPOS, AnnotationPos>
) {
  try {
    yield call(
      httpDelete,
      `${annotionPosEndpoint}/${action.payload.id}?photo=${action.payload.photo.id}`
    );
    yield put(
      Actions.deleteAnnotationPosSuccess({ photo: action.payload.photo })
    );
    yield put(EditorActions.setActiveAnnotationWindow(AnnotationWindow.BLANK));
    message.success('Successfully deleted annotation.');
  } catch (e) {
    message.error('Error deleting annotation. Please try again.');
    yield put(Actions.deleteAnnotationPosFailure());
  }
}

export function* annotationPosSaga() {
  yield takeLatest(ActionTypes.GET_ANNOTATIONPOSES, getAnnotationPoses);
  yield takeLatest(ActionTypes.CREATE_ANNOTATIONPOS, createAnnotationPos);
  yield takeLatest(ActionTypes.UPDATE_ANNOTATIONPOS, updateAnnotationPos);
  yield takeLatest(
    ActionTypes.CREATE_ANNOTATIONPOS_SUCCESS,
    getAnnotationPoses
  );
  yield takeLatest(
    ActionTypes.UPDATE_ANNOTATIONPOS_SUCCESS,
    getAnnotationPoses
  );
  yield takeLatest(ActionTypes.DELETE_ANNOTATIONPOS, deleteAnnotationPos);
  yield takeLatest(
    ActionTypes.DELETE_ANNOTATIONPOS_SUCCESS,
    getAnnotationPoses
  );
}
