import * as React from 'react';
import { ApplicationState } from '../../../state/ApplicationState';
import { connect } from 'react-redux';
import { Button, Form, Input, Select, message, Checkbox, Icon } from 'antd';
import { ANNOTAION_ICONS, NewAnnotationPosRequest, AnnotationPos } from '../../../model/Annotation';
import { Actions, AnnotationWindow } from '../../../reducers/EditorReducer';
import { Actions as AnnotationPosActions } from '../../../reducers/AnnotationPosReducer';
import { Session } from '../../../model/Session';
import { IPosition } from '../../../util';
import { uploadingBox } from '../../dashboard/SessionForm';
import { renderImageList, getNonAnnotationPhotos } from '../../utils';
import Dragger from 'antd/lib/upload/Dragger';

const { TextArea } = Input;
const { Option } = Select;

interface Props {
  form: any;
  currentSession: Session;
  currentPhotoIndex: number;
  activeAnnotationPos: IPosition;
  setActiveAnnotationWindow: (activeAnnotationWindow: AnnotationWindow) => void;
  createAnnotationPos: (annotationPos: NewAnnotationPosRequest) => void;
  getAnnotations: (AnnotationListRequest) => void;
}

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

class NewAnnotationWindow extends React.PureComponent<Props, {}> {
  state = {
    file: null,
    activePhotoIndex: 0,
    isFileUpload: false,
  };

  submitNewAnnotation() {
    const photos = getNonAnnotationPhotos(this.props.currentSession);
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('No error from form submit', values);

        let annotation = {
          title: values['title'],
          description: values['description'],
          is_template: false,
          icon: values['icon'],
          session: this.props.currentSession.uuid,
        };

        if (this.state.file && this.state.isFileUpload) {
          annotation['imageFile'] = this.state.file;
        }

        if (this.state.activePhotoIndex >= 0 && !this.state.isFileUpload) {
          annotation['image'] = photos[this.state.activePhotoIndex];
        }

        let annotationPos: AnnotationPos = {
          annotation,
          photo: this.props.currentSession.photos[this.props.currentPhotoIndex],
          position_x: this.props.activeAnnotationPos.x,
          position_y: this.props.activeAnnotationPos.y,
        };

        this.props.createAnnotationPos(annotationPos);
        uploadingBox('Started registering of ' + values.title, 'Uploading, please wait....', values.title);
      } else {
        console.log('Form error', err);
      }
    });
  }

  onImageChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        file: info.file,
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const draggerProps = {
      multiple: false,
      name: 'photo-upload',
      onChange: this.onImageChange,
      customRequest: dummyRequest,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div className='new-annotation-window'>
        <Form layout='horizontal'>
          <Form.Item label='Title'>
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Title cannot be empty',
                },
              ],
            })(<Input placeholder='Annotation Title' />)}
          </Form.Item>
          <Form.Item label='Icon Type'>
            {getFieldDecorator('icon', {
              rules: [
                {
                  required: true,
                  message: 'Icon type cannot be empty',
                },
              ],
            })(
              <Select mode='default'>
                {ANNOTAION_ICONS.map(annotationIcon => (
                  <Option value={annotationIcon.value} key={annotationIcon.value}>
                    {annotationIcon.text}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Checkbox
            checked={this.state.isFileUpload}
            style={{ marginBottom: '10px' }}
            onClick={() => this.setState({ isFileUpload: !this.state.isFileUpload })}
          >
            File Upload
          </Checkbox>
          {this.state.isFileUpload && (
            <Form.Item label='Image'>
              <Dragger {...draggerProps}>
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
              </Dragger>
            </Form.Item>
          )}
          {!this.state.isFileUpload &&
            renderImageList(this.props.currentSession, this.state.activePhotoIndex, activePhotoIndex =>
              this.setState({ activePhotoIndex }),
            )}
          <Form.Item label='Description'>
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Description cannot be empty',
                },
              ],
            })(<TextArea rows={3} placeholder='Description of annotation' />)}
          </Form.Item>
        </Form>
        <div className='bottom'>
          <Button
            type='primary'
            onClick={() => {
              this.submitNewAnnotation();
            }}
          >
            Save
          </Button>
          <Button
            type='ghost'
            onClick={() => {
              this.props.setActiveAnnotationWindow(AnnotationWindow.BLANK);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export const form = Form.create({ name: 'NewAnnotationForm' })(NewAnnotationWindow);

const mapDispatchToProps = {
  setActiveAnnotationWindow: Actions.setActiveAnnotationWindow,
  createAnnotationPos: AnnotationPosActions.createAnnotationPos,
};

const mapStateToProps = ({ editor, sessions }: ApplicationState) => ({
  activeAnnotationPos: editor.activeAnnotationPos,
  currentSession: sessions.currentSession,
  currentPhotoIndex: sessions.currentPhotoIndex,
});

export default connect(mapStateToProps, mapDispatchToProps)(form as any);
