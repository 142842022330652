import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Session } from '../../model/Session';
import { Actions } from '../../reducers/SessionReducer';
import { Page } from '../layout/Page';
import { BASE_URL } from '../../client/http';
import { User } from '../../model/User';
import '@/styles/session.scss';
import { history } from '../../index';

interface Props {
  session: Session;
  user: User;
  exportSession: (session: Session) => void;
  downloadSession: (session: Session) => void;
  completeSession: (session: Session) => void;
}

export class SpinviewAltSessionPage extends React.Component<Props, {}> {
  componentWillMount() {
    window.addEventListener('message', this.handleIframeMessageHandler.bind(this), false);
  }

  handleIframeMessageHandler(e) {
    const data = e.data;
    if (data.type === 'ready') {
      this.sendMessage({ type: 'ready' });
    } else if (data.type == 'resize') {
      let iframe = document.getElementById('view-frame') as HTMLIFrameElement;

      if (iframe) {
        iframe.style['height'] = data.data.height + 'px';
        iframe.style['min-height'] = data.data.height + 'px';
        iframe.height = data.data.height + 'px';
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessageHandler, false);
  }

  sendMessage(message) {
    let iframe = document.getElementById('view-frame') as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(message)), '*');
    }
  }

  downloadSession = () => {
    let session = {
      ...this.props.session,
      downloadOptions: ['spinviewer-alt'],
    } as Session;
    this.props.downloadSession(session);
  };

  completeSession = () => {
    let session = { ...this.props.session, complete: true } as Session;
    this.props.completeSession(session);
  };

  openEditing = () => {
    history.push('/spinviewer-editor/spinviewer-alt/' + this.props.session.uuid + '/');
  };

  renderSubmenu() {
    return (
      <div className='session-submenu'>
        <ul>
          {this.props.user && this.props.user.active_site && this.props.user.active_site.use_spinviewer_main && (
            <li className='session-submenu-item' onClick={() => this.openEditing()}>
              Open Editor
            </li>
          )}
          {this.props.user &&
            this.props.user.active_site &&
            this.props.session.photos.length === 0 && [
              <li className='session-submenu-item' onClick={() => this.downloadSession()}>
                Download
              </li>,
              <li className='session-submenu-item' onClick={() => this.completeSession()}>
                Mark as Complete
              </li>,
            ]}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <Page isFullWidth={true}>
        {this.renderSubmenu()}
        <div className='spinview-overlay'></div>
        {this.props.user && this.props.user.active_site && (
          <div className='spinview-preview'>
            <iframe
              src={`${BASE_URL}/view_session/${this.props.user.active_site.key}/${this.props.session.vehicle.vin}/spinviewer-alt/hide`}
              width='100%'
              height='100%'
              id='view-frame'
            ></iframe>
          </div>
        )}
      </Page>
    );
  }
}

const mapStateToProps = ({ user, sessions }: ApplicationState) => ({
  user: user,
  session: sessions.currentSession,
});

const mapDispatchToProps = {
  exportSession: Actions.setSessionExport,
  downloadSession: Actions.setSessionDownload,
  completeSession: Actions.completeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpinviewAltSessionPage as any);
