import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface Props {
  isLoading: boolean;
}

export class Loading extends React.PureComponent<Props, {}> {
  render() {
    if (this.props.isLoading) {
      return (
        <div className='loading-overlay'>
          <FontAwesomeIcon icon={faCircleNotch} spin={true} />
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}
