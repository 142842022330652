import { ActionCreatorsMapObject } from "redux";


export interface Action<T extends string> {
    type: T;
}

export interface ActionWithPayload<T extends string, P, M = {}> extends Action<T> {
    payload: P;
    error: false;
    meta: M;
}

export interface  ActionWithError<T extends string, M = {}> extends Action<T> {
    payload: Error;
    error: true;
    meta: M;
}

export type AnyAction = Action<any> | ActionWithPayload<any, any> | ActionWithError<any>;

export function createAction<T extends string>(type: T): Action<T>
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>
export function createAction<T extends string, P>(type: T, payload?: P) {
    return payload === undefined ? { type } : { type, payload };
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = Extract<
    ReturnType<A[keyof A]>,
    AnyAction
>;