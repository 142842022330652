import * as React from 'react';
import { ApplicationState } from '../../../state/ApplicationState';
import { connect } from 'react-redux';
import '../../../styles/editor-annotation-window.css';
import { Actions as EditorActions, AnnotationWindow } from '../../../reducers/EditorReducer';
import { Actions } from '../../../reducers/AnnotationPosReducer';
import { AnnotationPoses, AnnotationPosListRequest, AnnotationPos } from '../../../model/Annotation';
import { IPosition, convertPercentToPixelPosition } from '../../../util';
import NewAnnotationWindow from './NewAnnotationWindow';
import { Session } from '../../../model/Session';
import { Button, Modal } from 'antd';

interface Props {
  isPhotoLoaded: boolean;
  activeAnnotationWindow: AnnotationWindow;
  activeAnnotationPos: IPosition;
  annotationPoses: AnnotationPoses;
  editAnnotationPos: AnnotationPos;
  currentSession: Session;
  currentPhotoIndex: number;
  getAnnotationPoses: (AnnotationPosListRequest: AnnotationPosListRequest) => void;
  deleteAnnotationPos: (annotationPos: AnnotationPos) => void;
  setActiveAnnotationWindow: (annotationWindow: AnnotationWindow) => void;
}

interface IState {
  previewAnnotationPos: AnnotationPos;
}

class AnnotationEditorWindow extends React.PureComponent<Props, {}> {
  state: IState = {
    previewAnnotationPos: null,
  };
  handleAnnotationPreviewCancel: any;

  componentWillMount() {
    this.props.getAnnotationPoses({
      photo: this.props.currentSession.photos[this.props.currentPhotoIndex],
    });
  }

  getOffsetPosition(position: IPosition) {
    const canvasElement = document.getElementById('canvas-wrapper') as HTMLCanvasElement;
    let pos = convertPercentToPixelPosition(
      { width: canvasElement.clientWidth, height: canvasElement.clientHeight },
      position,
    );
    pos.x = canvasElement.offsetLeft + pos.x;
    pos.y = canvasElement.offsetTop + pos.y;
    return pos;
  }

  annotationPreviewHandler(annotationPos: AnnotationPos) {
    this.props.setActiveAnnotationWindow(AnnotationWindow.PREVIEW_ANNOTAION);
    this.setState({ ...this.state, previewAnnotationPos: annotationPos });
  }

  renderAnnotationPoses() {
    return this.props.annotationPoses.map((annotationPos, index) => {
      const position = this.getOffsetPosition({
        x: annotationPos.position_x,
        y: annotationPos.position_y,
      });
      return (
        <div
          className={`annotation-pos ${
            this.props.editAnnotationPos && this.props.editAnnotationPos.id === annotationPos.id ? 'active' : ''
          }`}
          style={{
            top: `${position.y}px`,
            left: `${position.x}px`,
          }}
          key={'annotation-pos-' + index}
          onClick={() => {
            this.annotationPreviewHandler(annotationPos);
          }}
        >
          <div className='annotation-pos-icon circle-icon' />
        </div>
      );
    });
  }

  renderAnnotationPreviewWindow() {
    const { previewAnnotationPos } = this.state;

    if (previewAnnotationPos) {
      return (
        <div className='annotation-preview-window'>
          <h3 className='title'> {previewAnnotationPos.annotation.title}</h3>
          <div className='annotation-preview-photo'>
            <img src={previewAnnotationPos.annotation.image.photo} alt='Annotation Display'></img>
          </div>
          <h3 className='description'>{previewAnnotationPos.annotation.description}</h3>
        </div>
      );
    }
  }

  renderAnnotationPos(position: IPosition) {
    const offsetPosition = this.getOffsetPosition(position);

    return (
      <div
        className='annotation-pos'
        style={{
          top: `${offsetPosition.y}px`,
          left: `${offsetPosition.x}px`,
        }}
      >
        <div className='annotation-pos-icon circle-icon' />
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderAnnotationPoses()}
        {this.props.activeAnnotationWindow === AnnotationWindow.NEW_ANNOTATION &&
          this.props.activeAnnotationPos != null &&
          this.renderAnnotationPos(this.props.activeAnnotationPos)}
        <Modal
          className='annotation-new-modal'
          destroyOnClose={true}
          title='Create Annotation'
          visible={
            this.props.activeAnnotationWindow === AnnotationWindow.NEW_ANNOTATION &&
            this.props.activeAnnotationPos != null
          }
          onCancel={() => this.props.setActiveAnnotationWindow(AnnotationWindow.BLANK)}
          footer={false}
          width={400}
        >
          <NewAnnotationWindow />
        </Modal>
        <Modal
          className='annotation-preview-modal'
          destroyOnClose={true}
          title='Annotation'
          visible={
            this.props.activeAnnotationWindow === AnnotationWindow.PREVIEW_ANNOTAION &&
            this.state.previewAnnotationPos != null
          }
          onCancel={() => this.props.setActiveAnnotationWindow(AnnotationWindow.BLANK)}
          footer={false}
          width={400}
        >
          {this.renderAnnotationPreviewWindow()}
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = {
  getAnnotationPoses: Actions.getAnnotationPoses,
  setActiveAnnotationWindow: EditorActions.setActiveAnnotationWindow,
  deleteAnnotationPos: Actions.deleteAnnotationPos,
};

const mapStateToProps = ({ editor, annotationPoses, sessions }: ApplicationState) => ({
  isPhotoLoaded: editor.isPhotoLoaded,
  activeAnnotationWindow: editor.activeAnnotationWindow,
  activeAnnotationPos: editor.activeAnnotationPos,
  annotationPoses: annotationPoses.annotationPoses,
  currentSession: sessions.currentSession,
  currentPhotoIndex: sessions.currentPhotoIndex,
  editAnnotationPos: annotationPoses.editAnnotationPos,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationEditorWindow as any);
