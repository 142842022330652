import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Table, Checkbox } from 'antd';
import { Actions } from '../../reducers/ClassificationReducer';
import { Classifications } from '../../model/Classification';
import { Loading } from '../layout/Loading';
import '@/styles/classifications.css';
import Button from 'antd/lib/button/button';
import ClassificationForm from './ClassificationForm';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Column } = Table;

interface Props {
  isLoading: boolean;
  classifications: Classifications;
  getClassifications: () => void;
  showModal: () => void;
  deleteClassification: (id: string) => void;
}

class ClassificationsPage extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.getClassifications();
  }
  renderDeleteClassificationButton(id: string) {
    return (
      <Button type='danger' onClick={() => this.props.deleteClassification(id)}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    );
  }
  render() {
    return (
      <Loading isLoading={this.props.isLoading}>
        <ClassificationForm />
        <div className='classifications'>
          <div className='add-classification-row'>
            <Button type='primary' onClick={() => this.props.showModal()}>
              Add Classification
            </Button>
          </div>
          <Table dataSource={this.props.classifications}>
            <Column
              title='Thumbnail'
              dataIndex='thumbnail'
              key='thumbnail'
              render={thumbnail => <img src={thumbnail} height='100px' width='120px' alt='Classification thumbnail' />}
            />
            <Column title='Name' dataIndex='name' key='name' />
            <Column
              title='Required'
              dataIndex='required'
              key='required'
              render={required => <Checkbox checked={required}>Required</Checkbox>}
            />
            <Column title={'Order'} dataIndex={'order'} key={'order'} />
            <Column title='Delete' dataIndex='id' key='id1' render={id => this.renderDeleteClassificationButton(id)} />
          </Table>
        </div>
      </Loading>
    );
  }
}

const mapStateToProps = ({ classifications }: ApplicationState) => ({
  classifications: classifications.classifications,
});

const mapDispatchToProps = {
  getClassifications: Actions.getClassifications,
  showModal: Actions.showClassificationModal,
  deleteClassification: Actions.deleteClassification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationsPage);
