import { Photo, Session } from '../model/Session';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { generateHash } from '../components/utils';

export interface IContainer {
  width: number;
  height: number;
}

export interface IPosition {
  x: number;
  y: number;
}

export function convertPixelPositionToPercent(container: IContainer, position: IPosition) {
  let newPosition: IPosition = { x: 0, y: 0 };

  newPosition.x = Math.floor((position.x * 100) / container.width);
  newPosition.y = Math.floor((position.y * 100) / container.height);

  return newPosition;
}

export function convertPercentToPixelPosition(container: IContainer, position: IPosition) {
  let newPosition: IPosition = { x: 0, y: 0 };

  newPosition.x = Math.floor((container.width * position.x) / 100);
  newPosition.y = Math.floor((container.height * position.y) / 100);

  return newPosition;
}

export function getURLExtension(url) {
  url = url.split('/')[url.split('/').length - 1];
  const splitURL = url.split('.');

  if (splitURL.length > 1) {
    const extension = splitURL[1];
    return extension.split('?')[0];
  } else {
    return '';
  }
}

export async function downloadPhotosAsZip(session: Session, downloadOptions: string[]) {
  // \vin: string, photos: Photo[],
  let zip = new JSZip();

  const photos = session.photos;
  const vin = session.vehicle.vin;

  if (downloadOptions.includes('photos') && photos) {
    for (const i in photos) {
      const photoBlob = await fetch(photos[i].photo + `?${generateHash(10)}`).then(response => response.blob());
      const photoExtension = getURLExtension(photos[i].photo);
      zip.file(`${vin}-${i}.${photoExtension || 'jpg'}`, photoBlob);
    }
  }

  // if (downloadOptions.includes('spinviewer') && session.spinviewer_nav) {
  //   const photoBlob = await fetch(session.spinviewer_media).then((response) =>
  //     response.blob()
  //   );
  //   const photoExtension = getURLExtension(session.spinviewer_media);
  //   zip.file(`${vin}-spinviewer.${photoExtension || 'jpg'}`, photoBlob);
  // }

  if (downloadOptions.includes('panoviewer') && session.panoviewer_nav) {
    const photoBlob = await fetch(session.panoviewer_media + `?${generateHash(10)}`).then(response => response.blob());
    const photoExtension = getURLExtension(session.panoviewer_media);
    zip.file(`${vin}-panoviewer.${photoExtension || 'jpg'}`, photoBlob);
  }

  // /* Generate a zip file asynchronously and trigger the download */
  zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, `${vin}`));
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getQueryParam(name: string) {
  const queryParams = new URLSearchParams(window.location.search);

  return queryParams.get(name);
}
