import * as React from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/AdminReducer';
import { BrandingTask } from '../../model/BrandingTask';
import { Actions as BrandingActions } from '../../reducers/BrandingReducer';
import { Actions as ClassificationActions } from '../../reducers/ClassificationReducer';
import { Brandings } from '../../model/Branding';
import { Classifications } from '../../model/Classification';

interface Props {
  // props
  form: any;
  showEditModal: boolean;
  brandingTask: BrandingTask;
  closeModal: () => void;
  getBrandings: () => void;
  getClassifications: () => void;
  createBrandingTask: (brandingTask: BrandingTask) => void;
  updateBrandingTask: (brandingTask: BrandingTask) => void;
  brandings: Brandings;
  classifications: Classifications;
}

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17 },
};

interface State {
  // state
}

class BrandingTaskEditForm extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getBrandings();
    this.props.getClassifications();
  }

  componentWillReceiveProps(nextProps: Props) {
    // Open Modal Case
    if (this.props.showEditModal == false && nextProps.showEditModal == true) {
      this.initFormValue(nextProps.brandingTask);
    }
  }

  initFormValue(brandingTask) {
    if (brandingTask) {
      this.props.form.getFieldDecorator('trigger', { initialValue: brandingTask.trigger });
      if (brandingTask.trigger == 'order') {
        this.props.form.getFieldDecorator('order', { initialValue: brandingTask.order });
      }
      if (brandingTask.trigger == 'classification') {
        this.props.form.getFieldDecorator('classification', { initialValue: brandingTask.classification.id });
      }
      this.props.form.getFieldDecorator('branding', { initialValue: brandingTask.branding.id });
    }
  }

  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('No error from form submit', values);
        const brandingTask: BrandingTask = {
          trigger: values['trigger'],
          classification: values['trigger'] === 'classification' ? { id: values['classification'] } : undefined,
          order: values['trigger'] === 'order' ? values['order'] : undefined,
          branding: { id: values['branding'] },
        };

        if (this.props.brandingTask) {
          brandingTask.id = this.props.brandingTask.id;
          this.props.updateBrandingTask(brandingTask);
        } else {
          this.props.createBrandingTask(brandingTask);
        }
        this.props.form.resetFields();
        this.props.closeModal();
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleModalCancel = () => {
    this.handleReset();
    this.props.closeModal();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title='Branding Task Information!'
        visible={this.props.showEditModal}
        onOk={this.handleModalOk}
        onCancel={this.handleModalCancel}
      >
        <Form layout='horizontal' {...formItemLayout}>
          <Form.Item label='Trigger Type'>
            {getFieldDecorator('trigger', {
              rules: [
                {
                  required: true,
                  message: 'The trigger type is required!',
                },
              ],
            })(
              <Select mode='default' placeholder='Trigger Type:'>
                <Option value='all'>All</Option>
                <Option value='order'>Order</Option>
                <Option value='classification'>Classification</Option>
              </Select>,
            )}
          </Form.Item>
          {this.props.form.getFieldValue('trigger') === 'classification' && (
            <Form.Item label='Classification'>
              {getFieldDecorator('classification', {
                rules: [
                  {
                    required: true,
                    message: 'The classification is required!',
                  },
                ],
              })(
                <Select mode='default' placeholder='Classification:'>
                  {this.props.classifications.map(classification => (
                    <Option value={classification.id} key={classification.id}>
                      {classification.name}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          )}
          {this.props.form.getFieldValue('trigger') === 'order' && (
            <Form.Item label='Order'>
              {getFieldDecorator('order', {
                rules: [
                  {
                    required: true,
                    message: 'The order is required!',
                  },
                ],
              })(
                <Select mode='default' placeholder='Order:'>
                  {Array.from(Array(50).keys()).map(index => (
                    <Option value={index + 1} key={index + 1}>
                      {index + 1}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          )}
          <Form.Item label='Branding'>
            {getFieldDecorator('branding', {
              rules: [
                {
                  required: true,
                  message: 'The branding is required!',
                },
              ],
            })(
              <Select mode='default' placeholder='Branding:'>
                {this.props.brandings
                  .filter(b => b.default_set)
                  .map(branding => (
                    <Option value={branding.id} key={branding.id}>
                      {branding.name}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export const form = Form.create({ name: 'BrandingTaskEditoForm' })(BrandingTaskEditForm);

const mapStateToProps = ({ admin, brandings, classifications }: ApplicationState) => ({
  brandings: brandings.brandings,
  classifications: classifications.classifications,
  showEditModal: admin.showBrandingTaskEditModal,
  brandingTask: admin.edittedBrandingTask,
});

const mapDispatchToProps = {
  getClassifications: ClassificationActions.getClassifications,
  getBrandings: BrandingActions.getBrandings,
  createBrandingTask: Actions.createBrandingTask,
  updateBrandingTask: Actions.updateBrandingTask,
  closeModal: Actions.hideBrandingTaskEditModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
