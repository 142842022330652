import { SitesState } from "../state/ApplicationState";
import { Sites } from "../model/Site";
import { ActionsUnion, createAction } from "./actionHelper";

export const INITIAL_STATE_SITES = {
    isLoading: false,
    isError: false,
    errorMessage: "",
    sites: []
};

export enum ActionTypes {
    GET_SITES = 'GET_SITES',
    GET_SITES_SUCCESS = 'GET_SITES_SUCCESS',
    GET_SITES_ERROR = 'GET_SITES_ERROR',
}

export function siteReducer(state: SitesState = INITIAL_STATE_SITES, action: any): SitesState {
    switch (action.type) {
        case ActionTypes.GET_SITES:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: "",
            };
        case ActionTypes.GET_SITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorMessage: "",
                sites: action.payload
            };
        case ActionTypes.GET_SITES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: action.errorMessage,
                sites: []
            };
        default:
            return state;
    }
}

export const Actions = {
    getSites: () => createAction(ActionTypes.GET_SITES),
    getSitesSuccess: (sites: Sites) => createAction(ActionTypes.GET_SITES_SUCCESS, sites),
    getSitesError: () => createAction(ActionTypes.GET_SITES_ERROR),
};

export type SitesActions = ActionsUnion<typeof Actions>;