import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Upload, message, Icon, Checkbox } from 'antd';
import { SpinAnnotation } from '../../../model/Annotation';
import { ApplicationState } from '../../../state/ApplicationState';
import TextArea from 'antd/lib/input/TextArea';
import { isDefined, renderImageList, getNonAnnotationPhotos } from '../../utils';
import { Session } from '../../../model/Session';

interface Props {
  form: any;
  annotation: SpinAnnotation;
  onSave: (annotation: SpinAnnotation) => void;
  onCancel: () => void;
  session: Session;
}

const { Dragger } = Upload;

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

class SpinviewAnnotationForm extends React.PureComponent<Props, {}> {
  state = {
    file: null,
    activePhotoIndex: 0,
    isFileUpload: false,
    isInitForm: false,
  };

  initFormValue() {
    if (!this.state.isInitForm) {
      this.props.form.getFieldDecorator('title', { initialValue: this.props.annotation.title });
      this.props.form.getFieldDecorator('description', { initialValue: this.props.annotation.description });

      if (this.props.annotation.image) {
        const activePhotoIndex = getNonAnnotationPhotos(this.props.session).findIndex(
          photo => photo.id === this.props.annotation.image.id,
        );
        if (activePhotoIndex >= 0) {
          this.setState({ activePhotoIndex, isFileUpload: false });
        }
      }

      this.setState({ isInitForm: true });
    }
  }

  onSave() {
    this.props.form.validateFields((err, values) => {
      if (!this.state.file && !this.props.annotation.image && this.state.isFileUpload) {
        message.error(`Please upload a file.`);
        return;
      }

      let allValues = { ...values };

      if (this.state.file && this.state.isFileUpload) {
        allValues['imageFile'] = this.state.file;
      }

      if (this.state.activePhotoIndex >= 0 && !this.state.isFileUpload) {
        allValues['image'] = getNonAnnotationPhotos(this.props.session)[this.state.activePhotoIndex];
      }

      if (!err) {
        if (this.props.onSave) {
          const newAnnotation = Object.assign(this.props.annotation, allValues);
          this.props.onSave(newAnnotation);
          this.props.form.resetFields();
          this.setState({ file: null });
        }
      } else {
        console.log('Form error', err);
      }
    });
  }

  onImageChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        file: info.file,
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const draggerProps = {
      multiple: false,
      name: 'spinview-upload',
      onChange: this.onImageChange,
      customRequest: dummyRequest,
    };

    if (this.props.annotation && isDefined(this.props.annotation.id)) {
      this.initFormValue();
    }

    const { getFieldDecorator } = this.props.form;

    return (
      <div className='spinview-annotation-form'>
        <Form layout='horizontal'>
          <Form.Item label='Title'>
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Title cannot be empty',
                },
              ],
            })(<Input placeholder='Annotation Title' />)}
          </Form.Item>
          <Checkbox
            checked={this.state.isFileUpload}
            style={{ marginBottom: '10px' }}
            onClick={() => this.setState({ isFileUpload: !this.state.isFileUpload })}
          >
            File Upload
          </Checkbox>
          {this.state.isFileUpload && (
            <Form.Item label='Image'>
              <Dragger {...draggerProps}>
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
              </Dragger>
            </Form.Item>
          )}
          {!this.state.isFileUpload &&
            renderImageList(this.props.session, this.state.activePhotoIndex, activePhotoIndex => {
              this.setState({ activePhotoIndex });
            })}
          <Form.Item label='Description'>
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Description cannot be empty',
                },
              ],
            })(<TextArea rows={3} placeholder='Description of annotation' />)}
          </Form.Item>
        </Form>
        <div className='bottom'>
          <Button type='primary' onClick={() => this.onSave()}>
            Save
          </Button>
          <Button type='ghost' onClick={() => this.props.onCancel()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export const form = Form.create({ name: 'SpinViewAnnotationForm' })(SpinviewAnnotationForm);

const mapDispatchToProps = {};

const mapStateToProps = ({}: ApplicationState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(form as any);
