import { User } from '../model/User'
import { ActionsUnion, createAction } from "./actionHelper";


export const INITIAL_USER_STATE = {
    id: '',
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    tenant_admin: false,
    tenant: "",
    last_login: "",
    sites: []
};

export enum ActionTypes {
    GET_USER = 'GET_USER',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    UPDATE_USER_ACTIVE_SITE = 'UPDATE_USER_ACTIVE_SITE'
}

export function UserReducer(state: User = INITIAL_USER_STATE, action: any): User {
    switch (action.type) {
        case (ActionTypes.GET_USER_SUCCESS):
            return {
                ...state,
                ...action.payload,
            };
        case (ActionTypes.UPDATE_USER_ACTIVE_SITE):
            return {
                ...state,
                ...action.payload
            };
    }
    return state
}


export const Actions = {
    getUser: () => { console.log("get user action"); return createAction(ActionTypes.GET_USER) },
    getUserSuccess: (user: User) => createAction(ActionTypes.GET_USER_SUCCESS, user),
    updateUserActiveSite: (user: User) => createAction(ActionTypes.UPDATE_USER_ACTIVE_SITE, user),
};

export type UserActions = ActionsUnion<typeof Actions>;