import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/SiteReducer';
import { Collapse, Input, Row } from 'antd';
import '@/styles/check.scss';
import { Sites } from '../../model/Site';
import { Page } from '../layout/Page';

const { Panel } = Collapse;

interface MatchParams {
  sessionId: string;
}

interface Props {
  sites: Sites;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
  getSites: () => void;
}

interface Dispatch {
  getSites: () => void;
}

class SitesComponent extends React.Component<Props, {}> {
  componentWillMount(): void {
    this.props.getSites();
  }

  render() {
    return (
      <Page isFullWidth={false}>
        <Row style={{ textAlign: 'center', paddingBottom: '2em', paddingTop: '2em' }}>
          <h2>
            <b>Site Locations</b>
          </h2>
          <h4>
            Locations are professional photography booths that automatically integrate with Jombler. Please contact Ovad
            to learn how you can setup more locations.
          </h4>
        </Row>
        <Collapse accordion>
          {this.props.sites.map((site, index) => {
            let header = (
              <div>
                <h2 style={{ textAlign: 'left' }}>{site.name}</h2>
              </div>
            );
            return (
              <Panel header={header} key={site.name + index}>
                <div>
                  <div>{site.description}</div>
                  <h3>Integration Key</h3>
                  <p>
                    Your site integration key is used to integrate your photo booth with Jombler so photos are
                    automatically uploaded and associated with your car.
                  </p>
                  <p>
                    <Input type={'text'} value={site.key} disabled={true} />
                  </p>
                  <div>
                    Total cars processed: <span style={{ textAlign: 'center' }}> {site.car_count}</span>
                  </div>
                  <div>
                    Total photos uploaded: <span style={{ textAlign: 'center' }}> {site.photo_count}</span>
                  </div>
                </div>
              </Panel>
            );
          })}
        </Collapse>
      </Page>
    );
  }
}

const mapStateToProps = ({ sites }: ApplicationState) => ({
  sites: sites.sites,
  isError: sites.isError,
  isLoading: sites.isLoading,
  errorMessage: sites.errorMessage,
});

const mapDispatchToProps = {
  getSites: Actions.getSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesComponent);
