import { INITIAL_STATE_AUTH } from '../reducers/AuthReducer';
import {
  AfterPhotos,
  BeforePhotos,
  Photo,
  Session,
  Sessions,
} from '../model/Session';
import { INITIAL_STATE_SESSIONS } from '../reducers/SessionReducer';
import { Classifications } from '../model/Classification';
import { TenantUsers } from '../model/TenantUser';
import { INITIAL_STATE_CLASSIFICATIONS } from '../reducers/ClassificationReducer';
import { Site, Sites } from '../model/Site';
import { INITIAL_STATE_SITES } from '../reducers/SiteReducer';
import { Tenant } from '../model/User';
import { INITIAL_USER_STATE } from '../reducers/UserReducer';
import { INITIAL_STATE_ADMIN } from '../reducers/AdminReducer';
import {
  INITIAL_STATE_EDITOR,
  EditorMode,
  SidebarMode,
  AnnotationWindow,
} from '../reducers/EditorReducer';
import { Brandings, Branding } from '../model/Branding';
import { INITIAL_STATE_BRANDINGS } from '../reducers/BrandingReducer';
import { IPosition } from '../util';
import {
  AnnotationPoses,
  Annotations,
  SpinAnnotations,
  PanoAnnotations,
  AnnotationPos,
} from '../model/Annotation';
import { INITIAL_STATE_ANNOTATIONPOSES } from '../reducers/AnnotationPosReducer';
import { INITIAL_STATE_METRICS } from '../reducers/MetricReducer';
import { INITIAL_STATE_ANNOTATIONS } from '../reducers/AnnotationReducer';
import { BrandingTask, BrandingTasks } from '../model/BrandingTask';
import internal from 'assert';

export interface PageLevelState {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}

export interface AuthState extends PageLevelState {
  username: string;
  isAuthenticated: boolean;
}

export interface SessionState extends PageLevelState {
  sessions: Sessions;
  filteredSessions: Sessions;
  sessionCount: number;
  completeSessionCount: number;
  currentSession?: Session;
  currentPhoto?: Photo;
  newSessionError: boolean;
  newSessionLoading: boolean;
  currentPhotoIndex: number;
  afterPhotos: AfterPhotos;
  beforePhotos: BeforePhotos;
  newSessionModalOpen: boolean;
  exportErrorMessage: string;
  downloadErrorMessage: string;
  completeErrorMessage: string;
  sessionsLoading: string[];
  photoUploadFormOpen: boolean;
  sessionsPaginationPage: number;
  sessionsPaginationPageSize: number;
  totalCount: number;
}

export interface SitesState {
  sites: Sites;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
}

export interface ClassificationState extends PageLevelState {
  classifications: Classifications;
  showModal: boolean;
}

export interface AdminState extends PageLevelState {
  tenantUsers: TenantUsers;
  showCreateModal: boolean;
  showEditModal: boolean;
  edittedUserId: string;
  showBrandingTaskEditModal: boolean;
  edittedBrandingTask: BrandingTask;
  brandingTasks: BrandingTasks;
}

export interface EditorState extends PageLevelState {
  isPhotoLoaded: boolean;
  mode: EditorMode;
  zoom: number;
  aspectRatio: string;
  sidebarMode: SidebarMode;
  activeAnnotationWindow: AnnotationWindow;
  activeAnnotationPos: IPosition;
  activeBranding: Branding;
  activeCanvasBranding: Branding;
  canvas: any;
}

export interface AnnotationState extends PageLevelState {
  annotations: Annotations;
  spin_annotations: SpinAnnotations;
  pano_annotations: PanoAnnotations;
}

export interface BrandingState extends PageLevelState {
  brandings: Brandings;
}

export interface AnnotationPosState extends PageLevelState {
  annotationPoses: AnnotationPoses;
  editAnnotationPos: AnnotationPos;
}

export interface UserState extends PageLevelState {
  id?: string;
  last_login: string;
  tenant_admin: boolean;
  tenant: Tenant | string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  active_site?: Site;
}

export interface MetricState extends PageLevelState {
  photoCount: Number;
  vehicleCount: Number;
  avgTime: Number;
  avgPhotos: Number;
  recentSession: Session | null;
}

export interface ApplicationState {
  auth: AuthState;
  sessions: SessionState;
  classifications: ClassificationState;
  sites: SitesState;
  editor: EditorState;
  brandings: BrandingState;
  annotations: AnnotationState;
  annotationPoses: AnnotationPosState;
  user: UserState;
  admin: AdminState;
  metrics: MetricState;
}

export const INITIAL_STATE = {
  auth: INITIAL_STATE_AUTH,
  sessions: INITIAL_STATE_SESSIONS,
  classifications: INITIAL_STATE_CLASSIFICATIONS,
  sites: INITIAL_STATE_SITES,
  editor: INITIAL_STATE_EDITOR,
  brandings: INITIAL_STATE_BRANDINGS,
  annotations: INITIAL_STATE_ANNOTATIONS,
  annotationPoses: INITIAL_STATE_ANNOTATIONPOSES,
  user: INITIAL_USER_STATE,
  admin: INITIAL_STATE_ADMIN,
  metrics: INITIAL_STATE_METRICS,
};
