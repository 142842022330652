import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';

interface Props {
  isAuthenticated: boolean;
}

class CategorizeVin extends React.Component<Props, {}> {
  render() {
    return <h1>Categorize Vin</h1>;
  }
}

const mapStateToProps = ({ auth }: ApplicationState): Props => ({
  isAuthenticated: auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(CategorizeVin);
