import { takeLatest, put, call, select, takeEvery } from '@redux-saga/core/effects';
import { Actions, ActionTypes } from '../reducers/SessionReducer';
import { Actions as AnnotationActions } from '../reducers/AnnotationReducer';
import { httpDelete, httpGet, httpPatch, multipartFormSubmit, multipartFormPatchSubmit } from '../client/http';
import { ActionWithPayload } from '../reducers/actionHelper';
import {
  AfterPhotos,
  BeforePhotos,
  ChangePhotoPayload,
  GetSession,
  NavigatePhotoDirection,
  NewSessionRequest,
  Photo,
  Session,
  PhotoUploadRequest,
  PhotoCanvasUploadRequest,
  DeletePhotoPayload,
  SetSpinviewerPhotoSortPayload,
  SetPanoviewerCenterPayload,
} from '../model/Session';
import { findIndex, isEmpty } from 'lodash';
import { message, notification } from 'antd';
import { history } from '../index';
import { FINALIZE } from '../router/Routes';
import { delay } from 'redux-saga/effects';
import { downloadPhotosAsZip } from '../util';

const SESSIONS_URL = 'api/session/';
const PHOTO_URL = 'api/photo';
const SET_SPINVIEWER_PHOTO_SORT_URL = 'api/spinviewer-sort/';
const SET_PANOVIEWER_CENTER_URL = 'api/panoviewer-center/';

export function* exportSession(action: ActionWithPayload<ActionTypes.SET_SESSION_EXPORT, Session>) {
  let response = yield call(
    httpPatch,
    SESSIONS_URL + action.payload.uuid,
    {
      export: true,
      photos: action.payload.photos.map(photo => {
        return { id: photo.id };
      }),
    },
    true,
    true,
  );

  if (response.status === 500) {
    // server error, display as such
    yield put(Actions.exportSessionFailure('There was a server error submitting your request.'));
    return;
  }

  let data = yield response.json();
  if ('error' in data) {
    let m = '';
    if (data.missing_classifications.length > 0) {
      let list = '';
      data.missing_classifications.forEach((v, i) => {
        list += v + ', ';
      });
      list = list.slice(0, -2);
      m = 'You are missing required classifications: ' + list;
    } else if (data.unclassified_photos.length > 0) {
      m = "You haven't classified every photo";
    } else {
      m = data.error;
    }
    yield put(Actions.exportSessionFailure(m));
    yield delay(3000);
    yield put(Actions.exportSessionFailure(''));
  } else {
    message.success('Finalization job is started. Once it is done, it will be notified the status.');
  }
}

export function* downloadSession(action: ActionWithPayload<ActionTypes.SET_SESSION_EXPORT, Session>) {
  let response = yield call(
    httpPatch,
    SESSIONS_URL + action.payload.uuid,
    {
      download: true,
      download_options: action.payload.downloadOptions,
      photos: action.payload.photos.map(photo => {
        return { id: photo.id };
      }),
    },
    true,
    true,
  );

  if (response.status === 500) {
    // server error, display as such
    yield put(Actions.exportSessionFailure('There was a server error submitting your request.'));
    return;
  }

  let data = yield response.json();
  if ('error' in data) {
    let m = '';
    if (data.missing_classifications.length > 0) {
      let list = '';
      data.missing_classifications.forEach((v, i) => {
        list += v + ', ';
      });
      list = list.slice(0, -2);
      m = 'You are missing required classifications: ' + list;
    } else if (data.unclassified_photos.length > 0) {
      m = "You haven't classified every photo";
    } else {
      m = data.error;
    }
    yield put(Actions.downloadSessionFailure(m));
    yield delay(3000);
    yield put(Actions.downloadSessionFailure(''));
  } else {
    message.success('Download job is started. Once it is done, it will be notified the status.');
  }
}

export function* completeSession(action: ActionWithPayload<ActionTypes.SET_SESSION_EXPORT, Session>) {
  let response = yield call(
    httpPatch,
    SESSIONS_URL + action.payload.uuid,
    {
      set_complete: true,
    },
    true,
    true,
  );

  if (response.status === 500) {
    // server error, display as such
    yield put(Actions.completeSessionFailure('There was a server error submitting your request.'));
    return;
  }

  message.success('Successfully mark as complete.');
}

export function* getSessions(action) {
  let url = '';
  if (action.payload.isIncludeCompleted) {
    url = `${SESSIONS_URL}?complete=both`;
  } else {
    url = SESSIONS_URL;
  }
  if (action.payload.page) {
    url = `${url}?page=${action.payload.page}`
  }

  try {
    const response = yield call(httpGet, url);
    yield put(Actions.getSessionsSuccess(response));
  } catch (e) {
    yield put(Actions.getSessionsFail());
  }
}

export function* createSession(action: ActionWithPayload<ActionTypes.CREATE_SESSION, NewSessionRequest>) {
  try {
    const { vin, photos } = action.payload;
    const data = new FormData();
    data.append('vin', vin);

    for (let i = 0; i < photos.length; i++) {
      data.append('files', photos[i].originFileObj, photos[i].originFileObj.name);
    }

    if (action.payload.spinviewer_main_photos.length > 0) {
      for (let i = 0; i < action.payload.spinviewer_main_photos.length; i++) {
        data.append(
          'spinviewer_main_files',
          action.payload.spinviewer_main_photos[i].originFileObj,
          action.payload.spinviewer_main_photos[i].originFileObj.name,
        );
      }
    }

    if (action.payload.spinviewer_alt_photos.length > 0) {
      for (let i = 0; i < action.payload.spinviewer_alt_photos.length; i++) {
        data.append(
          'spinviewer_alt_files',
          action.payload.spinviewer_alt_photos[i].originFileObj,
          action.payload.spinviewer_alt_photos[i].originFileObj.name,
        );
      }
    }

    if (!!action.payload.panoviewer_photo) {
      data.append(
        'panoviewer_file',
        action.payload.panoviewer_photo.originFileObj,
        action.payload.panoviewer_photo.name,
      );
    }

    const response = yield call(multipartFormSubmit, `${SESSIONS_URL}`, data);
    yield put(Actions.createSessionSuccess(response));
    message.success('Successfully created session');
  } catch (e) {
    console.log(e);
    message.error('Error creating session');
    yield put(Actions.createSessionFailure());
  }
}

export function* getSession(action: ActionWithPayload<ActionTypes.GET_SESSION, GetSession>) {
  try {
    const getSessionUrl = `${SESSIONS_URL}${action.payload.sessionId}`;
    const response = yield call(httpGet, getSessionUrl);
    yield put(Actions.getSessionSuccess(response));

    if (action.payload.isDownload) {
      downloadPhotosAsZip(response, action.payload.downloadOptions);
    }

    yield put(
      Actions.setCurrentPhoto({
        currentSession: response,
        direction: NavigatePhotoDirection.FIRST_UNCLASSIFIED,
        currentIndex: -1,
        photoId: action.payload.photoId ? action.payload.photoId : undefined,
      }),
    );
  } catch (e) {
    yield put(Actions.getSessionFail());
  }
}

export function* setSpinviewerPhotoSort(
  action: ActionWithPayload<ActionTypes.SET_SPINVIEWER_PHOTO_SORT, SetSpinviewerPhotoSortPayload>,
) {
  let response = yield call(httpPatch, SET_SPINVIEWER_PHOTO_SORT_URL + action.payload.session.uuid, {
    photos: action.payload.photos.map(photo => ({ id: photo.id, order: photo.order })),
    type: action.payload.type,
  });

  if (response.status === 500) {
    // server error, display as such
    yield put(Actions.completeSessionFailure('There was a server error submitting your request.'));
    return;
  }

  notification.close('spinviewer-photo-order');
  setTimeout(() => message.success('Successfully saved the spinviewer photo.'), 500);

  yield put(
    Actions.getSession({
      sessionId: action.payload.session.uuid,
    }),
  );

  if (action.payload.type === 'spinviewer-main') {
    yield put(AnnotationActions.getSpinAnnotations(action.payload.session.uuid));
  }
}

export function* setPanoviewerCenter(
  action: ActionWithPayload<ActionTypes.SET_PANOVIEWER_CENTER, SetPanoviewerCenterPayload>,
) {
  let response = yield call(httpPatch, SET_PANOVIEWER_CENTER_URL + action.payload.session.uuid, {
    panoviewer_yaw: Math.floor(action.payload.panoviewer_yaw),
    panoviewer_pitch: Math.floor(action.payload.panoviewer_pitch),
  });

  if (response.status === 500) {
    // server error, display as such
    yield put(Actions.completeSessionFailure('There was a server error submitting your request.'));
    return;
  }

  notification.close('panoviewer-center');
  setTimeout(() => message.success('Successfully saved the panoviewer center info.'), 500);
  yield put(
    Actions.getSession({
      sessionId: action.payload.session.uuid,
    }),
  );
}

export function* setCurrentPhoto(action: ActionWithPayload<ActionTypes.SET_CURRENT_PHOTO, ChangePhotoPayload>) {
  const { currentSession, currentIndex, direction, photoId } = action.payload;
  if (direction === NavigatePhotoDirection.FIRST_UNCLASSIFIED) {
    console.log('Looking for photoId', photoId, 'in all photos', currentSession.photos);
    let photoToShow;

    if (photoId) {
      photoToShow = findIndex(currentSession.photos, (photo: Photo) => photo.id.toString() === photoId.toString());
    } else {
      photoToShow = findIndex(currentSession.photos, (photo: Photo) => isEmpty(photo.classification));
    }

    photoToShow = Math.max(0, photoToShow);
    if (photoToShow >= 0) {
      yield put(
        Actions.setCurrentPhotoSuccess({
          currentIndex: photoToShow,
          beforePhotos: getBeforePhotos(photoToShow, currentSession.photos),
          afterPhotos: getAfterPhotos(photoToShow, currentSession.photos),
        }),
      );
    }
  } else if (direction === NavigatePhotoDirection.FORWARD) {
    if (currentIndex === currentSession.photos.length - 1) {
      // if we are already at the last photo and we're moving forward
      history.push(FINALIZE.replace(':sessionId', currentSession.uuid));
    }
    // Dont get an index > than length of photo array
    const nextIndex = Math.min(currentSession.photos.length - 1, currentIndex + 1);
    yield put(
      Actions.setCurrentPhotoSuccess({
        currentIndex: nextIndex,
        beforePhotos: getBeforePhotos(nextIndex, currentSession.photos),
        afterPhotos: getAfterPhotos(nextIndex, currentSession.photos),
      }),
    );
  } else {
    const nextIndex = currentIndex - 1;
    if (indexInBounds(nextIndex, currentSession.photos)) {
      yield put(
        Actions.setCurrentPhotoSuccess({
          currentIndex: nextIndex,
          beforePhotos: getBeforePhotos(nextIndex, currentSession.photos),
          afterPhotos: getAfterPhotos(nextIndex, currentSession.photos),
        }),
      );
    }
  }
}

function indexInBounds(index: number, photos: Photo[]) {
  return index >= 0 && index < photos.length;
}

function getBeforePhotos(index: number, photos: Photo[]): BeforePhotos {
  if (index >= 2) {
    return photos.slice(index - 2, index);
  }

  return index === 1 ? [null, photos[0]] : [null, null];
}

function getAfterPhotos(index: number, photos: Photo[]): AfterPhotos {
  if (index < photos.length - 3 || index === 0) {
    return photos.slice(index + 1, index + 3);
  }

  return index === photos.length - 2 ? [photos[photos.length - 1], null] : [null, null];
}

export function* searchSessions(action: ActionWithPayload<ActionTypes.SEARCH_SESSIONS, string>) {
  const query = action.payload;
  const complete = false;
  let url = `${SESSIONS_URL}?${complete ? 'complete=both' : ''}&vin=${query}`;
  try {
    const response = yield call(httpGet, url);
    console.log(response);
    yield put(Actions.searchSessionsResults(response));
  } catch (e) {
    yield put(Actions.searchSessionsResults([]));
  }
}

export function* deleteSession(action: ActionWithPayload<ActionTypes.DELETE_SESSION, string>) {
  try {
    yield call(httpDelete, `${SESSIONS_URL}${action.payload}`);
    yield put(Actions.deleteSessionSuccess(action.payload));
    message.success('Successfully deleted session');
  } catch (e) {
    message.error('Error deleting session. Please try again.');
  }
}

export function* uploadPhoto(action: ActionWithPayload<ActionTypes.PHOTO_UPLOAD, PhotoUploadRequest>) {
  try {
    const { session_uuid, photo } = action.payload;
    const data = new FormData();
    data.append('session_uuid', session_uuid);
    data.append('photo', photo[0].originFileObj, photo[0].originFileObj.name);
    yield call(multipartFormSubmit, `${PHOTO_URL}`, data);
    yield put(Actions.photoUploadSuccess());
    message.success('Successfully uploaded photo');
  } catch (e) {
    console.log(e);
    message.error('Error Uploading Photo');
    yield put(Actions.photoUploadFailure());
  }
}

export function* uploadCanvasPhoto(action: ActionWithPayload<ActionTypes.PHOTO_UPLOAD, PhotoCanvasUploadRequest>) {
  try {
    const { file, photo } = action.payload;
    const data = new FormData();
    const randomFileName = new Date().getTime().toString() + '.png';
    data.append('photo', file, randomFileName);
    yield call(multipartFormPatchSubmit, `${PHOTO_URL}/${photo.id}`, data);
    yield put(Actions.photoUploadCanvasSuccess());
    notification.close('uploading');
    message.success('Successfully uploaded photo.');
    yield put(Actions.getSession({ sessionId: photo.session_uuid, photoId: photo.id }));
  } catch (e) {
    console.log(e);
    message.error('Error Uploading Photo');
    yield put(Actions.photoUploadCanvasFailure());
  }
}

export function* deletePhoto(action: ActionWithPayload<ActionTypes.DELETE_PHOTO, DeletePhotoPayload>) {
  const { id, sessionId } = action.payload;
  try {
    yield call(httpDelete, `${PHOTO_URL}/${id}`);
    message.success('Successfully Deleted Photo');
  } catch (e) {
    console.log(e);
    message.error('Error Deleting Photo');
  }
  yield put(
    Actions.getSession({
      sessionId: sessionId,
    }),
  );
}

export function* sessionSaga() {
  yield takeLatest(ActionTypes.GET_SESSIONS, getSessions);
  yield takeLatest(ActionTypes.GET_SESSION, getSession);
  yield takeLatest(ActionTypes.SET_CURRENT_PHOTO, setCurrentPhoto);
  yield takeLatest(ActionTypes.SET_SESSION_EXPORT, exportSession);
  yield takeLatest(ActionTypes.SET_SESSION_DOWNLOAD, downloadSession);
  yield takeLatest(ActionTypes.COMPLETE_SESSION, completeSession);
  yield takeLatest(ActionTypes.SEARCH_SESSIONS, searchSessions);
  yield takeEvery(ActionTypes.CREATE_SESSION, createSession);
  yield takeLatest(ActionTypes.CREATE_SESSION_SUCCESS, getSessions);
  yield takeLatest(ActionTypes.DELETE_SESSION, deleteSession);
  yield takeLatest(ActionTypes.PHOTO_UPLOAD, uploadPhoto);
  yield takeLatest(ActionTypes.PHOTO_CANVAS_UPLOAD, uploadCanvasPhoto);
  yield takeLatest(ActionTypes.SET_SPINVIEWER_PHOTO_SORT, setSpinviewerPhotoSort);
  yield takeLatest(ActionTypes.SET_PANOVIEWER_CENTER, setPanoviewerCenter);
  yield takeLatest(ActionTypes.DELETE_PHOTO, deletePhoto);
}
