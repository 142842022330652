import { call, takeLatest, put } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/BrandingReducer";
import { Actions as EditorActions, SidebarMode } from "../reducers/EditorReducer";
import { httpGet, httpPost, httpPut, multipartFormSubmit } from "../client/http";
import { ActionWithPayload } from "../reducers/actionHelper";
import { NewBrandingRequest, UpdateBrandingRequest } from "../model/Branding";
import { message, notification } from "antd";

const brandingEndpoint = 'api/branding';

export function* getBrandings() {
    try {
        const response = yield call(httpGet, brandingEndpoint);
        yield put(Actions.getBrandingsSuccess(response));
    } catch (e) {
        yield put(Actions.getBrandingsFailure());
    }
}

export function* createBranding(action: ActionWithPayload<ActionTypes.CREATE_BRANDING, NewBrandingRequest>) {
    try {
        let { name, thumbnail } = action.payload;
        const data = new FormData();
        data.append('name', name);

        if (thumbnail && thumbnail.originFileObj) {
            data.append('thumbnail', thumbnail.originFileObj, thumbnail.originFileObj.name);
        }

        yield call(multipartFormSubmit, brandingEndpoint, data);
        yield put(EditorActions.setSidebarMode(SidebarMode.ITEM_LIST));
        yield put(Actions.createBrandingSuccess());
        notification.close(name);
        message.success('Successfully created brand');
    } catch (e) {
        console.log('Error creating branding', e);
        yield put(Actions.createBrandingFailure());
    }
}

export function* updateBrandings(action: ActionWithPayload<ActionTypes.UPDATE_BRANDINGS, UpdateBrandingRequest>) {
    try {
        const brandings = action.payload.brandings;

        yield call(httpPost, brandingEndpoint + '/update', {
            brandings
        });
        yield put(EditorActions.setActiveBranding(null));
        yield put(Actions.getBrandings());
    } catch (e) {
        console.log('Error update brandings', e);
        yield put(Actions.createBrandingFailure());
    }
}

export function* brandingSaga() {
    yield takeLatest(ActionTypes.GET_BRANDINGS, getBrandings);
    yield takeLatest(ActionTypes.CREATE_BRANDING, createBranding);
    yield takeLatest(ActionTypes.CREATE_BRANDING_SUCCESS, getBrandings);
    yield takeLatest(ActionTypes.UPDATE_BRANDINGS, updateBrandings);
}