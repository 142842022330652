import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { SearchVins } from './SearchVins';
import '@/styles/dashboard.css';
import { Actions } from '../../reducers/SessionReducer';
import { NewSessionRequest, Session, Sessions } from '../../model/Session';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SessionForm from './SessionForm';
import { Page } from '../layout/Page';
import { Card } from '../layout/Card';
import Button from 'antd/lib/button/button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'antd';
import { User } from '../../model/User';

interface Props {
  isAuthenticated: boolean;
  getSessions: (isIncludeCompleted?: boolean | null, page?: number) => void;
  sessions: Sessions;
  filteredSessions: Sessions;
  searchSessions: (query: string) => void;
  sessionCount: number;
  completeSessionCount: number;
  isLoading: boolean;
  createSession: (sessionRequest: NewSessionRequest) => void;
  newSessionError: boolean;
  newSessionLoading: boolean;
  deleteSession: (id: string) => void;
  openModal: () => void;
  sessionsLoading: string[];
  user: User;
  sessionsPaginationPage: number;
  sessionsPaginationPageSize: number;
  totalCount: number;
}

interface State {
  isModalOpen: boolean;
  keys: string[];
}

class DashboardPage extends React.Component<Props, State> {
  readonly state: State = {
    isModalOpen: false,
    keys: [],
  };

  openModal = () => {
    this.props.openModal();
  };

  componentDidMount() {
    this.props.getSessions(false);
  }

  getSessionWithPage(page) {
    this.props.getSessions(null, page);
  }

  renderCreateSessionModal() {
    return <SessionForm />;
  }

  renderNewSessionCard() {
    return (
      <Card key='new-session' className='vin-card new-session-card' onClick={this.openModal}>
        <FontAwesomeIcon icon={faPlus} size='4x' />
        <h4 className='card-title'>New Session</h4>
      </Card>
    );
  }

  onDeleteSessionClicked = (index: number | null) => {
    const modal = Modal.confirm({
      title: 'Delete session',
      onOk: () => {
        this.props.deleteSession(this.props.sessions[index].uuid);
        modal.destroy();
      },
      okText: 'Yes',
      cancelText: 'No',

      centered: true,
      content: <p>Are you sure you want to delete session {this.props.sessions[index].vehicle.vin}?</p>,
    });
  };

  renderCards() {
    return [
      this.renderNewSessionCard(),
      ...this.props.filteredSessions.map((session: Session, index: number) => {
        let photo = null;

        if (this.props.user.active_site) {
          if (this.props.user.active_site.use_spinviewer_main && session.spinviewer_nav) {
            photo = session.spinviewer_nav;
          }

          if (!photo && this.props.user.active_site.use_panoviewer && session.panoviewer_nav) {
            photo = session.panoviewer_nav;
          }

          if (!photo && this.props.user.active_site.use_photos) {
            photo = session.photos.length > 0 ? session.photos[0].photo : null;
          }
        }

        return (
          <div className='dashboard-card-wrapper' key={session.uuid}>
            <Button type='danger' onClick={() => this.onDeleteSessionClicked(index)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Card key={session.uuid} className={`vin-card ${session.vehicle.vin}`}>
              <Link to={`/session/${session.uuid}`}>
                <div className='card-photo-section'>{photo && <img src={photo} alt='Dashboard thumbnail' />}</div>
                <div className='body-section'>
                  <h4 className='card-title'>{session.vehicle.vin}</h4>
                  <h5 className='muted-text'>
                    {session.vehicle.make
                      ? `${session.vehicle.make} ${session.vehicle.model}
                                        ${session.vehicle.year}`
                      : ''}
                  </h5>
                  <h5 className='muted-text'>Photos: {session.photo_count}</h5>
                </div>
              </Link>
            </Card>
          </div>
        );
      }),
    ];
  }

  render() {
    return (
      <Page isFullWidth={true}>
        {this.renderCreateSessionModal()}
        <SearchVins
          searchSessions={this.props.searchSessions}
          sessionCount={this.props.sessionCount}
          sessionCompleteCount={this.props.completeSessionCount}
          paginationCurrent={this.props.sessionsPaginationPage}
          paginationPageSize={this.props.sessionsPaginationPageSize}
          paginationTotal={this.props.totalCount}
          paginationOnChange={(page: number) => this.getSessionWithPage(page)}
        />
        <div className='container'>
          <div className='vehicle-results'>{this.renderCards()}</div>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = ({ auth, sessions, user }: ApplicationState) => ({
  isAuthenticated: auth.isAuthenticated,
  sessions: sessions.sessions,
  filteredSessions: sessions.filteredSessions,
  sessionCount: sessions.sessionCount,
  completeSessionCount: sessions.completeSessionCount,
  isLoading: sessions.isLoading,
  newSessionError: sessions.newSessionError,
  newSessionLoading: sessions.newSessionLoading,
  sessionsLoading: [],
  user: user,
  sessionsPaginationPage: sessions.sessionsPaginationPage,
  sessionsPaginationPageSize: sessions.sessionsPaginationPageSize,
  totalCount: sessions.totalCount,
});

const mapDispatchToProps = {
  getSessions: Actions.getSessions,
  createSession: Actions.createSession,
  deleteSession: Actions.deleteSession,
  searchSessions: Actions.searchSessions,
  openModal: Actions.openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
