import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { RouteComponentProps } from 'react-router';
import { GetSession, Photo, Session, spinviewerPhotos } from '../../model/Session';
import { Classifications } from '../../model/Classification';
import { Actions } from '../../reducers/SessionReducer';
import { Actions as ClassificationActions } from '../../reducers/ClassificationReducer';
import { Alert, Col, message, Row } from 'antd';
import { Link } from 'react-router-dom';
import '@/styles/finalize.scss';
import '@/styles/check.scss';
import { Button, Icon } from 'antd';
import { Page } from '../layout/Page';
import { Card } from '../layout/Card';
import { ReactSortable, Sortable, MultiDrag, Swap } from 'react-sortablejs';
import { Actions as AdminActions } from '../../reducers/AdminReducer';
import { BrandingTasks } from '../../model/BrandingTask';
import { PhotoItem } from './PhotoItem';
import { CachedBrandingImage } from '../utils/CachedBrandingImage';
import { User } from '../../model/User';
import { BASE_URL } from '../../client/http';

interface MatchParams {
  sessionId: string;
}

Sortable.mount(new MultiDrag(), new Swap());

interface Props extends RouteComponentProps<MatchParams> {
  sessionId: string;
  currentSession: Session;
  currentPhoto: Photo;
  classifications: Classifications;
  brandingTasks: BrandingTasks;
  sessionLoading: boolean;
  classificationLoading: boolean;
  getSession: (searchSession: GetSession) => void;
  getBrandingTasks: () => void;
  getClassifications: () => void;
  exportSession: (session: Session) => void;
  downloadSession: (session: Session) => void;
  errorMessage: string;
  user: User;
}

class FinalizeSession extends React.Component<Props, {}> {
  public state = {
    photos: [],
    isBrandingTaskImagesLoaded: false,
  };

  displayingError = false;

  componentWillMount() {
    window.addEventListener('message', this.handleIframeMessageHandler.bind(this), false);
  }

  handleIframeMessageHandler(e) {
    const data = e.data;
    if (data.type === 'ready') {
      this.sendMessage({ type: 'ready' });
    } else if (data.type == 'resize') {
      let iframe = document.getElementById('spinview-frame') as HTMLIFrameElement;

      if (iframe) {
        iframe.style['height'] = data.data.height + 'px';
        iframe.style['min-height'] = data.data.height + 'px';
        iframe.height = data.data.height + 'px';
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessageHandler, false);
  }

  sendMessage(message) {
    let iframe = document.getElementById('spinview-frame') as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(message)), '*');
    }
  }

  componentDidMount() {
    const { sessionId } = this.props.match.params;
    this.props.getBrandingTasks();
    this.props.getSession({ sessionId });
    this.props.getClassifications();

    if (this.props.brandingTasks.length > 0) {
      this.loadingBrandingImages(this.props.brandingTasks);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    let { photos } = this.state;
    if (nextProps.currentSession && nextProps.currentSession.photos !== photos) {
      photos = [...nextProps.currentSession.photos];
      this.setState({ ...this.state, photos: this.sortPhotos(photos) });
    }

    if (this.props.brandingTasks.length !== nextProps.brandingTasks.length) {
      this.loadingBrandingImages(nextProps.brandingTasks);
    }
  }

  async loadingBrandingImages(brandingTasks) {
    this.setState({ isBrandingTaskImagesLoaded: false });

    for (let brandingTask of brandingTasks) {
      await CachedBrandingImage.updateImage(brandingTask.branding);
    }

    this.setState({ isBrandingTaskImagesLoaded: true });
  }

  brandingsForPhotoItem(photo: Photo, order: number) {
    const brandings = [];

    if (this.state.isBrandingTaskImagesLoaded) {
      for (const brandingTask of this.props.brandingTasks) {
        if (brandingTask.trigger === 'all') {
          brandings.push(brandingTask.branding);
        } else if (
          brandingTask.trigger === 'classification' &&
          photo.classification &&
          photo.classification.id === brandingTask.classification.id
        ) {
          brandings.push(brandingTask.branding);
        } else if (brandingTask.trigger === 'order' && order === brandingTask.order) {
          brandings.push(brandingTask.branding);
        }
      }
    }

    return brandings;
  }

  exportSession = () => {
    let session = {
      ...this.props.currentSession,
      photos: this.state.photos,
    } as Session;
    this.props.exportSession(session);
    console.log('Starting session check...');
  };

  downloadSession = () => {
    let session = {
      ...this.props.currentSession,
      photos: this.state.photos,
      downloadOptions: ['photos', 'spinviewer', 'panoviewer'],
    } as Session;
    this.props.downloadSession(session);
    console.log('Starting session check...');
  };

  renderFinalizeButton() {
    return this.props.currentSession && this.props.currentSession.export ? (
      <Button
        type='primary'
        size={'large'}
        onClick={this.exportSession}
        className={'btn-responsive float-right-md'}
        disabled
      >
        <Icon type='export' />
        Finalize & Export
      </Button>
    ) : (
      <Button type='primary' size={'large'} className={'btn-responsive float-right-md'} onClick={this.exportSession}>
        <Icon type='export' />
        Finalize & Export
      </Button>
    );
  }

  renderDownloadButton() {
    return (
      <Button
        type='primary'
        size={'large'}
        onClick={this.downloadSession}
        className={'btn-responsive float-right-md'}
        style={{ marginRight: '1vw' }}
      >
        <Icon type='export' />
        Download
      </Button>
    );
  }

  sortPhotos(photos: Photo[]) {
    photos.sort((photo1, photo2) => {
      if (photo1.classification?.order > photo2.classification?.order) {
        return 1;
      } else if (photo1.classification?.order === photo2.classification?.order) {
        let photo1Order = photo1.order ? photo1.order : -1;
        let photo2Order = photo2.order ? photo2.order : -1;
        return photo1Order - photo2Order;
      } else {
        return -1;
      }
    });
    return photos;
  }

  render() {
    let loading_class = 'circle-loader';

    if (this.props.currentSession && this.props.currentSession.export) {
      loading_class = 'circle-loader load-complete';
    }

    let export_message = '';

    this.props.currentSession && this.props.currentSession.export
      ? (export_message = 'This vehicle has been exported')
      : (export_message = "This vehicle hasn't been exported");

    const icon = (
      <div className={loading_class + ' hide-md'} onClick={this.exportSession}>
        {this.props.currentSession && this.props.currentSession.export ? <div className='checkmark draw'></div> : null}
      </div>
    );
    if (this.props.errorMessage && this.props.errorMessage.length > 0 && !this.displayingError) {
      message.error(this.props.errorMessage);
      this.displayingError = true;
      setTimeout(() => {
        this.displayingError = false;
      }, 3000);
    }

    return (
      <Page isFullWidth={false}>
        <Row type='flex' style={{ paddingTop: '2em' }}>
          <Col lg={8} md={24} sm={24} xs={24}>
            {this.props.currentSession && (
              <Link to={'/session/' + this.props.currentSession.uuid + '/'}>
                <Button type='primary' className={'btn-responsive'} size={'large'}>
                  <Icon type='edit' />
                  Edit classifications for this car
                </Button>
              </Link>
            )}
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} style={{ textAlign: 'center' }}>
            <Alert
              message={this.props.currentSession && this.props.currentSession.vehicle.vin}
              description={export_message}
              type={this.props.currentSession && this.props.currentSession.export ? 'success' : 'warning'}
              showIcon
              icon={icon}
              style={{ padding: '2em' }}
            />
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className={'float-right-md'}>
            {this.renderFinalizeButton()}
            {this.renderDownloadButton()}
          </Col>
        </Row>
        <Row type='flex'>
          <h1 className='view-label'>Photos</h1>
          <div id={'finalize'}>
            {this.props.currentSession && (
              <ReactSortable list={this.state.photos} setList={photos => this.setState({ ...this.state, photos })}>
                {this.state.photos.map((photo: Photo, index) => {
                  return (
                    <Col
                      key={photo.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '30px',
                      }}
                    >
                      <Card key={photo.id} className={`vin-card`}>
                        <Link to={`/session/` + this.props.currentSession.uuid + '?view=photos&photoId=' + photo.id}>
                          <div className='photo-section'>
                            <PhotoItem
                              photo={photo}
                              brandings={this.brandingsForPhotoItem(photo, index + 1)}
                            ></PhotoItem>
                          </div>
                        </Link>
                        <div className='body-section' style={{ overflow: 'hidden' }}>
                          <h2>{photo.classification ? photo.classification.name : 'Unclassified'}</h2>
                        </div>
                      </Card>
                    </Col>
                  );
                })}
              </ReactSortable>
            )}
          </div>
          {this.props.user &&
            this.props.user.active_site &&
            this.props.user.active_site.use_spinviewer_main &&
            spinviewerPhotos(this.props.currentSession, 'main').length > 0 && (
              <div className='preview-container'>
                <h1 className='view-label'>Exterior</h1>
                <div className='panoview-preview'>
                  <iframe
                    src={`${BASE_URL}/view_session/${this.props.user.active_site.key}/${this.props.currentSession.vehicle.vin}/spinviewer-main/hide`}
                    width='100%'
                    height='100%'
                    id='spinview-frame'
                  ></iframe>
                </div>
              </div>
            )}
          {this.props.user &&
            this.props.user.active_site &&
            this.props.user.active_site.use_panoviewer &&
            this.props.currentSession &&
            this.props.currentSession.spinviewer_nav && (
              <div className='preview-container'>
                <h1 className='view-label'>Interior</h1>
                <div className='spinview-preview'>
                  <iframe
                    src={`${BASE_URL}/view_session/${this.props.user.active_site.key}/${this.props.currentSession.vehicle.vin}/panoviewer/hide`}
                    width='100%'
                    height='100%'
                    id='view-frame'
                  ></iframe>
                </div>
              </div>
            )}
        </Row>
      </Page>
    );
  }
}

const mapStateToProps = ({ sessions, classifications, admin, user }: ApplicationState) => ({
  currentSession: sessions.currentSession,
  currentPhoto: sessions.currentPhoto,
  classifications: classifications.classifications,
  sessionLoading: sessions.isLoading,
  classificationLoading: classifications.isLoading,
  errorMessage: sessions.exportErrorMessage || sessions.downloadErrorMessage,
  brandingTasks: admin.brandingTasks,
  user: user,
});

const mapDispatchToProps = {
  getSession: Actions.getSession,
  exportSession: Actions.setSessionExport,
  downloadSession: Actions.setSessionDownload,
  getBrandingTasks: AdminActions.getBrandingTasks,
  getClassifications: ClassificationActions.getClassifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeSession as any);
