import React from 'react';
import { Form, Input, Upload, Icon, message, Modal, notification, Spin } from 'antd';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/SessionReducer';
import { NewSessionRequest } from '../../model/Session';
import { User } from '../../model/User';
import swal from 'sweetalert';

//Creating the upload section from Antd
const { Dragger } = Upload;

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

interface ConnectedSessionFormProps {
  user: User;
  form: any;
  createSession: (sessionRequest: NewSessionRequest) => void;
  closeModal: () => void;
  newSessionModalOpen: boolean;
}

export interface SessionFormState {
  fileList: File[];
  mainSpinviewerFileList: File[];
  altSpinviewerFileList: File[];
  panoviewerFile: any | null;
}

export const uploadingBox = (title, body, key) => {
  const args = {
    message: title,
    description: (
      <div>
        <Spin size='large' style={{ paddingRight: '2em' }} />
        {body}
      </div>
    ),
    duration: 0,
    key: key,
  };

  notification.open(args);
};

class SessionForm extends React.Component<ConnectedSessionFormProps, SessionFormState> {
  readonly state = {
    fileList: [],
    mainSpinviewerFileList: [],
    altSpinviewerFileList: [],
    panoviewerFile: null,
  };
  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      const allValues = {
        ...values,
        photos: this.state.fileList,
        spinviewer_main_photos: this.state.mainSpinviewerFileList,
        spinviewer_alt_photos: this.state.altSpinviewerFileList,
        panoviewer_photo: this.state.panoviewerFile,
      };

      const site = this.props.user.active_site;

      if (!err) {
        if (site.use_photos && allValues['photos'].length == 0) {
          swal('Oops!', 'Please upload more than one photo images!', 'error');
          return;
        }

        if (site.use_spinviewer_main && allValues['spinviewer_main_photos'].length == 0) {
          swal('Oops!', 'Please upload more than one main spinviewer images!', 'error');
          return;
        }

        if (site.use_spinviewer_alt && allValues['spinviewer_alt_photos'].length == 0) {
          swal('Oops!', 'Please upload more than one alt spinviewer images!', 'error');
          return;
        }

        if (site.use_panoviewer && !allValues['panoviewer_photo']) {
          swal('Oops!', 'Please upload panoviewer image!', 'error');
          return;
        }

        uploadingBox('Started upload of ' + allValues.vin, 'Uploading, please wait....', allValues.vin);

        this.props.createSession(allValues);
        console.log('No error from form submit', allValues);
        this.props.closeModal();
        this.props.form.resetFields();
        this.setState({
          mainSpinviewerFileList: [],
          altSpinviewerFileList: [],
          panoviewerFile: null,
          fileList: [],
        });
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleModalCancel = () => {
    this.props.closeModal();
  };

  onChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        fileList: info.fileList,
      });
    }
  };

  onMainSpiniviewerChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        mainSpinviewerFileList: info.fileList,
      });
    }
  };

  onAltSpiniviewerChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        altSpinviewerFileList: info.fileList,
      });
    }
  };

  onPanoviewerChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        panoviewerFile: info.file,
      });
    }
  };

  render() {
    const draggerProps = {
      multiple: true,
      name: 'bulkUpload',
      onChange: this.onChange,
      customRequest: dummyRequest,
    };

    const draggerMainSpinviewerProps = {
      multiple: true,
      name: 'draggerMainSpinviewer',
      onChange: this.onMainSpiniviewerChange,
      customRequest: dummyRequest,
    };

    const draggerAltSpinviewerProps = {
      multiple: true,
      name: 'draggerAlSpinviewer',
      onChange: this.onAltSpiniviewerChange,
      customRequest: dummyRequest,
    };

    const draggerPanoviewerProps = {
      multiple: false,
      name: 'draggerPanoviewer',
      onChange: this.onPanoviewerChange,
      customRequest: dummyRequest,
    };

    const site = this.props.user.active_site;

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal
          destroyOnClose={true}
          title='Create a new session'
          visible={this.props.newSessionModalOpen}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <Form>
            <Form.Item label='Vin'>
              {getFieldDecorator('vin', {
                rules: [
                  {
                    required: true,
                    message: 'Please add 17 character vin',
                    len: [12, 17],
                  },
                ],
              })(<Input placeholder='17 Character Vin' />)}
            </Form.Item>
            {site && site.use_photos && (
              <Form.Item label='Upload Photo Files'>
                <Dragger {...draggerProps}>
                  <p className='ant-upload-drag-icon'>
                    <Icon type='inbox' />
                  </p>
                  <p className='ant-upload-text'>Click or drag photo files to this area to upload</p>
                </Dragger>
              </Form.Item>
            )}
            {site && site.use_spinviewer_main && (
              <Form.Item label='Upload Main Exterior Files:'>
                <Dragger {...draggerMainSpinviewerProps}>
                  <p className='ant-upload-drag-icon'>
                    <Icon type='inbox' />
                  </p>
                  <p className='ant-upload-text'>Click or drag exterior files to this area to upload</p>
                </Dragger>
              </Form.Item>
            )}
            {site && site.use_spinviewer_alt && (
              <Form.Item label='Upload Alt Exterior Files:'>
                <Dragger {...draggerAltSpinviewerProps}>
                  <p className='ant-upload-drag-icon'>
                    <Icon type='inbox' />
                  </p>
                  <p className='ant-upload-text'>Click or drag exterior files to this area to upload</p>
                </Dragger>
              </Form.Item>
            )}
            {site && site.use_panoviewer && (
              <Form.Item label='Upload Interior File:'>
                <Dragger {...draggerPanoviewerProps}>
                  <p className='ant-upload-drag-icon'>
                    <Icon type='inbox' />
                  </p>
                  <p className='ant-upload-text'>Click or drag interior file to this area to upload</p>
                </Dragger>
              </Form.Item>
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

export const form = Form.create({ name: 'SessionForm' })(SessionForm);

const mapStateToProps = ({ sessions, user }: ApplicationState) => ({
  user: user,
  newSessionModalOpen: sessions.newSessionModalOpen,
});

const mapDispatchToProps = {
  createSession: Actions.createSession,
  closeModal: Actions.closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
