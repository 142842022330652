import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { Actions, SidebarMode } from '../../../reducers/EditorReducer';
import { Button, message } from 'antd';
import { Branding } from '../../../model/Branding';

interface Props {
  sidebarMode: SidebarMode;
  activeBranding: Branding;
  activeCanvasBranding: Branding;
  canvas: any;
  setSidebarMode: (mode: SidebarMode) => void;
}

class BrandingFooter extends React.PureComponent<Props, {}> {
  placeBranding() {
    if (!this.props.activeBranding) {
      message.error(`Please select branding image in branding list.`);
      return;
    }

    if (this.props.canvas && this.props.canvas.placeBranding) {
      this.props.canvas.placeBranding(this.props.activeBranding);
    }
  }

  removeBranding() {
    if (!this.props.activeCanvasBranding) {
      message.error(`Please select branding image in branding list.`);
      return;
    }

    if (this.props.canvas && this.props.canvas.removeBranding) {
      this.props.canvas.removeBranding(this.props.activeCanvasBranding);
    }
  }

  async uploadCanvas() {
    if (this.props.canvas && this.props.canvas.uploadCanvas) {
      await this.props.canvas.updateBrandings();
      this.props.canvas.uploadCanvas();
    }
  }

  render() {
    return (
      <div className='branding-footer'>
        <Button type='primary' size='large' style={{ float: 'right' }} onClick={() => this.uploadCanvas()}>
          Save
        </Button>
        {this.props.sidebarMode === SidebarMode.ITEM_LIST && (
          <Button type='ghost' size='large' style={{ float: 'right' }} onClick={() => this.placeBranding()}>
            Place Branding
          </Button>
        )}
        {this.props.activeCanvasBranding && (
          <Button type='danger' size='large' style={{ float: 'right' }} onClick={() => this.removeBranding()}>
            Remove Branding
          </Button>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setSidebarMode: Actions.setSidebarMode,
};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  activeBranding: editor.activeBranding,
  activeCanvasBranding: editor.activeCanvasBranding,
  sidebarMode: editor.sidebarMode,
  canvas: editor.canvas,
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandingFooter as any);
