import * as React from 'react';
import { connect } from 'react-redux';

interface Props {
  metricTitle: String;
  metricValue: String | Number;
  iconBackground: String;
  metricIcon: String;
}

class MetricCard extends React.PureComponent<Props, {}> {
  state = {};

  render() {
    const style = {
      backgroundColor: `${this.props.iconBackground}`,
      backgroundImage: `url(${this.props.metricIcon})`,
    };

    return (
      <div className='metric-card-body'>
        <div className='metric-icon-container' style={style}></div>
        <div className='metric-info-container'>
          <h3 className='metric-title'>{this.props.metricTitle}</h3>
          <h3 className='metric-value'>{this.props.metricValue}</h3>
        </div>
        <div className='time-selector'>
          <h2>this month</h2>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MetricCard);
