import { message } from 'antd';
import * as React from 'react';
import { httpGet } from '../../client/http';
import { Actions as UserActions } from '../../reducers/UserReducer';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { User } from '../../model/User';
import { GetSession } from '../../model/Session';
import { Actions } from '../../reducers/SessionReducer';

interface Props {
  user: User;
  isAuthenticated: boolean;
  getSession: (searchSession: GetSession) => void;
}

const FINALIZATION_JOB_URL = 'api/finalization_job/';

class JobNotification extends React.Component<Props, {}> {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.startNotificationJob();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated != nextProps.isAuthenticated && nextProps.isAuthenticated) {
      this.startNotificationJob();
    }
  }

  async startNotificationJob() {
    this.getJobStatus();
    setTimeout(() => this.startNotificationJob(), 15000);
  }

  async getJobStatus() {
    let jobStatuses: any = await httpGet(FINALIZATION_JOB_URL);

    if (!jobStatuses) {
      jobStatuses = [];
    }

    for (const jobStatus of jobStatuses) {
      if (jobStatus.status === 'failed') {
        message.error(`The finalization job on session uuid ${jobStatus.session.uuid} has been failed`, 5);
      } else if (jobStatus.status === 'done') {
        if (jobStatus.type === 'download_session') {
          this.props.getSession({
            sessionId: jobStatus.session.uuid,
            isDownload: true,
            downloadOptions: jobStatus.download_options,
          });
          message.success(`The download job on session uuid ${jobStatus.session.uuid} has been finished`, 5);
        } else if (jobStatus.type === 'export_session') {
          this.props.getSession({ sessionId: jobStatus.session.uuid });
          message.success(`The finalization job on session uuid ${jobStatus.session.uuid} has been finished`, 5);
        }
      }
    }
  }

  render() {
    return <></>;
  }
}

const mapDispatchToProps = {
  getSession: Actions.getSession,
  getUser: UserActions.getUser,
};
const mapStateToProps = ({ user, auth }: ApplicationState) => ({
  user: user,
  isAuthenticated: auth.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(JobNotification);
