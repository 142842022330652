import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Table, Row, Col } from 'antd';
import { Actions } from '../../reducers/AdminReducer';
import { Actions as SiteActions } from '../../reducers/SiteReducer';
import { TenantUsers } from '../../model/TenantUser';
import { User } from '../../model/User';
import { Loading } from '../layout/Loading';
import UserForm from './AddUserForm';
import UserEditForm from './EditUserForm';
import '../../styles/admin.scss';
import Button from 'antd/lib/button/button';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Column } = Table;

interface Props {
  isLoading: boolean;
  tenantUsers: TenantUsers;
  getTenantUsers: () => void;
  showCreateModal: () => void;
  showEditModal: (id: string) => void;
  user: User;
}

class UserManagement extends React.Component<Props, {}> {
  componentWillMount() {
    this.props.getTenantUsers();
  }

  renderEditButton(id: string) {
    return (
      <Button type='primary' onClick={() => this.props.showEditModal(id)}>
        <FontAwesomeIcon icon={faUserEdit} />
      </Button>
    );
  }

  renderUserList() {
    return (
      <div>
        <Table
          dataSource={this.props.tenantUsers}
          rowKey='id'
          pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'] }}
        >
          <Column title='First Name' dataIndex='first_name' key='first_name' />
          <Column title='Last Name' dataIndex='last_name' key='last_name' />
          <Column title='Edit' dataIndex='id' render={id => this.renderEditButton(id)} />
        </Table>
      </div>
    );
  }

  render() {
    return (
      <Loading isLoading={this.props.isLoading}>
        <UserForm />
        <UserEditForm />
        <Row className='tenant-user-wrapper'>
          <Row>
            <Col span={24}>
              <div className='userlist-title'>
                <span>Users</span>
              </div>
              {this.props.tenantUsers ? this.renderUserList() : null}
            </Col>
          </Row>
          <Row>
            <Col span={8} />
            <Col span={8} />
            <Col span={8} className='tenant-user-action'>
              <Button type='primary' onClick={() => this.props.showCreateModal()}>
                Create New User
              </Button>
            </Col>
          </Row>
        </Row>
      </Loading>
    );
  }
}
const mapStateToProps = ({ admin, user, sites }: ApplicationState) => ({
  tenantUsers: admin.tenantUsers,
  user: user,
  sites: sites.sites,
});

const mapDispatchToProps = {
  getTenantUsers: Actions.getTenantUsers,
  getSites: SiteActions.getSites,
  showCreateModal: Actions.showCreateModal,
  showEditModal: Actions.showEditModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
