import * as React from 'react';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { EditorMode, Actions } from '../../reducers/EditorReducer';
import EditorFooter from './EditorFooter';
import Canvas from './Canvas';
import AnnotationEditorWindow from './annotation/AnnotationEditorWindow';
import { Spin } from 'antd';

interface Props {
  mode: EditorMode;
  isPhotoLoaded: boolean;
}

const EditorLoading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='photo-loading-container'>
        <Spin size='large'></Spin>
      </div>
    );
  }

  return null;
};

class EditorMain extends React.PureComponent<Props, {}> {
  render() {
    return (
      <section className='editor-container'>
        <section className='editor'>
          <EditorLoading isLoading={!this.props.isPhotoLoaded} />
          {this.props.isPhotoLoaded && this.props.mode === EditorMode.ANNOTATING && <AnnotationEditorWindow />}
          <div className={`canvas-wrapper ${!this.props.isPhotoLoaded ? 'loading' : ''}`} id='canvas-wrapper'>
            <Canvas />
          </div>
        </section>
        <EditorFooter />
      </section>
    );
  }
}

const mapDispatchToProps = {
  setMode: Actions.setMode,
};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  mode: editor.mode,
  isPhotoLoaded: editor.isPhotoLoaded,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorMain as any);
