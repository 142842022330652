import { takeLatest, put, call } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/UserReducer";
import { httpGet, httpPost, httpPut } from "../client/http";
import { ActionWithPayload } from "../reducers/actionHelper";
import { Site } from "../model/Site";
import { User } from "../model/User";
import { message } from "antd";

const GET_USER_URL = 'api/user/';
const UPDATE_USER_URL = 'api/user';

export function* getUser() {
    const response = yield call(httpGet, GET_USER_URL, true, true);
    if (response.status < 400) {
        let user = yield response.json();
        yield put(Actions.getUserSuccess(user[0]));
    } else {
        console.error("Error getting current user")
    }
}

export function* updateUserActiveSite(action: ActionWithPayload<ActionTypes.UPDATE_USER_ACTIVE_SITE, User>) {
    try {
        yield call(httpPost, `${UPDATE_USER_URL}`, { active_site: action.payload.active_site });
        message.success('Successfully user active site updated');
        window.location.reload();
    } catch (e) {
        console.log('Error updating user active site', e);
        message.error('Something went wrong while updating the active site. Please try again!');
    }
}

export function* UserSaga() {
    yield takeLatest(ActionTypes.GET_USER, getUser);
    yield takeLatest(ActionTypes.UPDATE_USER_ACTIVE_SITE, updateUserActiveSite);
}