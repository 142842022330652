import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnnotationPos } from '../../../model/Annotation';
import * as React from 'react';

interface Props {
  selected: boolean;
  annotationPos: AnnotationPos;
  onClick: (annotationPos: AnnotationPos) => void;
  onEditClick: (annotatannotationPosion: AnnotationPos) => void;
  onDeleteClick: (annotationPos: AnnotationPos) => void;
}

class AnnotationPreview extends React.PureComponent<Props, {}> {
  render() {
    const { selected, annotationPos, onClick, onEditClick, onDeleteClick } = this.props;

    return (
      <div
        className={`photos-annotation-item-view ${selected ? 'active' : ''}`}
        onClick={e => {
          const element = e.target as HTMLElement;
          if (element.id != 'annotation-item-delete' && element.parentElement.id != 'annotation-item-delete') {
            onClick && onClick(annotationPos);
          }
        }}
      >
        <div className='photos-annotation-item-title'>
          <p>
            {annotationPos.annotation.title}
            <FontAwesomeIcon
              icon={faEdit}
              id='annotation-item-edit'
              onClick={() => onEditClick && onEditClick(annotationPos)}
            />
            <FontAwesomeIcon
              icon={faTrash}
              id='annotation-item-delete'
              onClick={() => onDeleteClick && onDeleteClick(annotationPos)}
            />
          </p>
        </div>
        <div className='photos-annotation-item-image'>
          {annotationPos.annotation.image && <img src={annotationPos.annotation.image.photo} />}
        </div>
        <div className='photos-annotation-item-description'>{annotationPos.annotation.description}</div>
      </div>
    );
  }
}

export default AnnotationPreview;
