import React from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../reducers/SessionReducer';
import { ApplicationState } from '../../state/ApplicationState';
import '@/styles/session.scss';
import { RouteComponentProps } from 'react-router';
import { ChangePhotoPayload, GetSession, Session, spinviewerPhotos } from '../../model/Session';
import { User } from '../../model/User';
import { Select } from 'antd';
import SpinviewMainSession from './SpinviewMainSession';
import SpinviewAltSession from './SpinviewAltSession';
import PhotoSession, { PhotoSessionPage } from './PhotoSession';
import PanoviewSessoin from './PanoviewSessoin';
import { getQueryParam } from '../../util';

interface MatchParams {
  sessionId: string;
  view: string;
}

const { Option } = Select;

interface Props extends RouteComponentProps<MatchParams> {
  user: User;
  session: Session;
  getSession: (sessionSearch: GetSession) => void;
  setCurrentPhoto: (photoPayload: ChangePhotoPayload) => void;
}

interface State {
  currentView: string;
  user: User;
  session: Session;
}

export class SessionPage extends React.Component<Props, State> {
  state: State = {
    currentView: null,
    user: null,
    session: null,
  };

  componentWillMount() {
    const { sessionId } = this.props.match.params;
    const photoId = getQueryParam('photoId');

    if (photoId) {
      this.props.getSession({
        sessionId,
        photoId,
      });
    } else {
      this.props.getSession({
        sessionId,
      });
    }

    this.setState({ user: this.props.user });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user != nextProps.user) {
      this.setState({ user: nextProps.user }, () => this.setFirstInitialView());
    }

    if (this.props.session != nextProps.session) {
      this.setState({ session: nextProps.session }, () => this.setFirstInitialView());
    }
  }

  setFirstInitialView() {
    const view = getQueryParam('view');

    let nextView = '';
    const { user, session } = this.state;

    if (!user || !user.active_site || !session) {
      return;
    }

    let availableViews = [];

    if (user.active_site.use_spinviewer_main && spinviewerPhotos(session, 'main').length > 0) {
      availableViews.push('spinviewer-main');
    }

    if (user.active_site.use_spinviewer_main && spinviewerPhotos(session, 'alt').length > 0) {
      availableViews.push('spinviewer-alt');
    }

    if (user.active_site.use_panoviewer && session.panoviewer_nav) {
      availableViews.push('panoviewer');
    }

    if (user.active_site.use_photos && session.photos.length > 0) {
      availableViews.push('photos');
    }

    if (view) {
      if (availableViews.includes(view)) {
        nextView = view;
      } else {
        nextView = availableViews[0];
      }
    } else {
      nextView = availableViews[0];
    }

    this.setState({ currentView: nextView });
  }

  handleViewChange(view) {
    this.setState({ currentView: view });
  }

  renderMenu() {
    const { user, session, currentView } = this.state;

    if (user && user.active_site && session) {
      return (
        <div className='session-view-selection'>
          <Select
            value={currentView}
            size='large'
            defaultValue={currentView}
            onChange={this.handleViewChange.bind(this)}
            style={{ width: 200 }}
          >
            {user.active_site.use_spinviewer_main && spinviewerPhotos(session, 'main').length > 0 && (
              <Option value={'spinviewer-main'}>Exterior Main</Option>
            )}
            {user.active_site.use_spinviewer_alt && spinviewerPhotos(session, 'alt').length > 0 && (
              <Option value={'spinviewer-alt'}>Exterior Alt</Option>
            )}
            {user.active_site.use_panoviewer && session.panoviewer_nav && (
              <Option value={'panoviewer'}>Interior</Option>
            )}
            {user.active_site.use_photos && session.photos.length > 0 && <Option value={'photos'}>Photos</Option>}
          </Select>
        </div>
      );
    } else {
      return <div className='session-view-selection'></div>;
    }
  }

  render() {
    return (
      <div className='session-container'>
        {this.renderMenu()}
        {this.state.currentView === 'spinviewer-main' && <SpinviewMainSession></SpinviewMainSession>}
        {this.state.currentView === 'spinviewer-alt' && <SpinviewAltSession></SpinviewAltSession>}
        {this.state.currentView === 'panoviewer' && <PanoviewSessoin></PanoviewSessoin>}
        {this.state.currentView === 'photos' && <PhotoSession></PhotoSession>}
      </div>
    );
  }
}

const mapStateToProps = ({ sessions, user }: ApplicationState) => ({
  user: user,
  session: sessions.currentSession,
});

const mapDispatchToProps = {
  setCurrentPhoto: Actions.setCurrentPhoto,
  getSession: Actions.getSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionPage as any);
