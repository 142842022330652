import * as React from 'react';
import { Link } from 'react-router-dom';
import logo from '@/assets/jombler.png';
import '@/styles/header.css';
import { CLASSIFICATIONS, DASHBOARD, LOGIN, SITES, ADMIN, METRIC } from '../../router/Routes';
import { Avatar, Dropdown, Layout, Menu, Select } from 'antd';
import { Actions as UserActions } from '../../reducers/UserReducer';
import { Actions as SiteActions } from '../../reducers/SiteReducer';
import { ApplicationState } from '../../state/ApplicationState';
import { connect } from 'react-redux';
import { User } from '../../model/User';
import { Sites } from '../../model/Site';

const { Header: HeaderComponent } = Layout;

interface Props {
  isAuthenticated: boolean;
  logout: () => void;
  user: User;
  getUser: () => void;
  getSites: () => void;
  sites: Sites;
  updateUserActiveSite: (user: User) => void;
}

const { Option } = Select;

class Header extends React.PureComponent<Props, {}> {
  componentDidMount() {
    this.props.getUser();
    if (this.props.isAuthenticated) {
      this.props.getSites();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.isAuthenticated && nextProps.isAuthenticated) {
      this.props.getSites();
    }
  }

  handleLogoutClick = () => {
    this.props.logout();
  };
  renderLinks() {
    let userMenu = (
      <Menu>
        <Menu.Item>
          <span onClick={this.handleLogoutClick}>Sign out</span>
        </Menu.Item>
      </Menu>
    );

    if (this.props.isAuthenticated) {
      let displayLocationSelection = this.props.user.tenant_admin || this.props.user.sites.length > 1;
      return (
        <ul>
          <li>
            <Link to={METRIC}>Metric</Link>
          </li>
          {this.props.user.tenant_admin ? (
            <li>
              <Link to={ADMIN}>Admin</Link>
            </li>
          ) : null}
          <li>
            <Link to={DASHBOARD}>Dashboard</Link>
          </li>
          <li>
            <Link to={SITES}>Sites</Link>
          </li>
          <li>
            <Link to={CLASSIFICATIONS}>Classifications</Link>
          </li>
          {displayLocationSelection && this.renderLocationSelection()}
          <li>
            <Dropdown overlay={userMenu} placement='bottomCenter' overlayStyle={{ cursor: 'pointer' }}>
              <div>
                <Avatar shape='circle' size={32} icon='user' />
                <span style={{ paddingLeft: '0.5em' }}>{this.props.user.username}</span>
              </div>
            </Dropdown>
          </li>
        </ul>
      );
    }
    return (
      <ul>
        <li>
          <Link to={LOGIN}>Login</Link>
        </li>
      </ul>
    );
  }

  renderLocationSelection() {
    return (
      <li>
        <Select
          showSearch
          style={{ width: 150 }}
          placeholder='Select a site'
          onChange={v => {
            const site = this.props.sites.find(site => v == site.id);
            this.props.updateUserActiveSite({ ...this.props.user, active_site: site });
          }}
          defaultValue={this.props.user.active_site.id}
        >
          {this.props.sites.map(site => (
            <Option value={site.id}>{site.name}</Option>
          ))}
        </Select>
      </li>
    );
  }

  render() {
    return (
      <HeaderComponent className='jombler-header'>
        <div className='logo'>
          <Link to={DASHBOARD}>
            <img src={logo} alt='Jombler Logo' />
          </Link>
        </div>

        <div className='links'>{this.renderLinks()}</div>
      </HeaderComponent>
    );
  }
}

const mapDispatchToProps = {
  getUser: UserActions.getUser,
  getSites: SiteActions.getSites,
  updateUserActiveSite: UserActions.updateUserActiveSite,
};
const mapStateToProps = ({ user, sites }: ApplicationState) => ({
  user: user,
  sites: sites.sites,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
