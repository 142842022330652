import { Annotation } from './Annotation';
import { Classification } from './Classification';

export function spinviewerPhotos(session, type): SpinviewerPhoto[] {
  if (!session || !session.spinviewer_photos) {
    return [];
  }

  const photos = session.spinviewer_photos.filter(photo => photo.type === type);

  return photos.sort((photo1, photo2) => photo1.order - photo2.order);
}

export interface Session {
  uuid: string;
  vehicle: Vehicle;
  complete: boolean;
  export: boolean;
  photos: Photo[];
  date: string;
  photo_count?: number;
  downloadOptions?: string[];

  // 360 view
  spinviewer_nav?: string;
  spinviewer_photos?: SpinviewerPhoto[];

  panoviewer_nav?: string;
  panoviewer_media?: string;
  panoviewer_yaw?: number;
  panoviewer_pitch?: number;
}

export interface NewObject360Request {
  uuid?: string;
  spinviewer?: boolean;
  spinviewer_photos: any[];
  panoviewer?: boolean;
  panoviewer_photo?: any;
}

export interface GetSession {
  sessionId: string;
  photoId?: string;
  isDownload?: boolean;
  downloadOptions?: string[];
}

export interface Vehicle {
  vin: string;
  stock_number: string;
  make: string;
  model: string;
  year: number;
}

export interface Photo {
  id: string;
  session_uuid: string;
  classification: Classification;
  photo: string;
  order: number;
  annotation: Annotation;
}

export interface SpinviewerPhoto {
  id: string;
  type: string;
  photo: string;
  order: number;
}

export interface NewSessionRequest {
  vin: string;
  photos: any;
  spinviewer_main_photos: any[];
  spinviewer_alt_photos: any[];
  panoviewer_photo?: any;
}

export enum NavigatePhotoDirection {
  FIRST_UNCLASSIFIED,
  FORWARD,
  BACKWARDS,
}

export interface ChangePhotoPayload {
  currentSession: Session;
  direction: NavigatePhotoDirection;
  currentIndex: number;
  photoId?: string;
}

export type BeforePhotos = Photo[] | [null, Photo] | [null, null];
export type AfterPhotos = Photo[] | [Photo, null] | [null, null];

export interface SetPhotoPayload {
  currentIndex: number;
  beforePhotos: BeforePhotos;
  afterPhotos: AfterPhotos;
}

export interface SetSpinviewerPhotoSortPayload {
  session: Session;
  photos: SpinviewerPhoto[];
  type: string;
}

export interface SetPanoviewerCenterPayload {
  session: Session;
  panoviewer_yaw: number;
  panoviewer_pitch: number;
}

export interface PhotoArrangement {
  currentPhotoIndex?: Photo;
  beforePhotos: Photo[];
  afterPhotos: Photo[];
}

export interface PhotoArrangementPayload {
  arrangement: PhotoArrangement;
  direction: NavigatePhotoDirection;
}

export interface PhotoUploadRequest {
  session_uuid: string;
  photo: any;
}

export interface PhotoCanvasUploadRequest {
  photo: Photo;
  file: any;
}

export interface DeletePhotoPayload {
  id: number;
  sessionId: string;
}

export type Sessions = Session[];
