import React from 'react';
import { Form, Input, Upload, Icon, message, Modal, Checkbox, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/ClassificationReducer';
import { NewClassificationRequest } from '../../model/Classification';

//Creating the upload section from Antd
const { Dragger } = Upload;

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

interface ConnectedClassificationFormProps {
  form: any;
  closeModal: () => void;
  showModal: boolean;
  createClassification: (classification: NewClassificationRequest) => void;
}

export interface ClassificationFormState {
  file: any | null;
}

class ClassificationForm extends React.Component<ConnectedClassificationFormProps, ClassificationFormState> {
  readonly state = {
    file: null,
  };
  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      const allValues = {
        ...values,
        thumbnail: this.state.file,
      };
      if (!err) {
        console.log('No error from form submit', allValues);
        this.props.createClassification(allValues);
        this.props.form.resetFields();
        this.props.closeModal();
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleModalCancel = () => {
    this.props.closeModal();
    this.props.form.resetFields();
  };

  onChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        file: info.file,
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    const draggerProps = {
      multiple: false,
      name: 'upload',
      onChange: this.onChange,
      customRequest: dummyRequest,
    };

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Modal
          destroyOnClose={true}
          title='Create a classification'
          visible={this.props.showModal}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <Form>
            <Form.Item label='Name'>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Name cannot be empty',
                  },
                ],
              })(<Input placeholder="Driver's side door" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('required', {
                valuePropName: 'checked',
                initialValue: false,
              })(<Checkbox>Required</Checkbox>)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(
                'order',
                {},
              )(<InputNumber style={{ float: 'left' }} min={1} max={999} defaultValue={25} value={0} />)}
              <p style={{ float: 'left', marginLeft: '5px' }}>Order</p>
            </Form.Item>
            <Form.Item label='Upload files'>
              <Dragger {...draggerProps}>
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Upload a photo to represent the classification type</p>
              </Dragger>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const form = Form.create({ name: 'ClassificationForm' })(ClassificationForm);

const mapStateToProps = ({ classifications }: ApplicationState) => ({
  showModal: classifications.showModal,
});

const mapDispatchToProps = {
  closeModal: Actions.hideClassificationModal,
  createClassification: Actions.createClassification,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
