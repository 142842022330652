/* global Spinviewer */

import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { RouteComponentProps, Router } from 'react-router';
import { Button, Modal, Spin } from 'antd';
import $ from 'jquery';
import { isDefined, uploadingBox } from '../../utils';
import { SpinAnnotation, SpinAnnotations } from '../../../model/Annotation';
import { Actions } from '../../../reducers/SessionReducer';
import { Actions as AnnotationActions } from '../../../reducers/AnnotationReducer';
import SpinviewAnnotationForm from './SpinviewAnnotationForm';
import SpinviewAnnotationPreview from './SpinviewAnnotationPreview';
import {
  Session,
  GetSession,
  spinviewerPhotos,
  SpinviewerPhoto,
  SetSpinviewerPhotoSortPayload,
} from '../../../model/Session';
import { history } from '../../../index';
import '@/styles/spinview-annotation.scss';
import { User } from '../../../model/User';
import { ReactSortable, Sortable, MultiDrag, Swap } from 'react-sortablejs';
import { Col } from 'antd';
import { Card } from '../../layout/Card';
import cn from 'classnames';

const Loading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='spinview-loading-container'>
        <Spin size='large'></Spin>
      </div>
    );
  }

  return null;
};

interface MatchParams {
  sessionId: string;
  type: ViewType;
}

export enum ViewMode {
  EDITOR,
  REORDER,
}

export enum ViewType {
  MAIN = 'spinviewer-main',
  ALT = 'spinviewer-alt',
}

interface Props extends RouteComponentProps<MatchParams> {
  sessionId: string;
  session: Session;
  user: User;
  getSession: (sessionSearch: GetSession) => void;
  getAnnotations: (sessionId: string) => void;
  createAnnotation: (annotation: SpinAnnotation) => void;
  updateAnnotation: (annotation: SpinAnnotation) => void;
  deleteAnnotation: (annotation: SpinAnnotation) => void;
  setSpinviewerPhotoSort: (payload: SetSpinviewerPhotoSortPayload) => void;
  annotations: SpinAnnotations;
}

interface State {
  spinViewer: any;
  loading: boolean;
  curFrameIndex: number;
  isEditAnnotation: boolean;
  annotation: SpinAnnotation;
  annotations: SpinAnnotations;
  showAnnotationModal: boolean;
  previewAnnotationModal: boolean;
  session: Session;
  type: ViewType;
  mode: ViewMode;
  photos: SpinviewerPhoto[];
}

class SpinviewAnnotationPage extends React.Component<Props, State> {
  state: State = {
    spinViewer: null,
    loading: true,
    curFrameIndex: -1,
    isEditAnnotation: false,
    annotation: null,
    showAnnotationModal: false,
    previewAnnotationModal: false,
    annotations: [],
    session: null,
    type: null,
    mode: ViewMode.EDITOR,
    photos: [],
  };

  private spinviewerRef: React.RefObject<HTMLDivElement>;
  private clientX: number = 0;
  private clientY: number = 0;

  constructor(props) {
    super(props);
    this.spinviewerRef = React.createRef();
  }

  componentWillMount() {
    const { sessionId, type } = this.props.match.params;

    this.setState({ type });

    this.props.getSession({
      sessionId,
    });

    if (type === ViewType.MAIN) {
      this.props.getAnnotations(sessionId);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.session != nextProps.session) {
      const photos = spinviewerPhotos(nextProps.session, this.state.type === ViewType.MAIN ? 'main' : 'alt');
      this.setState({ session: nextProps.session, photos }, () => this.initSpinviewer());
    }

    if (this.props.annotations != nextProps.annotations) {
      this.setState({ annotations: nextProps.annotations, annotation: null });
    }
  }

  initSpinviewer() {
    let { session, spinViewer, photos } = this.state;

    if (!session || photos.length == 0) {
      return;
    }

    if (spinViewer) {
      spinViewer.destroy();
      spinViewer = null;
      this.setState({ curFrameIndex: -1 });
    }

    const spinViewerElement = this.spinviewerRef.current;

    const self = this;

    spinViewer = new Spinviewer(
      spinViewerElement,
      {
        imageArrays: photos.map(photo => photo.photo),
        minXDifference: 20,
      },
      (eventType, data) => {
        if (eventType === 'load') {
          self.setState({ spinViewer, loading: false }, () => {
            self.showSpinViewByFrameIndex(0);
          });
        }

        if (eventType === 'current_image_change') {
          self.setState({ curFrameIndex: data.curImageIndex });
        }
      },
    );
  }

  handleSpinviewerMouseDownEvent(e) {
    this.clientX = e.clientX;
    this.clientY = e.clientY;
  }

  handleSpinviewerMouseUpEvent(e) {
    if (e.target.id === 'annotation-frame-done') {
      return;
    }

    const spinviewerElement = this.spinviewerRef.current;

    if (!spinviewerElement) {
      return;
    }

    const spinviewerElementRect = spinviewerElement.getBoundingClientRect() as DOMRect;

    let annotation = this.state.annotation;

    if (
      Math.abs(e.clientX - this.clientX) <= 1 &&
      Math.abs(e.clientY - this.clientY) <= 1 &&
      this.state.isEditAnnotation &&
      annotation
    ) {
      const curFrameIndex = this.state.curFrameIndex;
      const mouseOffset = 5;
      const x = Number(
        (((e.clientX - spinviewerElementRect.left - mouseOffset) * 100) / spinviewerElementRect.width).toFixed(3),
      );
      const y = Number(
        (((e.clientY - spinviewerElementRect.top - mouseOffset) * 100) / spinviewerElementRect.height).toFixed(3),
      );

      let annotationPos = annotation.annotationPos;

      if (!annotationPos) {
        annotationPos = {};
      }

      annotationPos[curFrameIndex] = {
        frame_index: curFrameIndex,
        position_x: x,
        position_y: y,
      };

      annotation.annotationPos = annotationPos;

      this.setState({ annotation });
      setTimeout(() => {
        this.showSpinViewNextFrame();
      }, 200);
    }
  }

  showSpinViewNextFrame() {
    const frameCount = this.state.spinViewer.imageCount;
    let curFrameIndex = this.state.curFrameIndex;

    if (this.state.curFrameIndex === frameCount - 1) {
      curFrameIndex = 0;
    } else {
      curFrameIndex++;
    }

    this.showSpinViewByFrameIndex(curFrameIndex);
  }

  showSpinViewByFrameIndex(index) {
    this.setState({ curFrameIndex: index }, () => {
      this.state.spinViewer.showImage(index);
    });
  }

  renderAnnotation(a: SpinAnnotation) {
    const { curFrameIndex, annotation } = this.state;

    if (curFrameIndex < 0) {
      return;
    }

    const spinviewerElement = this.spinviewerRef.current;

    if (!spinviewerElement) {
      return;
    }

    const spinviewerElementRect = spinviewerElement.getBoundingClientRect() as DOMRect;

    if (spinviewerElementRect.width == 0 || spinviewerElementRect.height == 0) {
      return;
    }

    if (a && a.annotationPos && a.annotationPos[curFrameIndex]) {
      const annotationPos = a.annotationPos[curFrameIndex];
      const left = (annotationPos.position_x * spinviewerElementRect.width) / 100;
      const top = (annotationPos.position_y * spinviewerElementRect.height) / 100;

      return (
        <div
          className={`spinview-annotation-pos ${annotation && annotation.id == a.id ? 'active' : ''}`}
          key={`annotation-${a.id}`}
          id={`annotation-${a.id}`}
          style={{
            left: `${left}px`,
            top: `${top}px`,
          }}
          onClick={() => this.handleAnnotationPreviewClick(a)}
        >
          <div className='spinview-annotation-pos-icon' />
        </div>
      );
    }
  }

  handleAnnotationPreviewClick(a: SpinAnnotation) {
    if (!this.state.isEditAnnotation) {
      $('.spinview-sidebar-outer').animate({
        scrollTop: $(`#annotation-${a.id}`).offset().top,
      });
      this.setState({ annotation: a, previewAnnotationModal: true });
    }
  }

  handleAnnotationPreviewCancel = () => {
    this.setState({ previewAnnotationModal: false });
  };

  handleAnnotationItemClick(annotation: SpinAnnotation) {
    const frameIndexes = Object.keys(annotation.annotationPos).map(v => parseInt(v));
    this.showSpinViewByFrameIndex(Math.min.apply(Math, frameIndexes));
    this.setState({ annotation });
  }

  handleAnnotationItemEditClick(annotation: SpinAnnotation) {
    this.setState({ annotation, isEditAnnotation: true });
  }

  handleAnnotationItemDeleteClick(annotation: SpinAnnotation) {
    this.props.deleteAnnotation(annotation);
    this.setState({ annotation: null });
  }

  handleAnnotationNewSave = async (annotation: SpinAnnotation) => {
    const { session } = this.state;
    annotation.session = session.uuid;
    this.props.createAnnotation(annotation);
    uploadingBox('Started creating annotation of ' + annotation.title, 'Creating, please wait....', annotation.title);
    this.setState({
      showAnnotationModal: false,
      isEditAnnotation: false,
      annotation: null,
    });
  };

  handleAnnotationNewCancel = () => {
    this.setState({
      showAnnotationModal: false,
      isEditAnnotation: false,
      annotation: null,
    });
  };

  handleAnnotationEditSave = async (annotation: SpinAnnotation) => {
    const annotations = this.state.annotations as SpinAnnotations;
    let index = annotations.findIndex(a => a.id === annotation.id);
    annotations[index] = annotation;
    this.props.updateAnnotation(annotation);
    uploadingBox('Started updating of ' + annotation.title, 'Updating, please wait....', annotation.title);
    this.setState({ isEditAnnotation: false, annotation: null, annotations });
  };

  handleAnnotationEditCancel = () => {
    this.setState({ isEditAnnotation: false, annotation: null });
  };

  handleChangePhotosOrder(photos) {
    photos.map((p, index) => (p.order = index));

    this.setState({ photos });
  }

  markAsFirstImage(index: number) {
    let { photos } = this.state;

    for (var i = index; i < photos.length; i++) {
      photos[i].order = i - index;
    }

    for (var i = 0; i < index; i++) {
      photos[i].order = photos.length - index + i;
    }

    this.setState({ photos });
  }

  handlePhotoOrdersSave() {
    this.props.setSpinviewerPhotoSort({
      session: this.state.session,
      photos: this.state.photos,
      type: this.state.type,
    });
    uploadingBox('Started saving spinviewer photo orders', 'Updating, please wait....', 'spinviewer-photo-order');
  }

  render() {
    let { isEditAnnotation, annotation, curFrameIndex, loading, annotations, session, mode, type, photos } = this.state;

    photos = photos.sort((p1, p2) => p1.order - p2.order);

    const modalProps = {
      onCancel: this.handleAnnotationNewCancel.bind(this),
      onSave: this.handleAnnotationNewSave.bind(this),
      annotation: annotation,
      session: session,
    };

    const previewModalProps = {
      selected: false,
      annotation: annotation,
      onClick: null,
      onEditClick: null,
      onDeleteClick: null,
      isEditable: false,
    };

    return (
      <div className='spinview-annnotation'>
        <section className='spinview-sidebar'>
          <div className='spinview-sidebar-inner'>
            <ul>
              <li
                className={cn('feature', { active: this.state.mode === ViewMode.EDITOR })}
                onClick={() => {
                  if (mode === ViewMode.REORDER) {
                    this.setState({ mode: ViewMode.EDITOR }, () => {
                      this.forceUpdate();
                    });
                  }
                }}
              >
                <span className='icon'>
                  <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fas'
                    data-icon='bullseye-pointer'
                    className='svg-inline--fa fa-bullseye-pointer fa-w-16'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path
                      fill='currentColor'
                      d='M256 0C114.8 0 0 114.8 0 256c0 9.318 .5684 18.5 1.543 27.56c5.055-4.24 10.67-7.84 17.28-9.725l45.43-12.98C64.21 259.2 64 257.6 64 256c0-106 85.96-192 192-192s192 85.96 192 192s-85.96 192-192 192c-1.641 0-3.23-.2051-4.861-.2461l-12.98 45.42c-1.885 6.605-5.494 12.22-9.754 17.28C237.5 511.4 246.7 512 256 512c141.2 0 256-114.8 256-256S397.2 0 256 0zM284.6 330.5l-19.6 68.6C340.2 394.3 400 332.3 400 256c0-79.4-64.6-144-144-144c-76.3 0-138.3 59.83-143.1 134.1l68.6-19.6C193.1 197.4 221.9 176 256 176c44.18 0 80 35.82 80 80C336 290.1 314.6 318.9 284.6 330.5zM267.3 244.7C263.2 240.6 257.2 239.1 251.6 240.6l-224 64C21.09 306.5 16.45 312.2 16.03 318.1c-.4219 6.766 3.438 13.06 9.672 15.72l74.44 31.91l-84.14 84.14c-12.49 12.49-12.5 32.75-.0005 45.25c12.49 12.5 32.75 12.5 45.25 .0005l84.14-84.14l31.91 74.44C179.8 492.2 185.6 496 192 496c.3281 0 .6719-.0156 1.016-.0313c6.75-.4219 12.52-5.062 14.38-11.58l64-224C272.1 254.8 271.4 248.8 267.3 244.7z'
                    ></path>
                  </svg>
                </span>
              </li>
              <li
                className={cn('feature', { active: this.state.mode === ViewMode.REORDER })}
                onClick={() => {
                  this.setState({ mode: ViewMode.REORDER });
                }}
              >
                <span className='icon'>
                  <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fas'
                    data-icon='shuffle'
                    className='svg-inline--fa fa-shuffle fa-w-16'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'
                  >
                    <path
                      fill='currentColor'
                      d='M424.1 287c-15.13-15.12-40.1-4.426-40.1 16.97V352H336L153.6 108.8C147.6 100.8 138.1 96 128 96H32C14.31 96 0 110.3 0 128s14.31 32 32 32h80l182.4 243.2C300.4 411.3 309.9 416 320 416h63.97v47.94c0 21.39 25.86 32.12 40.99 17l79.1-79.98c9.387-9.387 9.387-24.59 0-33.97L424.1 287zM336 160h47.97v48.03c0 21.39 25.87 32.09 40.1 16.97l79.1-79.98c9.387-9.391 9.385-24.59-.0013-33.97l-79.1-79.98c-15.13-15.12-40.99-4.391-40.99 17V96H320c-10.06 0-19.56 4.75-25.59 12.81L254 162.7L293.1 216L336 160zM112 352H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c10.06 0 19.56-4.75 25.59-12.81l40.4-53.87L154 296L112 352z'
                    ></path>
                  </svg>
                </span>
              </li>
            </ul>
          </div>
          {mode === ViewMode.EDITOR && type === ViewType.MAIN && (
            <div className='spinview-sidebar-outer active'>
              <div className='spinview-sidebar-toolbar'>
                {!isEditAnnotation && (
                  <Button type='primary' onClick={() => this.setState({ isEditAnnotation: true, annotation: {} })}>
                    Add Annotation
                  </Button>
                )}
                {isEditAnnotation && annotation && !isDefined(annotation.id) && (
                  <Button type='danger' onClick={() => this.setState({ isEditAnnotation: false, annotation: null })}>
                    Cancel
                  </Button>
                )}
              </div>
              {annotations.map((a: SpinAnnotation, index) => {
                const editProps = {
                  onCancel: this.handleAnnotationEditCancel.bind(this),
                  onSave: this.handleAnnotationEditSave.bind(this),
                  annotation,
                  session,
                };

                const previewProps = {
                  selected: annotation && annotation.id == a.id,
                  annotation: JSON.parse(JSON.stringify(a)),
                  onClick: this.handleAnnotationItemClick.bind(this),
                  onEditClick: this.handleAnnotationItemEditClick.bind(this),
                  onDeleteClick: this.handleAnnotationItemDeleteClick.bind(this),
                  isEditable: true,
                };

                return (
                  <div className='spinview-annotation-list-item' key={index}>
                    {isEditAnnotation && annotation && annotation.id == a.id && (
                      <SpinviewAnnotationForm {...editProps}></SpinviewAnnotationForm>
                    )}
                    {(!isEditAnnotation || !annotation || annotation.id !== a.id) && (
                      <SpinviewAnnotationPreview {...previewProps}></SpinviewAnnotationPreview>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </section>
        <div className='spinview-main-view'>
          <Button
            className='view-back-button'
            type='primary'
            id='view-switch-button'
            onClick={() => {
              history.push(`/session/${this.props.session.uuid}?view=${this.state.type}`);
            }}
          >
            Back To Session
          </Button>
          {mode === ViewMode.REORDER && (
            <Button
              className='save-reorder-button'
              type='primary'
              size='large'
              onClick={() => this.handlePhotoOrdersSave()}
            >
              Save
            </Button>
          )}
          {this.props.user.active_site && this.props.user.active_site.use_panoviewer && (
            <Button
              className='view-switch-button'
              type='danger'
              id='view-switch-button'
              onClick={() => {
                history.push('/panoviewer-editor/' + this.props.session.uuid);
              }}
            >
              Go To Interior
            </Button>
          )}
          <Loading isLoading={loading} />
          <div className={cn('spinview-360view-reorder', { visible: mode === ViewMode.REORDER })}>
            <div className='spinview-360view-reorder-wrapper'>
              {photos.length > 0 && (
                <ReactSortable list={photos} setList={photos => this.handleChangePhotosOrder(photos)}>
                  {photos.map((photo: SpinviewerPhoto, index) => {
                    return (
                      <Col
                        key={photo.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '30px',
                        }}
                      >
                        <Card key={photo.id} className={`vin-card`}>
                          <div className='photo-section'>
                            <img src={photo.photo}></img>
                            <Button
                              className='first-image-button'
                              type='primary'
                              onClick={() => this.markAsFirstImage(index)}
                            >
                              Mark As First Image
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </ReactSortable>
              )}
            </div>
          </div>
          <div className={cn('spinview-360view-editor', { visible: mode === ViewMode.EDITOR })}>
            <div className='spinview-360view-editor-wrapper'>
              <div className='spinview-info'>
                {curFrameIndex >= 0 && <span className='spinview-frameindex-label'>{curFrameIndex}</span>}
                {isEditAnnotation && annotation && (
                  <span className='spinview-edit-message'>Please click on the viewer to add annotation path.</span>
                )}
              </div>
              <div
                id='spinview-360view'
                ref={this.spinviewerRef}
                onMouseDown={this.handleSpinviewerMouseDownEvent.bind(this)}
                onMouseUp={this.handleSpinviewerMouseUpEvent.bind(this)}
              >
                {isEditAnnotation && annotation && annotation.annotationPos && !isDefined(annotation.id) && (
                  <Button
                    className='annotation-frame-done'
                    type='primary'
                    id='annotation-frame-done'
                    onClick={() => {
                      this.setState({ showAnnotationModal: true });
                    }}
                  >
                    Done
                  </Button>
                )}
                {!isEditAnnotation && annotations.map(a => this.renderAnnotation(a))}
              </div>
            </div>
          </div>
          <Modal
            className='annotation-new-modal'
            destroyOnClose={true}
            title='Add Annotation'
            visible={this.state.showAnnotationModal}
            onCancel={this.handleAnnotationNewCancel.bind(this)}
            footer={false}
          >
            <SpinviewAnnotationForm {...modalProps}></SpinviewAnnotationForm>
          </Modal>
          <Modal
            className='annotation-preview-modal'
            destroyOnClose={true}
            title='Annotation'
            visible={this.state.previewAnnotationModal}
            onCancel={this.handleAnnotationPreviewCancel.bind(this)}
            footer={false}
            width={400}
          >
            <SpinviewAnnotationPreview {...previewModalProps}></SpinviewAnnotationPreview>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ sessions, annotations, user }: ApplicationState) => ({
  user: user,
  session: sessions.currentSession,
  annotations: annotations.spin_annotations,
});

const mapDispatchToProps = {
  getSession: Actions.getSession,
  setSpinviewerPhotoSort: Actions.setSpinviewerPhotoSort,
  getAnnotations: AnnotationActions.getSpinAnnotations,
  createAnnotation: AnnotationActions.createSpinAnnoation,
  updateAnnotation: AnnotationActions.updateSpinAnnotation,
  deleteAnnotation: AnnotationActions.deleteSpinAnnotation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpinviewAnnotationPage as any);
