import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { RouteComponentProps } from 'react-router';
import { PanoViewer } from '@egjs/view360';
import { Button, Modal, Spin } from 'antd';
import $ from 'jquery';
import { isDefined, uploadingBox } from '../../utils';
import { PanoAnnotation, PanoAnnotations } from '../../../model/Annotation';
import { Actions } from '../../../reducers/SessionReducer';
import { Actions as AnnotationActions } from '../../../reducers/AnnotationReducer';
import PanoviewAnnotationForm from './PanoviewAnnotationForm';
import PanoviewAnnotationPreview from './PanoviewAnnotationPreview';
import { GetSession, Session, SetPanoviewerCenterPayload } from '../../../model/Session';
import { history } from '../../../index';
import '@/styles/panoview-annotation.scss';
import { User } from '../../../model/User';

const Loading = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div className='panoview-loading-container'>
        <Spin size='large'></Spin>
      </div>
    );
  }

  return null;
};

interface MatchParams {
  sessionId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  sessionId: string;
  session: Session;
  setPanoviewerCenter: (payload: SetPanoviewerCenterPayload) => void;
  getSession: (sessionSearch: GetSession) => void;
  getPanoAnnotations: (sessionId: string) => void;
  createPanoAnnotation: (annotation: PanoAnnotation) => void;
  updatePanoAnnotation: (annotation: PanoAnnotation) => void;
  deletePanoAnnotation: (annotation: PanoAnnotation) => void;
  pano_annotations: PanoAnnotations;
  user: User;
}

interface State {
  panoViewer: any;
  panoViewerWrapper: any;
  loading: boolean;
  isEditAnnotation: boolean;
  annotation: PanoAnnotation;
  pano_annotations: PanoAnnotations;
  showAnnotationModal: boolean;
  previewAnnotationModal: boolean;
  session: Session;
}

class PanoviewAnnotationPage extends React.Component<Props, State> {
  state: State = {
    panoViewer: null,
    panoViewerWrapper: null,
    loading: true,
    isEditAnnotation: false,
    annotation: null,
    showAnnotationModal: false,
    previewAnnotationModal: false,
    pano_annotations: [],
    session: null,
  };

  componentWillMount() {
    const { sessionId } = this.props.match.params;
    this.props.getSession({
      sessionId,
    });
    this.props.getPanoAnnotations(sessionId);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.session != nextProps.session) {
      this.setState({ session: nextProps.session }, () => this.initPanoviewer());
    }

    if (this.props.pano_annotations != nextProps.pano_annotations) {
      this.setState({
        pano_annotations: nextProps.pano_annotations,
        annotation: null,
      });
    }
  }

  initPanoviewer() {
    let { session, panoViewer } = this.state;

    if (!session) {
      return;
    }

    if (panoViewer) {
      return;
    }

    const panoViewElement = document.getElementById('panoview-360view');
    const panoViewerWrapper = $(panoViewElement);

    panoViewer = new PanoViewer(panoViewElement, {
      image: session.panoviewer_media,
      useZoom: false,
      projectionType: PanoViewer.PROJECTION_TYPE.EQUIRECTANGULAR,
      cubemapConfig: {
        tileConfig: { flipHorizontal: true, rotation: 0 },
      },
    });

    const self = this;

    panoViewer.on('ready', () => {
      this.setState({ panoViewerWrapper, panoViewer, loading: false }, () => {
        if (session.panoviewer_yaw !== 0 && session.panoviewer_pitch !== 0) {
          panoViewer.lookAt({
            fov: 80,
            yaw: session.panoviewer_yaw,
            pitch: session.panoviewer_pitch,
          });
        }

        setTimeout(function() {
          panoViewer.lookAt(
            {
              fov: 65,
            },
            500,
          );
          self.forceUpdate();
        });
      });
    });

    panoViewer.on('viewChange', e => {
      this.forceUpdate();
    });

    let pageX = 0,
      pageY = 0;

    panoViewerWrapper.on('mousedown', e => {
      pageX = e.pageX;
      pageY = e.pageY;
    });

    panoViewerWrapper.on('mouseup', e => {
      if (e.target.id === 'annotation-frame-done') {
        return;
      }

      let annotation = this.state.annotation;
      let panoViewer = this.state.panoViewer;

      if (
        Math.abs(e.pageX - pageX) <= 1 &&
        Math.abs(e.pageY - pageY) <= 1 &&
        this.state.isEditAnnotation &&
        annotation
      ) {
        const parentOffset = panoViewerWrapper.offset();
        const mouseOffset = 5;

        const x = e.pageX - parentOffset.left - mouseOffset;
        const y = e.pageY - parentOffset.top - mouseOffset;

        let point0 = (2 * x - panoViewer._width) / panoViewer._width;
        let point1 = (2 * y - panoViewer._height) / panoViewer._height;

        var fov = panoViewer.getFov();
        var hfov = this.getHFov(fov);

        var rx = Math.tan(this.toRadian(hfov) / 2);
        var ry = Math.tan(this.toRadian(fov) / 2);

        let radYaw = -Math.atan(point0 * rx);
        let radPitch = -Math.atan(point1 * ry);

        let deltaYaw = this.toAngle(radYaw);
        let deltaPitch = this.toAngle(radPitch);

        var oyaw = panoViewer.getYaw();
        var opitch = panoViewer.getPitch();

        let yaw = deltaYaw + oyaw;
        let pitch = deltaPitch + opitch;

        annotation.yaw = yaw;
        annotation.pitch = pitch;

        this.setState({ annotation });
      }
    });
  }

  toRadian(deg: number) {
    return (deg * Math.PI) / 180;
  }

  toAngle(rad: number) {
    return (rad * 180) / Math.PI;
  }

  getHFov(fov: number) {
    if (this.state.panoViewerWrapper.length > 0) {
      var rect = this.state.panoViewerWrapper[0].getBoundingClientRect();
      var width = rect.width;
      var height = rect.height;
      return (Math.atan((width / height) * Math.tan(this.toRadian(fov) / 2)) / Math.PI) * 360;
    } else {
      return 0;
    }
  }

  renderAnnotation(a: PanoAnnotation) {
    const { annotation, panoViewer } = this.state;

    if (a && a.yaw && a.pitch && panoViewer) {
      var oyaw = panoViewer.getYaw();
      var opitch = panoViewer.getPitch();
      var yaw = a.yaw;
      var pitch = a.pitch;
      var deltaYaw = yaw - oyaw;
      var deltaPitch = pitch - opitch;

      if (deltaYaw < -180) {
        deltaYaw += 360;
      } else if (deltaYaw > 180) {
        deltaYaw -= 360;
      }

      if (Math.abs(deltaYaw) > 90) {
        return;
      }

      var radYaw = this.toRadian(deltaYaw);
      var radPitch = this.toRadian(deltaPitch);

      var fov = panoViewer.getFov();
      var hfov = this.getHFov(fov);

      var rx = Math.tan(this.toRadian(hfov) / 2);
      var ry = Math.tan(this.toRadian(fov) / 2);

      var point = [Math.tan(-radYaw) / rx, Math.tan(-radPitch) / ry];

      var left = panoViewer._width / 2 + (point[0] * panoViewer._width) / 2;
      var top = panoViewer._height / 2 + (point[1] * panoViewer._height) / 2;

      return (
        <div
          className={`panoview-annotation-pos ${annotation && annotation.id == a.id ? 'active' : ''}`}
          key={`annotation-${a.id}`}
          id={`annotation-${a.id}`}
          style={{
            transform: 'translate(' + left + 'px, ' + top + 'px)',
          }}
          onClick={() => this.handleAnnotationPreviewClick(a)}
        >
          {' '}
          <div className='panoview-annotation-pos-icon' />
        </div>
      );
    }
  }

  handleAnnotationPreviewClick(a: PanoAnnotation) {
    if (!this.state.isEditAnnotation) {
      $('.panoview-sidebar-outer').animate({
        scrollTop: $(`#annotation-${a.id}`).offset().top,
      });
      this.setState({ annotation: a, previewAnnotationModal: true });
    }
  }

  handleAnnotationPreviewCancel = () => {
    this.setState({ previewAnnotationModal: false });
  };

  handleAnnotationItemClick(annotation: PanoAnnotation) {
    this.setState({ annotation });
  }

  handleAnnotationItemEditClick(annotation: PanoAnnotation) {
    this.setState({ annotation, isEditAnnotation: true });
  }

  handleAnnotationItemDeleteClick(annotation: PanoAnnotation) {
    this.props.deletePanoAnnotation(annotation);
    this.setState({ annotation: null });
  }

  handleAnnotationNewSave = async (annotation: PanoAnnotation) => {
    const { session } = this.state;
    annotation.session = session.uuid;
    this.props.createPanoAnnotation(annotation);
    uploadingBox('Started creating annotation of ' + annotation.title, 'Creating, please wait....', annotation.title);
    this.setState({
      showAnnotationModal: false,
      isEditAnnotation: false,
      annotation: null,
    });
  };

  handleAnnotationNewCancel = () => {
    this.setState({
      showAnnotationModal: false,
      isEditAnnotation: false,
      annotation: null,
    });
  };

  handleAnnotationEditSave = async (annotation: PanoAnnotation) => {
    const pano_annotations = this.state.pano_annotations as PanoAnnotations;

    let index = pano_annotations.findIndex(a => a.id === annotation.id);
    pano_annotations[index] = annotation;

    this.props.updatePanoAnnotation(annotation);
    uploadingBox('Started updating of ' + annotation.title, 'Updating, please wait....', annotation.title);
    this.setState({
      isEditAnnotation: false,
      annotation: null,
      pano_annotations,
    });
  };

  handleAnnotationEditCancel = () => {
    this.setState({ isEditAnnotation: false, annotation: null });
  };

  newCenterHandler() {
    const { panoViewer } = this.state;

    if (panoViewer) {
      this.props.setPanoviewerCenter({
        session: this.state.session,
        panoviewer_pitch: panoViewer.getPitch(),
        panoviewer_yaw: panoViewer.getYaw(),
      });
    }
    uploadingBox('Started saving panoviewer center information', 'Updating, please wait....', 'panoviewer-center');
  }

  render() {
    const { isEditAnnotation, annotation, loading, pano_annotations, session } = this.state;
    const modalProps = {
      onCancel: this.handleAnnotationNewCancel.bind(this),
      onSave: this.handleAnnotationNewSave.bind(this),
      annotation: annotation,
      session: session,
    };

    const previewModalProps = {
      selected: false,
      annotation: annotation,
      onClick: null,
      onEditClick: null,
      onDeleteClick: null,
      isEditable: false,
    };

    return (
      <div className='panoview-annnotation'>
        <section className='panoview-sidebar'>
          <div className='panoview-sidebar-outer active'>
            <div className='panoview-sidebar-toolbar'>
              {!isEditAnnotation && (
                <Button type='primary' onClick={() => this.setState({ isEditAnnotation: true, annotation: {} })}>
                  Add Annotation
                </Button>
              )}
              {isEditAnnotation && annotation && !isDefined(annotation.id) && (
                <Button type='danger' onClick={() => this.setState({ isEditAnnotation: false, annotation: null })}>
                  Cancel
                </Button>
              )}
            </div>
            {pano_annotations &&
              pano_annotations.map((a: PanoAnnotation, index) => {
                const editProps = {
                  onCancel: this.handleAnnotationEditCancel.bind(this),
                  onSave: this.handleAnnotationEditSave.bind(this),
                  annotation,
                  session: session,
                };

                const previewProps = {
                  selected: annotation && annotation.id == a.id,
                  annotation: { ...a },
                  onClick: this.handleAnnotationItemClick.bind(this),
                  onEditClick: this.handleAnnotationItemEditClick.bind(this),
                  onDeleteClick: this.handleAnnotationItemDeleteClick.bind(this),
                  isEditable: true,
                };

                return (
                  <div className='panoview-annotation-list-item' key={index}>
                    {isEditAnnotation && annotation && annotation.id == a.id && (
                      <PanoviewAnnotationForm {...editProps}></PanoviewAnnotationForm>
                    )}
                    {(!isEditAnnotation || !annotation || annotation.id !== a.id) && (
                      <PanoviewAnnotationPreview {...previewProps}></PanoviewAnnotationPreview>
                    )}
                  </div>
                );
              })}
          </div>
        </section>
        <div className='panoview-main-view'>
          <Button
            className='view-back-button'
            type='primary'
            id='view-switch-button'
            onClick={() => {
              history.push('/session/' + this.props.session.uuid + '?view=panoviewer');
            }}
          >
            Back To Session
          </Button>
          <Button
            className='view-center-button'
            type='primary'
            id='view-center-button'
            onClick={() => this.newCenterHandler()}
            size='large'
          >
            New Center
          </Button>
          {this.props.user.active_site && this.props.user.active_site.use_spinviewer_main && (
            <Button
              className='view-switch-button'
              type='danger'
              id='view-switch-button'
              onClick={() => {
                history.push('/spinviewer-main-editor/' + this.props.session.uuid);
              }}
            >
              Go To Exterior
            </Button>
          )}
          <Loading isLoading={loading} />
          <div className='panoview-360view-wrapper'>
            <div className='panoview-info'>
              <span className='panoview-edit-message'>
                {isEditAnnotation && annotation && 'Please click on the viewer to add annotation.'}
              </span>
            </div>
            <div id='panoview-360view'>
              {isEditAnnotation && annotation && annotation.yaw && annotation.pitch && !isDefined(annotation.id) && (
                <Button
                  className='annotation-frame-done'
                  type='primary'
                  id='annotation-frame-done'
                  onClick={() => {
                    this.setState({ showAnnotationModal: true });
                  }}
                >
                  Done
                </Button>
              )}
              {this.renderAnnotation(annotation)}
              {!isEditAnnotation && pano_annotations.map(a => this.renderAnnotation(a))}
            </div>
          </div>
          <Modal
            className='annotation-new-modal'
            destroyOnClose={true}
            title='Add Annotation'
            visible={this.state.showAnnotationModal}
            onCancel={this.handleAnnotationNewCancel.bind(this)}
            footer={false}
          >
            <PanoviewAnnotationForm {...modalProps}></PanoviewAnnotationForm>
          </Modal>
          <Modal
            className='annotation-preview-modal'
            destroyOnClose={true}
            title='Annotation'
            visible={this.state.previewAnnotationModal}
            onCancel={this.handleAnnotationPreviewCancel.bind(this)}
            footer={false}
            width={400}
          >
            <PanoviewAnnotationPreview {...previewModalProps}></PanoviewAnnotationPreview>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ sessions, annotations, user }: ApplicationState) => ({
  session: sessions.currentSession,
  pano_annotations: annotations.pano_annotations,
  user: user,
});

const mapDispatchToProps = {
  getSession: Actions.getSession,
  setPanoviewerCenter: Actions.setPanoviewerCenter,
  getPanoAnnotations: AnnotationActions.getPanoAnnotations,
  createPanoAnnotation: AnnotationActions.createPanoAnnoation,
  updatePanoAnnotation: AnnotationActions.updatePanoAnnotation,
  deletePanoAnnotation: AnnotationActions.deletePanoAnnotation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanoviewAnnotationPage as any);
