import { Branding } from '../../model/Branding';
import { fabric } from 'fabric';

export class CachedBrandingImage {
  private static _images: Map<string, any> = new Map<string, any>();

  public static async updateImage(branding: Branding): Promise<any> {
    return new Promise((resolve, rej) => {
      if (branding.thumbnail) {
        let image: any = CachedBrandingImage._images[branding.id];

        if (!image) {
          fabric.Image.fromURL(
            branding.thumbnail,
            function(img) {
              CachedBrandingImage._images[branding.id] = img;
              resolve(img);
            },
            {
              selectable: false,
              hoverCursor: 'default',
              // crossOrigin: 'anonymous',
            }
          );
        } else {
          resolve(fabric.util.object.clone(image));
        }
      }
    });
  }
}
