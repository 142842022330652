import { call, takeLatest, put } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/AnnotationReducer";
import { Actions as SessionActions } from "../reducers/SessionReducer";
import { httpDelete, httpGet, multipartFormPatchSubmit, multipartFormSubmit, } from "../client/http";
import { ActionWithPayload } from "../reducers/actionHelper";
import { AnnotationListRequest, PanoAnnotation, SpinAnnotation, SpinAnnotations } from "../model/Annotation";
import { message, notification } from "antd";

const annotationEndpoint = 'api/annotation';


export function* getAnnotations(action: ActionWithPayload<ActionTypes.GET_ANNOTATIONS, AnnotationListRequest>) {
    try {
        const endpoint = `${annotationEndpoint}?session=${action.payload.session.uuid}`;
        const response = yield call(httpGet, endpoint);
        yield put(Actions.getAnnotationsSuccess(response));
    } catch (e) {
        yield put(Actions.getAnnotationsFailure());
    }
}

const SPIN_ANNOTATION_URL = 'api/spin-annotation/';

export function* getSpinAnnotations(action: ActionWithPayload<ActionTypes.GET_SPIN_ANNOTATIONS, any>) {
    try {
        const endpoint = `${SPIN_ANNOTATION_URL}?uuid=${action.payload}`;
        let response = yield call(httpGet, endpoint);
        response.map(res => {
            res.annotationPos = convertAnnoationPosArrayToObject(res.annotationPoses);
            delete res.annotationPoses;
        });
        yield put(Actions.getSpinAnnotationsSuccess(response));
    } catch (e) {
        yield put(Actions.getSpinAnnotationsFailure());
    }
}

export function* createSpinAnnoation(action: ActionWithPayload<ActionTypes.CREATE_SPIN_ANNOTATION, SpinAnnotation>) {
    try {
        const data = new FormData();
        data.append('title', action.payload.title);
        data.append('session', action.payload.session);
        data.append('description', action.payload.description);

        if (action.payload.imageFile) {
            data.append('imageFile', action.payload.imageFile.originFileObj, action.payload.imageFile.name);
        }

        if (action.payload.image) {
            data.append('image', action.payload.image.id);
        }

        data.append('annotationPoses', JSON.stringify(convertAnnotationPosObjectToArray(action.payload.annotationPos)));
        yield call(multipartFormSubmit, `${SPIN_ANNOTATION_URL}`, data);
        notification.close(action.payload.title);
        yield put(Actions.createSpinAnnoationSuccess(action.payload.session));

        yield put(SessionActions.getSession({
            sessionId: action.payload.session,
        }));

        message.success('Successfully created annotation.');
    } catch (e) {
        console.log(e);
        notification.close(action.payload.title);
        message.error('Error creating annotation.');
        yield put(Actions.createSpinAnnoationFailure());
    }
}

export function* updateSpinAnnotation(action: ActionWithPayload<ActionTypes.UPDATE_SPIN_ANNOTATION, SpinAnnotation>) {
    try {
        const data = new FormData();
        data.append('title', action.payload.title);
        data.append('description', action.payload.description);

        if (action.payload.imageFile) {
            data.append('imageFile', action.payload.imageFile.originFileObj, action.payload.imageFile.name);
        }

        if (action.payload.image) {
            data.append('image', action.payload.image.id);
        }

        data.append('annotationPoses', JSON.stringify(convertAnnotationPosObjectToArray(action.payload.annotationPos)));
        yield call(multipartFormPatchSubmit, `${SPIN_ANNOTATION_URL}${action.payload.id}`, data);
        notification.close(action.payload.title);
        yield put(Actions.updateSpinAnnotationSuccess(action.payload.session));
        message.success('Successfully update annotation.');
    } catch (e) {
        console.log(e);
        notification.close(action.payload.title);
        message.error('Error update annotation.');
        yield put(Actions.updateSpinAnnotationFailure());
    }
}

export function* deleteSpinAnnotation(action: ActionWithPayload<ActionTypes.DELETE_SPIN_ANNOTATION, SpinAnnotation>) {
    try {
        yield call(httpDelete, `${SPIN_ANNOTATION_URL}${action.payload.id}`);
        yield put(Actions.deleteSpinAnnotationSuccess(action.payload.session));
        yield put(SessionActions.getSession({
            sessionId: action.payload.session,
        }));
        message.success('Successfully deleted annotation.');
    } catch (e) {
        message.error('Error deleting annotation. Please try again.');
        yield put(Actions.deleteSpinAnnotationFailure());
    }
}

const PANO_ANNOTATION_URL = 'api/pano-annotation/';

export function* getPanoAnnotations(action: ActionWithPayload<ActionTypes.GET_PANO_ANNOTATIONS, any>) {
    try {
        const endpoint = `${PANO_ANNOTATION_URL}?uuid=${action.payload}`;
        let response = yield call(httpGet, endpoint);
        yield put(Actions.getPanoAnnotationsSuccess(response));
    } catch (e) {
        yield put(Actions.getPanoAnnotationsFailure());
    }
}

export function* createPanoAnnoation(action: ActionWithPayload<ActionTypes.CREATE_PANO_ANNOTATION, PanoAnnotation>) {
    try {
        const data = new FormData();
        data.append('title', action.payload.title);
        data.append('session', action.payload.session);
        data.append('description', action.payload.description);

        if (action.payload.imageFile) {
            data.append('imageFile', action.payload.imageFile.originFileObj, action.payload.imageFile.name);
        }

        if (action.payload.image) {
            data.append('image', action.payload.image.id);
        }

        data.append('yaw', action.payload.yaw.toString());
        data.append('pitch', action.payload.pitch.toString());
        yield call(multipartFormSubmit, `${PANO_ANNOTATION_URL}`, data);
        notification.close(action.payload.title);
        yield put(Actions.createPanoAnnoationSuccess(action.payload.session));
        yield put(SessionActions.getSession({
            sessionId: action.payload.session,
        }));
        message.success('Successfully created annotation.');
    } catch (e) {
        console.log(e);
        notification.close(action.payload.title);
        message.error('Error creating annotation.');
        yield put(Actions.createPanoAnnoationFailure());
    }
}

export function* updatePanoAnnotation(action: ActionWithPayload<ActionTypes.CREATE_PANO_ANNOTATION, PanoAnnotation>) {
    try {
        const data = new FormData();
        data.append('title', action.payload.title);
        data.append('description', action.payload.description);

        if (action.payload.imageFile) {
            data.append('imageFile', action.payload.imageFile.originFileObj, action.payload.imageFile.name);
        }

        if (action.payload.image) {
            data.append('image', action.payload.image.id);
        }

        data.append('yaw', action.payload.yaw.toString());
        data.append('pitch', action.payload.pitch.toString());

        yield call(multipartFormPatchSubmit, `${PANO_ANNOTATION_URL}${action.payload.id}`, data);
        notification.close(action.payload.title);
        yield put(Actions.updatePanoAnnotationSuccess(action.payload.session));
        message.success('Successfully update annotation.');
    } catch (e) {
        console.log(e);
        notification.close(action.payload.title);
        message.error('Error update annotation.');
        yield put(Actions.updatePanoAnnotationFailure());
    }
}

export function* deletePanoAnnotation(action: ActionWithPayload<ActionTypes.DELETE_PANO_ANNOTATION, PanoAnnotation>) {
    try {
        yield call(httpDelete, `${PANO_ANNOTATION_URL}${action.payload.id}`);
        yield put(Actions.deletePanoAnnotationSuccess(action.payload.session));
        yield put(SessionActions.getSession({
            sessionId: action.payload.session,
        }));
        message.success('Successfully deleted annotation.');
    } catch (e) {
        message.error('Error deleting annotation. Please try again.');
        yield put(Actions.deletePanoAnnotationFailure());
    }
}

export function* annotationSaga() {
    yield takeLatest(ActionTypes.GET_ANNOTATIONS, getAnnotations);

    yield takeLatest(ActionTypes.GET_SPIN_ANNOTATIONS, getSpinAnnotations);
    yield takeLatest(ActionTypes.CREATE_SPIN_ANNOTATION, createSpinAnnoation);
    yield takeLatest(ActionTypes.CREATE_SPIN_ANNOTATION_SUCCESS, getSpinAnnotations);
    yield takeLatest(ActionTypes.UPDATE_SPIN_ANNOTATION, updateSpinAnnotation);
    yield takeLatest(ActionTypes.UPDATE_SPIN_ANNOTATION_SUCCESS, getSpinAnnotations);
    yield takeLatest(ActionTypes.DELETE_SPIN_ANNOTATION, deleteSpinAnnotation);
    yield takeLatest(ActionTypes.DELETE_SPIN_ANNOTATION_SUCCESS, getSpinAnnotations);

    yield takeLatest(ActionTypes.GET_PANO_ANNOTATIONS, getPanoAnnotations);
    yield takeLatest(ActionTypes.CREATE_PANO_ANNOTATION, createPanoAnnoation);
    yield takeLatest(ActionTypes.CREATE_PANO_ANNOTATION_SUCCESS, getPanoAnnotations);
    yield takeLatest(ActionTypes.UPDATE_PANO_ANNOTATION, updatePanoAnnotation);
    yield takeLatest(ActionTypes.UPDATE_PANO_ANNOTATION_SUCCESS, getPanoAnnotations);
    yield takeLatest(ActionTypes.DELETE_PANO_ANNOTATION, deletePanoAnnotation);
    yield takeLatest(ActionTypes.DELETE_PANO_ANNOTATION_SUCCESS, getPanoAnnotations);
}



function convertAnnotationPosObjectToArray(annotationPos: any) {
    let result = [];

    for (const key in annotationPos) {
        result.push(annotationPos[key]);
    }

    return result;
}

function convertAnnoationPosArrayToObject(annotationPoses: []) {
    let result = {};

    for (const i in annotationPoses) {
        const annotationPos = annotationPoses[i];
        result[annotationPos['frame_index']] = annotationPos;
    }

    return result;
}