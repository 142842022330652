import { Photo, Session } from './Session';

export const ANNOTAION_ICONS = [
  {
    value: 'damage',
    text: 'Damage',
  },
  {
    value: 'feature',
    text: 'Feature',
  },
  {
    value: 'safety_feature',
    text: 'Safety Feature',
  },
];

export interface Annotation {
  id?: string;
  title?: string;
  image?: Photo;
  imageFile?: any;
  description?: string;
  is_template?: boolean;
  icon?: string;
  session?: string;
}

export interface AnnotationPos {
  id?: string;
  annotation: Annotation;
  photo?: Photo;
  position_x?: number;
  position_y?: number;
}

export interface NewAnnotationPosRequest {
  annotation: Annotation;
  photo?: Photo;
  position_x?: number;
  position_y?: number;
}

export interface UpdateAnnotationPosRequest {
  id?: string;
  photo?: Photo;
  annotation: Annotation;
}

export interface AnnotationPosListRequest {
  photo: Photo;
}

export interface AnnotationListRequest {
  session: Session;
}

export type AnnotationPoses = AnnotationPos[];
export type Annotations = Annotation[];

export interface SpinAnnotation extends Annotation {
  annotationPos?: {
    [index: number]: SpinAnnotationPos;
  };
}

export interface SpinAnnotationPos {
  id?: string;
  frame_index?: number;
  position_x?: number;
  position_y?: number;
}

export interface PanoAnnotation extends Annotation {
  yaw?: number;
  pitch?: number;
}

export type SpinAnnotations = SpinAnnotation[];

export type PanoAnnotations = PanoAnnotation[];
