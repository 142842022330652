import { LOGIN } from '../router/Routes';
import { isEmpty } from 'lodash';

let baseUrl = '';

if (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'
) {
  console.log('Development Detected');
  baseUrl = 'http://' + window.location.hostname + ':8000';
} else {
  baseUrl = window.location.origin;
  console.log('Production Detected: ' + baseUrl);
}

const TOKEN_KEY = '_t';

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const commonHeaders = {
  'Content-Type': 'application/json',
};

function generateAuthenticatedHeaders() {
  return {
    Authorization: `Token ${getToken()}`,
    'X-CSRFToken': getCookie('csrftoken'),
    ...commonHeaders,
  };
}

function getHeaders(auth: boolean, isJsonRequest = true) {
  const headers = auth ? generateAuthenticatedHeaders() : commonHeaders;

  if (!isJsonRequest) {
    delete headers['Content-Type'];
  }

  return headers;
}

function logout() {
  console.log('logging out');
  localStorage.removeItem(TOKEN_KEY);
  console.log(
    'logging out, redirecting to: ' +
      window.location.protocol +
      window.location.host +
      LOGIN
  );
  if (
    window.location.href !==
    window.location.protocol + window.location.host + LOGIN
  ) {
    window.location.href =
      window.location.protocol + window.location.host + LOGIN;
  }
}

export const BASE_URL = baseUrl;

export function getToken() {
  const payload = localStorage.getItem(TOKEN_KEY);
  if (payload && !isEmpty(payload)) {
    return atob(payload).split('_')[1];
  }
}

export function httpGet(
  path: string,
  auth: boolean = true,
  altRequest: boolean = false
) {
  const endpoint = `${baseUrl}/${path}`;
  if (altRequest) {
    return makeRequestPlain(endpoint, {
      method: 'GET',
      headers: getHeaders(auth),
    });
  }
  return makeRequest(endpoint, { method: 'GET', headers: getHeaders(auth) });
}

export function httpPost(
  path: string,
  payload: any,
  auth: boolean = true,
  include400PlusError = false
) {
  const endpoint = `${baseUrl}/${path}`;
  return makeRequest(
    endpoint,
    {
      method: 'POST',
      headers: getHeaders(auth),
      body: JSON.stringify(payload),
    },
    include400PlusError
  );
}

export function httpPut(path: string, payload: any, auth: boolean = true) {
  const endpoint = `${baseUrl}/${path}`;
  return makeRequest(endpoint, {
    method: 'PUT',
    headers: getHeaders(auth),
    body: JSON.stringify(payload),
  });
}

export function httpDelete(path: string, auth: boolean = true) {
  const endpoint = `${baseUrl}/${path}`;
  return makeRequest(endpoint, { method: 'DELETE', headers: getHeaders(auth) });
}

export function multipartFormSubmit(
  path: string,
  payload: FormData,
  auth: boolean = true
) {
  const endpoint = `${baseUrl}/${path}`;
  return makeRequest(endpoint, {
    method: 'POST',
    headers: getHeaders(auth, false),
    body: payload,
  });
}

export function multipartFormPatchSubmit(
  path: string,
  payload: FormData,
  auth: boolean = true
) {
  const endpoint = `${baseUrl}/${path}`;
  return makeRequest(endpoint, {
    method: 'PATCH',
    headers: getHeaders(auth, false),
    body: payload,
  });
}

export function httpPatch(
  path: string,
  payload: any,
  auth: boolean = true,
  plain: boolean = false
) {
  const endpoint = `${baseUrl}/${path}`;
  if (!plain) {
    return makeRequest(endpoint, {
      method: 'PATCH',
      headers: getHeaders(auth),
      body: JSON.stringify(payload),
    });
  }
  return makeRequestPlain(endpoint, {
    method: 'PATCH',
    headers: getHeaders(auth),
    body: JSON.stringify(payload),
  });
}

function makeRequestPlain(endpoint: string, properties: any) {
  return fetch(endpoint, properties).then((res) => {
    if (res.status === 403) {
      logout();
    }
    return res;
  });
}

function makeRequest(
  endpoint: string,
  properties: any,
  include400PlusError = false
) {
  return fetch(endpoint, properties).then((res) => {
    if (res.status < 400) {
      if (res.status === 204) return {};
      return res.json();
    }
    if (res.status === 401) {
      logout();
    } else {
      console.log('FAILED REQUEST', res);
      if (include400PlusError) {
        return res.json();
      }
      // throw new Error(`Request failed with status ${res.status}`);
    }
  });
}
