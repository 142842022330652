import * as React from 'react';
import { Modal, Form, Icon, Upload, message } from 'antd';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/SessionReducer';
import { connect } from 'react-redux';
import { PhotoUploadRequest, Session } from '../../model/Session';

//Creating the upload section from Antd
const { Dragger } = Upload;

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

export interface UploadPhotoFormProps {
  photoFormSubmit: (photo: PhotoUploadRequest) => void;
  closeModal: () => void;
  photoUploadFormOpen: boolean;
  form: any;
  currentSession: Session;
}

export interface UploadPhotoFormState {
  fileList: any | null;
}

class UploadPhotoForm extends React.Component<UploadPhotoFormProps, UploadPhotoFormState> {
  readonly state = {
    fileList: null,
  };
  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      const allValues = {
        ...values,
        photo: this.state.fileList,
        session_uuid: this.props.currentSession.uuid,
      };
      if (!err) {
        console.log('No error from form submit', allValues);
        this.props.photoFormSubmit(allValues);
        this.props.form.resetFields();
        this.props.closeModal();
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleModalCancel = () => {
    this.props.closeModal();
  };

  onChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        fileList: info.fileList,
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  render() {
    const draggerProps = {
      multiple: false,
      name: 'upload',
      onChange: this.onChange,
      customRequest: dummyRequest,
    };

    return (
      <div>
        <Modal
          title='Upload Photos to the Session'
          visible={this.props.photoUploadFormOpen}
          onOk={this.handleModalOk}
          onCancel={this.handleModalCancel}
        >
          <Form>
            <Form.Item>
              <Dragger {...draggerProps}>
                <p className='ant-upload-drag-icon'>
                  <Icon type='inbox' />
                </p>
                <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                <p className='ant-upload-hint'>Upload a photo or photos to the session</p>
              </Dragger>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const form = Form.create({ name: 'UploadPhotoForm' })(UploadPhotoForm);

const mapStateToProps = ({ sessions }: ApplicationState) => ({
  currentSession: sessions.currentSession,
  photoUploadFormOpen: sessions.photoUploadFormOpen,
});

const mapDispatchToProps = {
  closeModal: Actions.closePhotoUploadForm,
  photoFormSubmit: Actions.photoUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
