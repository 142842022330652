import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';

import { Form } from 'antd';
import '@/styles/editor-annotation-sidebar.scss';
import { Actions as AnnotationPosActions } from '../../../reducers/AnnotationPosReducer';
import { AnnotationPos, AnnotationPoses } from '../../../model/Annotation';
import AnnotationPreview from './AnnotationPreview';
import EditAnnotationForm from './EditAnnotationForm';

interface Props {
  annotationPoses: AnnotationPoses;
  editAnnotationPos: AnnotationPos;
  setEditAnnotationPos: (annotationPos: AnnotationPos) => void;
  deleteAnnotationPos: (annotationPos: AnnotationPos) => void;
}

class AnnnotationSidebar extends React.PureComponent<Props, {}> {
  state = {
    file: null,
    isEditAnnotationPos: false,
  };

  handleAnnotationPosEditSave = async (annotationPos: AnnotationPos) => {
    this.setState({
      isEditAnnotationPos: false,
    });
  };

  handleAnnotationPosEditCancel() {
    this.setState({ isEditAnnotationPos: false, annotationPos: null });
  }
  handleAnnotationPosItemClick(annotationPos: AnnotationPos) {
    this.props.setEditAnnotationPos(annotationPos);
  }

  handleAnnotationPosItemEditClick(annotationPos: AnnotationPos) {
    this.setState({ isEditAnnotationPos: true });
    this.props.setEditAnnotationPos(annotationPos);
  }

  handleAnnotationPosItemDeleteClick(annotationPos: AnnotationPos) {
    this.props.deleteAnnotationPos(annotationPos);
  }

  render() {
    const { isEditAnnotationPos } = this.state;

    return (
      <div className='annotation-sidebar'>
        {this.props.annotationPoses.map((a: AnnotationPos, index) => {
          const editProps = {
            annotationPos: { ...this.props.editAnnotationPos },
            onCancel: this.handleAnnotationPosEditCancel.bind(this),
          };

          const previewProps = {
            selected: this.props.editAnnotationPos && this.props.editAnnotationPos.id == a.id,
            annotationPos: { ...a },
            onClick: this.handleAnnotationPosItemClick.bind(this),
            onEditClick: this.handleAnnotationPosItemEditClick.bind(this),
            onDeleteClick: this.handleAnnotationPosItemDeleteClick.bind(this),
          };

          return (
            <div className='photos-annotation-list-item' key={index}>
              {isEditAnnotationPos && this.props.editAnnotationPos && this.props.editAnnotationPos.id === a.id && (
                <EditAnnotationForm {...editProps}></EditAnnotationForm>
              )}
              {(!isEditAnnotationPos || !this.props.editAnnotationPos || this.props.editAnnotationPos.id !== a.id) && (
                <AnnotationPreview {...previewProps}></AnnotationPreview>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export const form = Form.create({ name: 'AnnotationUploadForm' })(AnnnotationSidebar);

const mapDispatchToProps = {
  setEditAnnotationPos: AnnotationPosActions.setEditAnnotationPos,
  deleteAnnotationPos: AnnotationPosActions.deleteAnnotationPos,
};

const mapStateToProps = ({ annotationPoses }: ApplicationState) => ({
  annotationPoses: annotationPoses.annotationPoses,
  editAnnotationPos: annotationPoses.editAnnotationPos,
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
