import * as React from 'react';
import { AutoComplete, Icon, Input, Pagination } from 'antd';

interface Props {
  sessionCount: number;
  sessionCompleteCount: number;
  paginationCurrent: number;
  paginationTotal: number;
  paginationPageSize: number;
  paginationOnChange: (page: number) => void;
  searchSessions: (query: string) => void;
}

interface State {
  searchValue: string;
  searchQueryTimeOut: number;
}

export class SearchVins extends React.Component<Props, State> {
  state: State = {
    searchValue: '',
    searchQueryTimeOut: null,
  };

  handleSearch = query => {
    if (this.state.searchQueryTimeOut) {
      window.clearTimeout(this.state.searchQueryTimeOut);
    }

    const searchQueryTimeOut = window.setTimeout(() => {
      this.setState({
        searchValue: query,
      });

      this.props.searchSessions(query);
    }, 100);

    this.setState({ searchQueryTimeOut });
  };

  render() {
    const searchPrefix = <Icon type='search' />;
    return (
      <div className='search-header' id='search'>
        <h4>Search for a vehicle</h4>
        <div className='global-search-wrapper'>
          <Input
            addonBefore={searchPrefix}
            value={this.state.searchValue}
            onChange={e => this.handleSearch(e.target.value)}
          />
        </div>
        <div className='search-vins-footer'>
          <div className='stats'>
            <h5 className='muted-text'>Session count: {this.props.sessionCount}</h5>
            <h5 className='muted-text'>Completed session count: {this.props.sessionCompleteCount}</h5>
          </div>
          {this.props.paginationPageSize && (
            <Pagination
              className={'session-pagination'}
              defaultCurrent={1}
              current={this.props.paginationCurrent}
              total={this.props.paginationTotal}
              defaultPageSize={this.props.paginationPageSize}
              onChange={(page, pageSize) => this.props.paginationOnChange(page)}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              hideOnSinglePage={true}
            />
          )}
        </div>
      </div>
    );
  }
}
