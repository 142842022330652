import * as React from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import Login from '../components/auth/Login';
import CategorizeVin from '../components/categorize/CategorizeVin';
import Categories from '../components/classifications/Classifications';
import Session from '../components/session/Session';
import FinalizeSession from '../components/finalize/FinalizeSession';
import Editor from '../components/editor/Editor';
import Sites from '../components/sites/sites';
import Admin from '../components/admin/Admin';
import { Route, Switch, Redirect } from 'react-router';
import SpinviewAnnotation from '../components/editor-360/spinview/SpinviewAnnotation';
import PanoviewAnnotation from '../components/editor-360/panoview/PanoviewAnnotation';
import MetricPage from '../components/metrics/MetricPage';
import ForgetPassword from '../components/auth/ForgetPassword';

export const LOGIN = '/login';
export const FORGET_PASSWORD = '/forget-password';
export const DASHBOARD = '/dashboard';
export const CLASSIFY_VIN = '/classify/{vin}';
export const CLASSIFICATIONS = '/classifications';
export const SESSION = '/session/:sessionId';
export const FINALIZE = '/finalize/:sessionId';
export const PHOTO_EDITOR = '/editor/:sessionId/:photoId';
export const SPINVIEWER_EDITOR = '/spinviewer-editor/:type/:sessionId';
export const PANOVIEWER_EDITOR = '/panoviewer-editor/:sessionId';
export const SITES = '/sites';
export const ADMIN = '/admin';
export const METRIC = '/metric';

export enum PageType {
  LOGIN,
  DASHBOARD,
  SESSION,
  CLASSIFICATIONS,
}

export const getRoutes = () => {
  const renderAuthenticatedComponent = (Component, props): JSX.Element =>
    !!localStorage.getItem('_t') ? <Component {...props} /> : <Redirect to={LOGIN} />;
  return (
    <Switch>
      <Route path={LOGIN} component={Login} />
      <Route path={FORGET_PASSWORD} component={ForgetPassword} />
      <Route path={DASHBOARD} render={props => renderAuthenticatedComponent(Dashboard, props)} />
      <Route path={CLASSIFY_VIN} render={props => renderAuthenticatedComponent(CategorizeVin, props)} />
      <Route path={CLASSIFICATIONS} render={props => renderAuthenticatedComponent(Categories, props)} />
      <Route path={SESSION} render={props => renderAuthenticatedComponent(Session, props)} />
      <Route path={FINALIZE} render={props => renderAuthenticatedComponent(FinalizeSession, props)} />
      <Route path={PHOTO_EDITOR} render={props => renderAuthenticatedComponent(Editor, props)} />
      <Route path={SPINVIEWER_EDITOR} render={props => renderAuthenticatedComponent(SpinviewAnnotation, props)} />
      <Route path={PANOVIEWER_EDITOR} render={props => renderAuthenticatedComponent(PanoviewAnnotation, props)} />
      <Route path={SITES} render={props => renderAuthenticatedComponent(Sites, props)} />
      <Route path={ADMIN} render={props => renderAuthenticatedComponent(Admin, props)} />
      <Route path={METRIC} render={props => renderAuthenticatedComponent(MetricPage, props)} />
      <Route render={props => renderAuthenticatedComponent(Dashboard, props)} />
    </Switch>
  );
};
