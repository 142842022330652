import { BrandingState } from "../state/ApplicationState";
import { createAction } from "./actionHelper";
import { Brandings, NewBrandingRequest, UpdateBrandingRequest } from "../model/Branding";


export const INITIAL_STATE_BRANDINGS = {
    isLoading: false,
    isError: false,
    errorMessage: '',
    brandings: [],
};

export enum ActionTypes {
    GET_BRANDINGS = 'GET_BRANDINGS',
    GET_BRANDINGS_SUCCESS = 'GET_BRANDINGS_SUCCESS',
    GET_BRANDINGS_FAILURE = 'GET_BRANDINGS_FAILURE',
    CREATE_BRANDING = 'CREATE_BRANDING',
    CREATE_BRANDING_SUCCESS = 'CREATE_BRANDING_SUCCESS',
    CREATE_BRANDING_FAILURE = 'CREATE_BRANDING_FAILURE',
    UPDATE_BRANDINGS = 'UPDATE_BRANDINGS',
}

export function brandingReducer(
    state: BrandingState = INITIAL_STATE_BRANDINGS,
    action: any,
) {
    switch (action.type) {
        case ActionTypes.GET_BRANDINGS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: '',
            };
        case ActionTypes.GET_BRANDINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                brandings: action.payload,
            };
        case ActionTypes.GET_BRANDINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: 'Cannot retrieve branding list'
            };
        default:
            return state;
    }
}

export const Actions = {
    getBrandings: () => createAction(ActionTypes.GET_BRANDINGS),
    updateBrandings: (brandings: UpdateBrandingRequest) => createAction(ActionTypes.UPDATE_BRANDINGS, brandings),
    getBrandingsSuccess: (brandings: Brandings) => createAction(ActionTypes.GET_BRANDINGS_SUCCESS, brandings),
    getBrandingsFailure: () => createAction(ActionTypes.GET_BRANDINGS_FAILURE),
    createBranding: (branding: NewBrandingRequest) => createAction(ActionTypes.CREATE_BRANDING, branding),
    createBrandingSuccess: () => createAction(ActionTypes.CREATE_BRANDING_SUCCESS),
    createBrandingFailure: () => createAction(ActionTypes.CREATE_BRANDING_FAILURE),
};