import { AnnotationPosState } from '../state/ApplicationState';
import { createAction } from './actionHelper';
import {
  AnnotationPoses,
  NewAnnotationPosRequest,
  AnnotationPosListRequest,
  UpdateAnnotationPosRequest,
  AnnotationPos,
} from '../model/Annotation';

export const INITIAL_STATE_ANNOTATIONPOSES = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  annotationPoses: [],
  editAnnotationPos: null,
};

export enum ActionTypes {
  GET_ANNOTATIONPOSES = 'GET_ANNOTATIONPOSES',
  GET_ANNOTATIONPOSES_SUCCESS = 'GET_ANNOTATIONPOSES_SUCCESS',
  GET_ANNOTATIONPOSES_FAILURE = 'GET_ANNOTATIONPOSES_FAILURE',
  CREATE_ANNOTATIONPOS = 'CREATE_ANNOTATIONPOS',
  CREATE_ANNOTATIONPOS_SUCCESS = 'CREATE_ANNOTATIONPOS_SUCCESS',
  CREATE_ANNOTATIONPOS_FAILURE = 'CREATE_ANNOTATIONPOS_FAILURE',
  UPDATE_ANNOTATIONPOS = 'UPDATE_ANNOTATIONPOS',
  UPDATE_ANNOTATIONPOS_SUCCESS = 'UPDATE_ANNOTATIONPOS_SUCCESS',
  UPDATE_ANNOTATIONPOS_FAILURE = 'UPDATE_ANNOTATIONPOS_FAILURE',
  DELETE_ANNOTATIONPOS = 'DELETE_ANNOTATIONPOS',
  DELETE_ANNOTATIONPOS_SUCCESS = 'DELETE_ANNOTATIONPOS_SUCCESS',
  DELETE_ANNOTATIONPOS_FAILURE = 'DELETE_ANNOTATIONPOS_FAILURE',
  SET_EDIT_ANNOTATIONPOS = 'SET_EDIT_ANNOTATIONPOS',
}

export function annotationPosReducer(
  state: AnnotationPosState = INITIAL_STATE_ANNOTATIONPOSES,
  action: any
) {
  switch (action.type) {
    case ActionTypes.GET_ANNOTATIONPOSES:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };
    case ActionTypes.GET_ANNOTATIONPOSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        annotationPoses: action.payload,
      };
    case ActionTypes.GET_ANNOTATIONPOSES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Cannot retrieve annotationPos list',
      };
    case ActionTypes.SET_EDIT_ANNOTATIONPOS:
      return {
        ...state,
        editAnnotationPos: action.payload,
      };
    default:
      return state;
  }
}

export const Actions = {
  getAnnotationPoses: (request: AnnotationPosListRequest) =>
    createAction(ActionTypes.GET_ANNOTATIONPOSES, request),
  getAnnotationPosesSuccess: (annotationPoses: AnnotationPoses) =>
    createAction(ActionTypes.GET_ANNOTATIONPOSES_SUCCESS, annotationPoses),
  getAnnotationPossFailure: () =>
    createAction(ActionTypes.GET_ANNOTATIONPOSES_FAILURE),
  createAnnotationPos: (annotationPos: NewAnnotationPosRequest) =>
    createAction(ActionTypes.CREATE_ANNOTATIONPOS, annotationPos),
  createAnnotationPosSuccess: (request: AnnotationPosListRequest) =>
    createAction(ActionTypes.CREATE_ANNOTATIONPOS_SUCCESS, request),
  createAnnotationPosFailure: () =>
    createAction(ActionTypes.CREATE_ANNOTATIONPOS_FAILURE),
  updateAnnotationPos: (annotationPos: UpdateAnnotationPosRequest) =>
    createAction(ActionTypes.UPDATE_ANNOTATIONPOS, annotationPos),
  updateAnnotationPosSuccess: (request: AnnotationPosListRequest) =>
    createAction(ActionTypes.UPDATE_ANNOTATIONPOS_SUCCESS, request),
  updateAnnotationPosFailure: () =>
    createAction(ActionTypes.UPDATE_ANNOTATIONPOS_FAILURE),
  deleteAnnotationPos: (annotationPos: AnnotationPos) =>
    createAction(ActionTypes.DELETE_ANNOTATIONPOS, annotationPos),
  deleteAnnotationPosSuccess: (request: AnnotationPosListRequest) =>
    createAction(ActionTypes.DELETE_ANNOTATIONPOS_SUCCESS, request),
  deleteAnnotationPosFailure: () =>
    createAction(ActionTypes.DELETE_ANNOTATIONPOS_FAILURE),
  setEditAnnotationPos: (annotationPos: AnnotationPos) =>
    createAction(ActionTypes.SET_EDIT_ANNOTATIONPOS, annotationPos),
};
