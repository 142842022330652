import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { GAMMA_VALUES, GammaObject } from '../../../reducers/EditorReducer';

import '@/styles/editor-filtering-sidebar.css';
import FilterPreviewItem from './FilterPreviewItem';

interface Props {}

class FilteringSidebar extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className='filtering-sidebar'>
        <h2>Select Image Preview</h2>
        {GAMMA_VALUES.map((value: GammaObject, index) => (
          <FilterPreviewItem gammaObject={value} key={index}></FilterPreviewItem>
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ editor }: ApplicationState) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FilteringSidebar as any);
