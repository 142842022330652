import * as React from 'react';
import { connect } from 'react-redux';
import { EditorMode, Actions } from '../../../reducers/EditorReducer';
import { ApplicationState } from '../../../state/ApplicationState';
import { Button } from 'antd';

interface Props {
  mode: EditorMode;
  aspectRatio: string;
  setMode: (mode: EditorMode) => void;
  canvas: any;
}

class CroppingFooter extends React.PureComponent<Props, {}> {
  onCropClick() {
    if (this.props.canvas && this.props.canvas.cropImage) {
      this.props.canvas.cropImage();
    }
  }

  render() {
    return (
      <>
        <div className='editor-footer-left'>
          <p className='aspect-ratio'>Aspect ratio: {this.props.aspectRatio}</p>
        </div>
        <div className='editor-footer-right'>
          <Button type='primary' size='large' onClick={this.onCropClick.bind(this)}>
            Crop
          </Button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setMode: Actions.setMode,
};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  mode: editor.mode,
  aspectRatio: editor.aspectRatio,
  canvas: editor.canvas,
});

export default connect(mapStateToProps, mapDispatchToProps)(CroppingFooter as any);
