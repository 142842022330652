import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpinAnnotation } from '../../../model/Annotation';
import * as React from 'react';

interface Props {
  selected: boolean;
  annotation: SpinAnnotation;
  onClick: (annotation: SpinAnnotation) => void;
  onEditClick: (annotation: SpinAnnotation) => void;
  onDeleteClick: (annotation: SpinAnnotation) => void;
  isEditable: boolean;
}

class SpinviewAnnotationPreview extends React.PureComponent<Props, {}> {
  render() {
    const { selected, annotation, onClick, onEditClick, isEditable, onDeleteClick } = this.props;

    return (
      <div
        className={`spinview-annotation-item-view ${selected ? 'active' : ''}`}
        onClick={e => {
          const element = e.target as HTMLElement;
          if (element.id != 'annotation-item-delete' && element.parentElement.id != 'annotation-item-delete') {
            onClick && onClick(annotation);
          }
        }}
      >
        <div className='spinview-annotation-item-title'>
          <p>
            {annotation.title}
            {isEditable && (
              <FontAwesomeIcon
                icon={faEdit}
                id='annotation-item-edit'
                onClick={() => onEditClick && onEditClick(annotation)}
              />
            )}
            {isEditable && (
              <FontAwesomeIcon
                icon={faTrash}
                id='annotation-item-delete'
                onClick={() => onDeleteClick && onDeleteClick(annotation)}
              />
            )}
          </p>
        </div>
        <div className='spinview-annotation-item-image'>{annotation.image && <img src={annotation.image.photo} />}</div>
        <div className='spinview-annotation-item-description'>{annotation.description}</div>
      </div>
    );
  }
}

export default SpinviewAnnotationPreview;
