import { MetricState } from "../state/ApplicationState";
import { createAction } from "./actionHelper";
import { Metrics } from "../model/Metric"

export const INITIAL_STATE_METRICS = {
    isLoading: true,
    isError: false,
    errorMessage: '',
    photoCount: 0,
    vehicleCount: 0,
    avgTime: 0,
    avgPhotos: 0,
    recentSession: null,
}

export enum ActionTypes {
    GET_METRICS = "GET_METRICS",
    GET_METRICS_SUCCESS = "GET_METRICS_SUCCESS",
    GET_METRICS_FAILURE = "GET_METRICS_FAILURE",
    GET_RECENT_PHOTOS = "GET_RECENT_PHOTOS",
    GET_RECENT_PHOTOS_SUCCESS = "GET_RECENT_PHOTOS_SUCCESS",
    GET_RECENT_PHOTOS_FAILURE = "GET_RECENT_PHOTOS_FAILURE",
}

export function metricReducer(
    state: MetricState = INITIAL_STATE_METRICS,
    action: any,
) {
    switch (action.type) {
        case ActionTypes.GET_METRICS:
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorMessage: ""
            };
        case ActionTypes.GET_METRICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorMessage: "",
                vehicleCount: action.payload.vehicle_count,
                photoCount: action.payload.photo_count,
                avgTime: action.payload.avg_time,
                avgPhotos: action.payload.avg_photos,
                recentSession: action.payload.last_session,

            };
        case ActionTypes.GET_METRICS_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "Could not fetch metrics"
            };
        case ActionTypes.GET_RECENT_PHOTOS:
            return {
                ...state
            };
        case ActionTypes.GET_RECENT_PHOTOS_SUCCESS:
            return {
                ...state
            };
        case ActionTypes.GET_RECENT_PHOTOS_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
}

export const Actions = {
    getMetrics: () => createAction(ActionTypes.GET_METRICS),
    getMetricsSuccess: (metrics: Metrics) => createAction(ActionTypes.GET_METRICS_SUCCESS, metrics),
    getMetricsFail: () => createAction(ActionTypes.GET_METRICS_FAILURE),
}