import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { Button } from 'antd';

interface Props {
  canvas: any;
}

class RotatingFooter extends React.PureComponent<Props, {}> {
  rotateImage(degree: number) {
    if (this.props.canvas && this.props.canvas.rotateImage) {
      this.props.canvas.rotateImage(degree);
    }
  }

  apply() {
    if (this.props.canvas && this.props.canvas.applyRotate) {
      this.props.canvas.applyRotate();
    }
  }

  render() {
    return (
      <>
        <div className='editor-footer-left'>
          <p>Rotate:</p>
          <Button type='danger' size='large' onClick={() => this.rotateImage(90)}>
            90 degrees
          </Button>
          <Button type='danger' size='large' onClick={() => this.rotateImage(180)}>
            180 degrees
          </Button>
          <Button type='danger' size='large' onClick={() => this.rotateImage(270)}>
            270 degrees
          </Button>
        </div>
        <div className='editor-footer-right'>
          <Button type='primary' size='large' onClick={() => this.apply()}>
            Apply
          </Button>
          <Button type='ghost' size='large' onClick={() => this.rotateImage(0)}>
            Reset
          </Button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  canvas: editor.canvas,
});

export default connect(mapStateToProps, mapDispatchToProps)(RotatingFooter as any);
