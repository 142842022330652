import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { SidebarMode } from '../../../reducers/EditorReducer';

import { Form, Upload, Input, Icon, message, Button, Spin, notification } from 'antd';
import { Actions } from '../../../reducers/BrandingReducer';
import { Actions as EditorActions } from '../../../reducers/EditorReducer';
import { NewBrandingRequest, Brandings, Branding } from '../../../model/Branding';
import '@/styles/editor-branding-sidebar.css';

const { Dragger } = Upload;

const dummyRequest = options => {
  setTimeout(() => {
    options.onSuccess('ok');
  }, 0);
};

interface Props {
  sidebarMode: SidebarMode;
  form: any;
  canvas: any;
  activeBranding: Branding;
  brandings: Brandings;
  getBrandings: () => void;
  setActiveBranding: (activeBranding: Branding) => void;
  createBranding: (classification: NewBrandingRequest) => void;
  setSidebarMode: (mode: SidebarMode) => void;
}

const uploadingBox = (title, body, key) => {
  const args = {
    message: title,
    description: (
      <div>
        <Spin size='large' style={{ paddingRight: '2em' }} />
        {body}
      </div>
    ),
    duration: 0,
    key: key,
  };

  notification.open(args);
};

class BrandingSidebar extends React.PureComponent<Props, {}> {
  state = {
    file: null,
  };

  componentWillMount() {
    this.props.getBrandings();
  }

  onImageChange = info => {
    const { status } = info.file;
    if (status !== 'uploading') {
      this.setState({
        file: info.file,
      });
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  onFormSubmit() {
    this.props.form.validateFields((err, values) => {
      if (!this.state.file) {
        message.error(`Please upload a file.`);
        return;
      }

      const allValues = {
        ...values,
        thumbnail: this.state.file,
      };

      if (!err) {
        this.props.createBranding(allValues);
        uploadingBox('Started upload of ' + allValues.name, 'Uploading, please wait....', allValues.name);
      } else {
        console.log('Form error', err);
      }
    });
  }

  renderUploadForm() {
    const draggerProps = {
      multiple: false,
      name: 'branding-upload',
      onChange: this.onImageChange,
      customRequest: dummyRequest,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div className='brandupload-form'>
        <h2>Add Branding Element</h2>
        <Form layout='horizontal'>
          <Form.Item label='Name'>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Name cannot be empty',
                },
              ],
            })(<Input placeholder='Branding Name' />)}
          </Form.Item>
          <Form.Item label='Upload Image'>
            <Dragger {...draggerProps}>
              <p className='ant-upload-drag-icon'>
                <Icon type='inbox' />
              </p>
              <p className='ant-upload-text'>Click or drag file to this area to upload</p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button type='primary' block onClick={this.onFormSubmit.bind(this)}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  renderBrandingList() {
    return (
      <div className='branding-list'>
        <h2>Select Image</h2>
        {this.props.brandings.map((branding, index) => {
          let isSelected = false;
          if (this.props.activeBranding && branding.id === this.props.activeBranding.id) {
            isSelected = true;
          }

          return (
            <div
              className={'branding-item ' + (isSelected ? 'selected' : '')}
              key={`brand-item-${index}`}
              onClick={() => this.props.setActiveBranding(branding)}
            >
              <div className='branding-item-container'>
                <img src={branding.thumbnail} className={`branding-item-img`} alt='Branding for Dealership' />
                <h4>{branding.name}</h4>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  extractForeground() {
    if (this.props.canvas && this.props.canvas.extractForeground) {
      this.props.canvas.extractForeground();
    }
  }

  render() {
    return (
      <div className='branding-sidebar'>
        {this.props.sidebarMode === SidebarMode.ITEM_UPLOAD && this.renderUploadForm()}
        {this.props.sidebarMode === SidebarMode.ITEM_LIST && this.renderBrandingList()}
        {this.props.sidebarMode === SidebarMode.ITEM_LIST && (
          <Button
            type='primary'
            className='branding-upload-image-btn'
            size='large'
            onClick={() => this.props.setSidebarMode(SidebarMode.ITEM_UPLOAD)}
          >
            Upload Image
          </Button>
        )}
        {this.props.sidebarMode === SidebarMode.ITEM_UPLOAD && (
          <Button
            type='primary'
            className='branding-cancel-upload-btn'
            size='large'
            onClick={() => this.props.setSidebarMode(SidebarMode.ITEM_LIST)}
          >
            Cancel Uploading
          </Button>
        )}
      </div>
    );
  }
}

export const form = Form.create({ name: 'BrandingUploadForm' })(BrandingSidebar);

const mapDispatchToProps = {
  setSidebarMode: EditorActions.setSidebarMode,
  createBranding: Actions.createBranding,
  getBrandings: Actions.getBrandings,
  setActiveBranding: EditorActions.setActiveBranding,
};

const mapStateToProps = ({ editor, brandings }: ApplicationState) => ({
  sidebarMode: editor.sidebarMode,
  canvas: editor.canvas,
  brandings: brandings.brandings,
  activeBranding: editor.activeBranding,
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
