import * as React from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { connect } from 'react-redux';
import { ApplicationState } from '../../state/ApplicationState';
import { Actions } from '../../reducers/AdminReducer';
import { NewTenantUserRequest } from '../../model/TenantUser';
import { Sites } from '../../model/Site';

interface Props {
  // props
  form: any;
  showCreateModal: boolean;
  closeModal: () => void;
  createTenantUser: (tenantUser: NewTenantUserRequest) => void;
  sites: Sites;
}

interface State {
  // state
}

const { Option } = Select;

class UserForm extends React.Component<Props, State> {
  state = {
    confirmDirty: false,
  };

  handleModalOk = async () => {
    this.props.form.validateFields((err, values) => {
      const allValues = {
        ...values,
      };
      if (!err) {
        console.log('No error from form submit', allValues);
        const siteIds: [string] = allValues['sites'] ? allValues['sites'] : [];
        const sites = this.props.sites.filter(site => siteIds.includes(site.id));
        allValues['sites'] = sites;
        this.props.createTenantUser(allValues);
        this.props.form.resetFields();
        this.props.closeModal();
      } else {
        console.log('Form error', err);
      }
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleModalCancel = () => {
    this.props.closeModal();
    this.handleReset();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title='Create a new user'
        visible={this.props.showCreateModal}
        onOk={this.handleModalOk}
        onCancel={this.handleModalCancel}
      >
        <Form>
          <Form.Item label='Username'>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input E-mail!',
                },
              ],
            })(<Input placeholder='please input an email address...' />)}
          </Form.Item>
          <Form.Item label='Password' hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input a password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label='Confirm Password' hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm the password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Form.Item label='First Name'>
            {getFieldDecorator('first_name', {
              rules: [
                {
                  required: true,
                  message: 'Field cannot be empty',
                },
              ],
            })(<Input placeholder='First name...' />)}
          </Form.Item>
          <Form.Item label='Last Name'>
            {getFieldDecorator('last_name', {
              rules: [
                {
                  required: true,
                  message: 'Field cannot be empty',
                },
              ],
            })(<Input placeholder='Last name...' />)}
          </Form.Item>
          <Form.Item label='Sites'>
            {getFieldDecorator('sites', {
              rules: [],
            })(
              <Select mode='multiple' placeholder='Sites:'>
                {this.props.sites.map(site => (
                  <Option value={site.id}>{site.name}</Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export const form = Form.create({ name: 'UserForm' })(UserForm);

const mapStateToProps = ({ admin, sites }: ApplicationState) => ({
  showCreateModal: admin.showCreateModal,
  sites: sites.sites,
});

const mapDispatchToProps = {
  closeModal: Actions.hideUserFormModal,
  createTenantUser: Actions.createTenantUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(form);
