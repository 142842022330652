import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../state/ApplicationState';
import { EditorMode, Actions } from '../../../reducers/EditorReducer';
import { Button } from 'antd';

interface Props {
  setMode: (mode: EditorMode) => void;
  canvas: any;
}

class FilteringFooter extends React.PureComponent<Props, {}> {
  onSaveClick() {
    if (this.props.canvas && this.props.canvas.saveFiltering) {
      this.props.canvas.saveFiltering();
    }
  }

  onCancelClick() {
    if (this.props.canvas && this.props.canvas.resetFiltering) {
      this.props.canvas.resetFiltering();
    }
  }

  render() {
    return (
      <>
        <div className='editor-footer-left' />
        <div className='editor-footer-right'>
          <Button
            type='primary'
            size='large'
            onClick={() => {
              this.onSaveClick();
            }}
          >
            Save
          </Button>
          <Button
            type='primary'
            size='large'
            onClick={() => {
              this.onCancelClick();
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setMode: Actions.setMode,
};

const mapStateToProps = ({ editor }: ApplicationState) => ({
  canvas: editor.canvas,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilteringFooter as any);
