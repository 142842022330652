import { call, takeLatest, put } from "@redux-saga/core/effects";
import { Actions, ActionTypes } from "../reducers/AdminReducer";
import { httpGet, multipartFormSubmit, multipartFormPatchSubmit, httpPost, httpPut, httpDelete } from "../client/http";
import { ActionWithPayload } from "../reducers/actionHelper";
import { NewTenantUserRequest, UpdateTenantUserRequest } from "../model/TenantUser";
import { message } from "antd";
import { BrandingTask } from "../model/BrandingTask";

const tenantUsersEndpoint = 'api/group';
const tenantUserAddEndpoint = 'api/admin/user';
const tenantUserUpdateEndpoint = 'api/admin/user/change';

export function* getTenantUsers() {
  console.log(`tenant users fetching url: ${tenantUsersEndpoint}/`);
  try {
    const response = yield call(httpGet, `${tenantUsersEndpoint}/`);
    yield put(Actions.getTenantUserSuccess(response));
  } catch (e) {
    yield put(Actions.getTenantUserFailture());
  }
}

export function* createTenantUser(action: ActionWithPayload<ActionTypes.CREATE_TENANT_USER, NewTenantUserRequest>) {
  try {
    let { email, first_name, last_name, password, sites } = action.payload;
    yield call(httpPost, tenantUserAddEndpoint, {
      username: email,
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      sites: sites
    });
    yield put(Actions.createTenantUserSuccess());
    message.success('Successfully new user added');
  } catch (e) {
    console.log('Error creating new tenant user', e);
    yield put(Actions.createTenantUserFailure());
    message.error('Something went wrong while creating new user. Please try again!');
  }
}

export function* updateTenantUser(action: ActionWithPayload<ActionTypes.UPDATE_TENANT_USER, UpdateTenantUserRequest>) {
  try {
    let { id, email, first_name, last_name, password, sites } = action.payload;
    const data = {
      username: email,
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      sites: sites
    };
    if (password) {
      data['password'] = password;
    }
    yield call(httpPut, `${tenantUserUpdateEndpoint}/${id}`, data);
    yield put(Actions.updateTenantUserSuccess());
    message.success('Successfully user information updated');
  } catch (e) {
    console.log('Error updating tenant user', e);
    yield put(Actions.updateTenantUserFailure());
    message.error('Something went wrong while updating the user. Please try again!');
  }
}

const brandingTaskEndpoint = 'api/admin/brandingtask/';

export function* getBrandingTasks() {
  try {
    const response = yield call(httpGet, `${brandingTaskEndpoint}`);
    yield put(Actions.getBrandingTasksSuccess(response));
  } catch (e) {
    yield put(Actions.getBrandingTasksFailture());
  }
}

export function* createBrandingTask(action: ActionWithPayload<ActionTypes.CREATE_BRANDINGTASK, BrandingTask>) {
  try {
    yield call(httpPost, brandingTaskEndpoint, action.payload);
    yield put(Actions.createBrandingTaskSuccess());
    message.success('Successfully create new branding task');
  } catch (e) {
    console.log('Error creating new branding task', e);
    yield put(Actions.createBrandingTaskFailure());
    message.error('Something went wrong while creating new user. Please try again!');
  }
}

export function* updateBrandingTask(action: ActionWithPayload<ActionTypes.UPDATE_BRANDINGTASK, BrandingTask>) {
  try {
    yield call(httpPut, `${brandingTaskEndpoint}${action.payload.id}/`, action.payload);
    yield put(Actions.updateBrandingTaskSuccess());
    message.success('Successfully branding task information updated');
  } catch (e) {
    console.log('Error updating branding task', e);
    yield put(Actions.updateTenantUserFailure());
    message.error('Something went wrong while updating the branding task. Please try again!');
  }
}

export function* deleteBrandingTask(action: ActionWithPayload<ActionTypes.DELETE_BRANDINGTASK, BrandingTask>) {
  try {
    yield call(httpDelete, `${brandingTaskEndpoint}${action.payload.id}/`);
    yield put(Actions.deleteBrandingTaskSuccess());
    message.success('Successfully deleted branding task');
  } catch (e) {
    message.error('Error deleting branding task. Please try again.');
    yield put(Actions.deleteBrandingTaskFailure());
  }
}

export function* AdminSaga() {
  yield takeLatest(ActionTypes.GET_TENANT_USERS, getTenantUsers);
  yield takeLatest(ActionTypes.CREATE_TENANT_USER, createTenantUser);
  yield takeLatest(ActionTypes.CREATE_TENANT_USER_SUCCESS, getTenantUsers);
  yield takeLatest(ActionTypes.UPDATE_TENANT_USER, updateTenantUser);
  yield takeLatest(ActionTypes.UPDATE_TENANT_USER_SUCCESS, getTenantUsers);

  yield takeLatest(ActionTypes.GET_BRANDINGTASK, getBrandingTasks);
  yield takeLatest(ActionTypes.CREATE_BRANDINGTASK, createBrandingTask);
  yield takeLatest(ActionTypes.CREATE_BRANDINGTASK_SUCCESS, getBrandingTasks);
  yield takeLatest(ActionTypes.UPDATE_BRANDINGTASK, updateBrandingTask);
  yield takeLatest(ActionTypes.UPDATE_BRANDINGTASK_SUCCESS, getBrandingTasks);
  yield takeLatest(ActionTypes.DELETE_BRANDINGTASK, deleteBrandingTask);
  yield takeLatest(ActionTypes.DELETE_BRANDINGTASK_SUCCESS, getBrandingTasks);
}